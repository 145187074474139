import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import CatalogList from '../../components/CatalogList';
import PrivateData from '../../API/PrivateData';
import Loading from '../../UI/Loading';
import './index.scss';

import bemClassName from '../../utils/bem';

const currentBookings = bemClassName('current-bookings');

const CurrentBookings = () => {
  const [data, setData] = useState<any>();
  const [currentData, setCurrentData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isLogin = useSelector((state: RootState) => state.autorizationStatus.isLogin);

  useEffect(() => {
    if (isLogin !== null && isLogin) {
      setIsLoading(true);
      PrivateData.getPrivateUserDealsCurrentBooking().then((item) => {
        setData(item.data.map((items: any) => items.product));
        setCurrentData(item.data);
        setIsLoading(false);
      });
    }
  }, [isLogin]);

  useEffect(() => {
    document.title = 'Текущие бронирования';
  }, []);

  // eslint-disable-next-line
  return !isLoading ? (
    data ? (
      <CatalogList current data={data} currentData={currentData} />
    ) : (
      <h2 className={currentBookings()}>Текущих бронирований нет</h2>
    )
  ) : (
    <Loading externalClassName={currentBookings('loading')} />
  );
};

export default CurrentBookings;
