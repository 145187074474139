import { createSlice } from '@reduxjs/toolkit';

export interface DataState {
  isModalMap: boolean;
}

const initialState: DataState = {
  isModalMap: false,
};

export const modalMapSlice = createSlice({
  name: 'mapModal',
  initialState,
  reducers: {
    setIsModalMap: (state) => {
      state.isModalMap = !state.isModalMap;
    },
  },
});

export const { setIsModalMap } = modalMapSlice.actions;

export default modalMapSlice.reducer;
