import React, { useEffect, useState } from 'react';

import CatalogItem from '../CatalogItem';

import { ICatalogList } from './interface';
import bemClassName from '../../utils/bem';

import './index.scss';
import Loading from '../../UI/Loading';

const catalogList = bemClassName('catalog-list');

const CatalogList: React.FC<ICatalogList> = ({ data, fav, current, currentData }) => {
  const [isLoading, setIsLoading] = useState<boolean>();

  const handleExternal = (value: boolean) => {
    setIsLoading(value);
  };

  useEffect(() => {
    if (isLoading) {
      if (isLoading) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }
  }, [isLoading]);

  return (
    <div className={catalogList()}>
      {isLoading && (
        <div className={catalogList('carousel-box')}>
          <div className={catalogList('carousel')}>
            <Loading externalClassName={catalogList('loading')} />
            <p className={catalogList('loading-text')}>Идёт загрузка</p>
          </div>
        </div>
      )}
      {data &&
        data.length > 0 &&
        data.map((item) => {
          return (
            <CatalogItem
              key={item.id}
              data={item}
              fav={fav}
              current={current}
              currentData={currentData}
              loading={handleExternal}
            />
          );
        })}
    </div>
  );
};

export default CatalogList;
