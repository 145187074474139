import { useSelector } from 'react-redux';
import { RootState } from '../../store';

export const getMobailCalendars = (currentDate: Date) => {
  const arr: Array<Date> = [];
  let i = 0;
  while (i <= 12) {
    const date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + i,
      currentDate.getDate(),
    );
    arr.push(date);
    i += 1;
  }
  return arr;
};

export const GetFormattedDate = () => {
  const windowWidth = useSelector((state: RootState) => state.window.windowWidth);
  const calendarSelected = useSelector((state: RootState) => state.searchData.calendarSelected);
  if (windowWidth < 960 && !calendarSelected?.checkIn) {
    return 'Заезд';
  }
  if (windowWidth < 960 && calendarSelected?.checkIn && !calendarSelected?.departure) {
    return 'Отъезд';
  }

  let formatedDate = '';

  if (
    windowWidth < 960 &&
    calendarSelected &&
    calendarSelected.checkIn &&
    calendarSelected.departure
  ) {
    if (calendarSelected.checkIn) {
      new Date(calendarSelected.checkIn).getDate();
    }
    if (calendarSelected.departure) {
      new Date(calendarSelected.departure).getDate();
    }
    formatedDate = `${new Date(calendarSelected.checkIn).getDate()}-${new Date(
      calendarSelected.departure,
    ).getDate()} ${new Intl.DateTimeFormat('ru', {
      month: 'long',
    }).format(new Date(calendarSelected.departure))}`;
  }

  return formatedDate;
};

export const getCurrentDayOfMonth = (
  currentYear: number,
  currentMonth: number,
  dayOfWeek: number,
  weekNumber: number,
) => {
  const firstDayOfMonth = new Date(currentYear, currentMonth, 0);
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);

  const totalDaysInMonth = lastDayOfMonth.getDate();
  let currentDayOfMonth = 0;

  currentDayOfMonth = dayOfWeek - firstDayOfMonth.getDay() + 1 + 7 * weekNumber;

  if (currentDayOfMonth > totalDaysInMonth) {
    currentDayOfMonth = 0;
  }
  return currentDayOfMonth;
};
