import { useEffect, useState } from 'react';
import bemClassName from '../../../utils/bem';
import './index.scss';
import { MonthProps } from './interface';

const month = bemClassName('month');

const Month: React.FC<MonthProps> = ({
  calendarDate,
  setCalendarDate,
  miniCalendar,
  passportDate,
}) => {
  const date = calendarDate.toLocaleString('default', { month: 'long' });
  const monthName = date.toUpperCase()[0] + date.toLowerCase().slice(1);

  const [showDate, setShowDate] = useState(false);
  const [selectedYear, setSelectedYear] = useState(
    passportDate ? calendarDate.getFullYear() : calendarDate.getFullYear() - 14,
  );

  const years = Array.from(
    { length: 100 },
    (_, i) => new Date().getFullYear() - i - (passportDate ? 0 : 14),
  );

  const handleOptionClick = (year: number) => {
    setSelectedYear(year);
    setShowDate(false);
    if (setCalendarDate) {
      const newDate = new Date(year, calendarDate.getMonth(), calendarDate.getDate());
      setCalendarDate(newDate);
    }
  };

  useEffect(() => {
    if (miniCalendar && setCalendarDate) {
      const newDate = new Date(selectedYear, calendarDate.getMonth(), calendarDate.getDate());
      setCalendarDate(newDate);
    }
  }, [miniCalendar, setCalendarDate]);

  const handleClickOpen = () => {
    setShowDate(!showDate);
  };

  return miniCalendar ? (
    <h3 className={month()}>
      {monthName}
      <div onClick={handleClickOpen} className={month('container-date')}>
        <p onClick={() => handleOptionClick(selectedYear)}>{calendarDate.getFullYear()}</p>
        {showDate && (
          <div className={month('date')}>
            {years.map((year) => (
              <p key={year} onClick={() => handleOptionClick(year)}>
                {year}
              </p>
            ))}
          </div>
        )}
      </div>
    </h3>
  ) : (
    <h3 className={month()}>
      {monthName} {calendarDate.getFullYear()}
    </h3>
  );
};

export default Month;
