import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { SwiperSlide, Swiper } from 'swiper/react';
import { FreeMode, Navigation, Thumbs, Pagination } from 'swiper';
import { RootState } from '../../store';

import Icon from '../Icon';

import bemClassName from '../../utils/bem';

import './index.scss';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

const carouselDetail = bemClassName('carousel-detail');

const CarouselDetail = ({
  data,
  onClick,
  modalOpen,
  photos_url,
}: {
  data: Array<string> | undefined;
  onClick?: () => void;
  modalOpen?: boolean;
  photos_url?: string;
}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<any>();

  const navigationNextRef = useRef(null);
  const navigationPrevRef = useRef(null);

  const windowWidth = useSelector((state: RootState) => state.window.windowWidth);

  return windowWidth < 1024 ? (
    <Swiper
      className={carouselDetail({ mobile_modal: modalOpen })}
      spaceBetween={0}
      slidesPerView={1}
      modules={[Pagination]}
      pagination={{ clickable: true }}
      loop>
      {data &&
        data.map((item, id) => {
          return (
            <SwiperSlide onClick={onClick} key={id}>
              {modalOpen ? (
                <picture className={carouselDetail('picture')}>
                  <source
                    media="(max-width: 600px)"
                    type="image/webp"
                    srcSet={`${photos_url}/${item}-600x348.webp, ${photos_url}/${item}-1200x696.webp 2x, ${photos_url}/${item}-1800x1044.webp 3x`}
                  />
                  <source
                    media="(max-width: 600px)"
                    type="image/jpeg"
                    srcSet={`${photos_url}/${item}-600x348.jpg, ${photos_url}/${item}-1200x696.jpg 2x, ${photos_url}/${item}-1800x1044.jpg 3x`}
                  />
                  <source
                    type="image/webp"
                    srcSet={`${photos_url}/${item}-600x348.webp, ${photos_url}/${item}-1200x696.webp 2x, ${photos_url}/${item}-1800x1044.webp 3x`}
                  />
                  <img
                    className={carouselDetail('image')}
                    src={`${photos_url}/${item}-600x348.jpg`}
                    srcSet={`${photos_url}/${item}-1200x696.jpg 2x, ${photos_url}/${item}-1800x1044.jpg 3x`}
                    alt={`${photos_url}/${item}`}
                  />
                </picture>
              ) : (
                <picture className={carouselDetail('picture')}>
                  <source
                    media="(max-width: 600px)"
                    type="image/webp"
                    srcSet={`${photos_url}/${item}-580x312.webp, ${photos_url}/${item}-1160x624.webp 2x, ${photos_url}/${item}-1740x936.webp 3x`}
                  />
                  <source
                    media="(max-width: 600px)"
                    type="image/jpeg"
                    srcSet={`${photos_url}/${item}-580x312.jpg, ${photos_url}/${item}-1160x624.jpg 2x, ${photos_url}/${item}-1740x936.jpg 3x`}
                  />
                  <source
                    type="image/webp"
                    srcSet={`${photos_url}/${item}-580x312.webp, ${photos_url}/${item}-1160x624.webp 2x, ${photos_url}/${item}-1740x936.webp 3x`}
                  />
                  <img
                    className={carouselDetail('image')}
                    onClick={onClick}
                    src={`${photos_url}/${item}-580x312.jpg`}
                    srcSet={`${photos_url}/${item}-1160x624.jpg 2x, ${photos_url}/${item}-1740x936.jpg 3x`}
                    alt={`${photos_url}/${item}`}
                  />
                </picture>
              )}
            </SwiperSlide>
          );
        })}
    </Swiper>
  ) : (
    <div className={carouselDetail('carousel-desktop-wrap', { modal: modalOpen })}>
      <Swiper
        className={carouselDetail({ desktop: true })}
        loop
        spaceBetween={0}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[FreeMode, Navigation, Thumbs, Pagination]}
        pagination={{ clickable: true }}>
        {data &&
          data.map((item, id) => {
            return (
              <SwiperSlide onClick={onClick} key={id}>
                {modalOpen ? (
                  <picture className={carouselDetail('picture')}>
                    <source
                      type="image/webp"
                      srcSet={`${photos_url}/${item}-1595x925.webp, ${photos_url}/${item}-3190x1850.webp 2x, ${photos_url}/${item}-4785x2775.webp 3x`}
                    />
                    <img
                      className={carouselDetail('image', { image_modal: modalOpen })}
                      src={`${photos_url}/${item}-1595x925.jpg`}
                      srcSet={`${photos_url}/${item}-3190x1850.jpg 2x, ${photos_url}/${item}-4785x2775.jpg 3x`}
                      alt=""
                    />
                  </picture>
                ) : (
                  <picture className={carouselDetail('picture')}>
                    <source
                      type="image/webp"
                      srcSet={`${photos_url}/${item}-836x450.webp, ${photos_url}/${item}-1672x900.webp 2x, ${photos_url}/${item}-2508x1350.webp 3x`}
                    />
                    <img
                      className={carouselDetail('image', { image_modal: modalOpen })}
                      src={`${photos_url}/${item}-836x450.jpg`}
                      srcSet={`${photos_url}/${item}-1672x900.jpg 2x, ${photos_url}/${item}-2508x1350.jpg 3x`}
                      alt={`${photos_url}/${item}`}
                    />
                  </picture>
                )}
              </SwiperSlide>
            );
          })}
        <div
          className={carouselDetail('carousel-navigation', { prev: true })}
          ref={navigationPrevRef}>
          <Icon
            iconName="arrow"
            externalClassName={carouselDetail('carousel-navigation-icon', {
              prev: true,
            })}
          />
        </div>
        <div
          className={carouselDetail('carousel-navigation', { next: true })}
          ref={navigationNextRef}>
          <Icon
            iconName="arrow"
            externalClassName={carouselDetail('carousel-navigation-icon', {
              next: true,
            })}
          />
        </div>
      </Swiper>
      <Swiper
        className={carouselDetail({ thumbs: true })}
        onSwiper={setThumbsSwiper}
        loop
        spaceBetween={15}
        slidesPerView="auto"
        freeMode
        watchSlidesProgress
        modules={[FreeMode, Navigation, Thumbs]}
        direction="vertical">
        {data &&
          data.map((item, id) => {
            return (
              <SwiperSlide key={id}>
                <picture className={carouselDetail('picture')}>
                  <img
                    className={carouselDetail('image', { image_modall: modalOpen })}
                    src={`${photos_url}/${item}-150x100.jpg`}
                    srcSet={`${photos_url}/${item}-300x200.jpg 2x, ${photos_url}/${item}-450x300.jpg 3x`}
                    alt={`${photos_url}/${item}`}
                  />
                </picture>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default CarouselDetail;
