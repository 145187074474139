import { ReactNode, useEffect, useState } from 'react';
import parse from 'html-react-parser';

import bemClassName from '../../utils/bem';

import './index.scss';
import Public from '../../API/Public';
import Loading from '../../UI/Loading';

const aboutCompany = bemClassName('about-company');

const AboutCompany = () => {
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  let parsedHTML;
  if (data) {
    parsedHTML = parse(data.content);
  }

  useEffect(() => {
    document.title = 'О Компании';
    setIsLoading(true);
    Public.getAboutCompany()
      .then((items) => {
        setData(items);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading externalClassName={aboutCompany('loading')} />
      ) : (
        data && (
          <>
            <h2 className={aboutCompany('title')}>{data.title}</h2>
            <div className={aboutCompany('inner')}>{parsedHTML as ReactNode}</div>
          </>
        )
      )}
    </>
  );
};

export default AboutCompany;
