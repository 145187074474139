import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setFavorite } from '../../store/reducers/profile/favouritesSlice';

import Icon from '../Icon';

import { ILike } from './interface';
import bemClassName from '../../utils/bem';

import './index.scss';
import PrivateData from '../../API/PrivateData';

const like = bemClassName('like');

const Like: React.FC<ILike> = ({ id = 1, full, externalClassName }) => {
  const [isLike, setLike] = useState(false);
  const favourites = useSelector((state: RootState) => state.favoritesData.favorites);
  // const citySelected = useSelector((state: RootState) => state.searchData.citySelected);

  const features = useSelector((state: RootState) => state.otherFiltersData.features);
  const bedsValue = useSelector((state: RootState) => state.otherFiltersData.bedsValue);
  const doubleBedsValue = useSelector((state: RootState) => state.otherFiltersData.doubleBedsValue);
  const metaCount = useSelector((state: RootState) => state.otherFiltersData.metaCount);
  const selectedAreas = useSelector((state: RootState) => state.otherFiltersData.selectedAreas);
  const guests = useSelector((state: RootState) => state.searchData.guests);
  const isBonus = useSelector((state: RootState) => state.searchData.isBonus);
  const isContactless = useSelector((state: RootState) => state.searchData.isContactless);
  const rooms = useSelector((state: RootState) => state.searchData.rooms);
  const sliderPriceValue = useSelector((state: RootState) => state.searchData.sliderPriceValue);
  const sortSelected = useSelector((state: RootState) => state.searchData.sortSelected);
  const isLogin = useSelector((state: RootState) => state.autorizationStatus.isLogin);

  const dispatch = useDispatch();

  const handleLike = (event: React.MouseEvent) => {
    event.preventDefault();
    setLike(!isLike);

    const requestData = {
      features: features || undefined,
      bedsValue: bedsValue || undefined,
      doubleBedsValue: doubleBedsValue || undefined,
      metaCount: metaCount || undefined,
      selectedAreas: selectedAreas || undefined,
      guests: guests || undefined,
      isBonus: isBonus || undefined,
      isContactless: isContactless || undefined,
      rooms: rooms || undefined,
      sliderPriceValue: sliderPriceValue || undefined,
      sortSelected: sortSelected || undefined,
    };

    if (id) {
      dispatch(setFavorite(id));
    }
    if (isLogin !== null && isLogin) {
      if (!isLike) {
        PrivateData.getPrivateAddetToCollectionFavorite(id, requestData);
      } else {
        PrivateData.getPrivateDeleteCollectionFavorite(id);
      }
    }
  };

  useEffect(() => {
    if (Array.isArray(favourites)) {
      setLike(favourites.includes(id));
    }
  }, [favourites]);

  return (
    <div className={`${like()} ${externalClassName}`} onClick={(event) => handleLike(event)}>
      <div className={like('icon-wrap')}>
        {isLike ? (
          <Icon iconName="like_active" externalClassName={like('icon', { active: isLike })} />
        ) : (
          <>
            <Icon iconName="like_active" externalClassName={like('icon', { back: true })} />
            <Icon iconName="like" externalClassName={like('icon')} />
          </>
        )}
      </div>
      {full && <>В избранное</>}
    </div>
  );
};

export default Like;
