import { useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../UI/Button';

import bemClassName from '../../utils/bem';

import photo_default from '../../assets/images/photo-default.png';

import './index.scss';
import PrivateData from '../../API/PrivateData';
import Icon from '../../UI/Icon';
import { RootState } from '../../store';
import { setVerified } from '../../store/reducers/profile/autorizationStatutsSlice';
import Modal from '../../UI/Modal';

const proofIdentity = bemClassName('proof-identity');

const ProofIdentity = () => {
  // const [error, setError] = useState(null);
  const [isEnabled, setEnabled] = useState(false);
  const [isEnabledPassport, setEnabledPassport] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [facing, setFacing] = useState('user');
  const [photoPassport, setPhotoPassport] = useState<File | null>(null);

  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<any>(null);
  const [videoUserPassportInHand, setVideoUserPassportInHand] = useState<File | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const videoRef = useRef<any | null>();
  const videoHandPassportRef = useRef<any | null>();
  const streamRef = useRef<any | null>();

  const isPaswordVerified = useSelector(
    (state: RootState) => state.autorizationStatus.isPaswordVerified,
  );

  const dispatch = useDispatch();

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleDataAvailable = (event: any) => {
    if (event.data.size > 0) {
      const videoBlob = new Blob([event.data], { type: 'video/webm' });
      const videoFile = new File([videoBlob], 'passport_video.webm', { type: 'video/webm' });

      setVideoUserPassportInHand(videoFile);
    }
  };

  const handleStartRecording = () => {
    if (!isRecording) {
      if (mediaRecorder !== null) {
        mediaRecorder.start();
        setIsRecording(true);
      }
    } else if (mediaRecorder !== null) {
      mediaRecorder.stop();
      setIsRecording(false);
    }
  };

  const uploadPhoto = () => {
    const handleChange = (event: any) => {
      setEnabled(true);
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (reader.result) {
            setPhotoPassport(new File([file], file.name));
          }
        };
      }
    };

    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = handleChange;
    input.click();
  };

  const startStream = (ref: any) => {
    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          facingMode: facing,
        },
      })
      .then((stream) => {
        streamRef.current = stream;
        if (ref) {
          // eslint-disable-next-line no-param-reassign
          ref.current.srcObject = streamRef.current;
          // eslint-disable-next-line no-param-reassign
          ref.current.onloadedmetadata = () => ref.current.play();
        }
      })
      .catch((err) => {
        // setError(err.name);
        console.log(err);
      });
  };

  const stopStream = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track: any) => track.stop());
    }
  };

  const makePhoto = (videoRefPassport: any) => {
    const canvas = document.createElement('canvas');
    const video = videoRefPassport.current;
    if (video) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');
      if (context) {
        if (facing === 'user') {
          context.scale(-1, 1);
          context.drawImage(video, 0, 0, -canvas.width, canvas.height);
        } else {
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
        }
        if (videoRefPassport === videoRef) {
          canvas.toBlob((blob) => {
            if (blob) {
              const file = new File([blob], 'passport.png', { type: 'image/png' });
              setPhotoPassport(file);
            }
          }, 'image/png');
        }
      }
    }
  };

  const deletePhoto = (ref: any) => {
    if (ref) {
      if (ref === videoRef) {
        setEnabled(true);
        setPhotoPassport(null);
        startStream(ref);
      }
      if (ref === videoHandPassportRef) {
        setEnabledPassport(true);
        setVideoUserPassportInHand(null);
        startStream(ref);
      }
    }
  };

  const handleSavePassportDataPhoto = () => {
    if (photoPassport && videoUserPassportInHand) {
      PrivateData.getPrivateAddedPassportPhoto(photoPassport);
      PrivateData.getPrivateAddedPassportAndUserFaceVideo(videoUserPassportInHand);
    }
  };

  useEffect(() => {
    const constraints = {
      video: true,
    };

    const startVideo = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        if (videoHandPassportRef.current) {
          videoHandPassportRef.current.srcObject = stream;
          const recorder = new MediaRecorder(stream);
          setMediaRecorder(recorder);
          recorder.ondataavailable = handleDataAvailable;
        }
      } catch (err) {
        console.error('Error:', err);
      }
    };
    if (isEnabledPassport) {
      startVideo();
    }
    return () => {
      // if (isEnabled || isEnabledPassport || isRecording) {
      if (videoHandPassportRef.current && videoHandPassportRef.current.srcObject) {
        const stream = videoHandPassportRef.current.srcObject;
        const tracks = stream.getTracks();

        tracks.forEach((track: any) => {
          track.stop();
        });
      }
    };
    // };
  }, [isEnabledPassport]);

  useEffect(() => {
    if (photoPassport !== null && videoUserPassportInHand !== null) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [photoPassport, videoUserPassportInHand]);

  useEffect(() => {
    // setError(null);
    stopStream();
    if (isEnabled) {
      startStream(videoRef);
    }
    if (isEnabledPassport) {
      startStream(videoHandPassportRef);
    }
  }, [isEnabled, facing, isEnabledPassport]);

  useEffect(() => {
    document.title = 'Подтверждение личности';

    PrivateData.getPrivateUser().then((item) => {
      if (item.passport.approved === true) {
        dispatch(setVerified());
      }
    });
  }, []);

  return isPaswordVerified ? (
    <div className={proofIdentity()}>
      {/* <img src="photoPassport.jpg" alt="" /> */}
      <h2 className={proofIdentity('title')}>Для подтверждения личности сделайте фото</h2>
      <div className={proofIdentity('photos-wrap')}>
        <div className={proofIdentity('photos')}>
          {!photoPassport ? (
            <div
              className={proofIdentity('photo', { disabled: isEnabled || photoPassport !== null })}>
              {!isEnabled ? (
                <img
                  className={proofIdentity('photo-default')}
                  src={photo_default}
                  alt={photo_default}
                />
              ) : (
                <div className={proofIdentity('make-photo-box')}>
                  <video
                    className={proofIdentity('camera-photo')}
                    playsInline
                    muted
                    autoPlay
                    ref={videoRef}
                  />
                  <p className={proofIdentity('take-Photo')} onClick={() => makePhoto(videoRef)} />
                  <button
                    type="button"
                    className={proofIdentity('flip-camera')}
                    onClick={() => setFacing(facing === 'user' ? 'environment' : 'user')}>
                    Перевернуть
                  </button>
                  <button
                    className={proofIdentity('button-photo')}
                    type="button"
                    onClick={() => setEnabled(!isEnabled)}>
                    Закрыть
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className={proofIdentity('photo', { disabled: isEnabled })}>
              <img
                className={proofIdentity('received-photo')}
                src={URL.createObjectURL(photoPassport)}
                alt="Passport"
              />
              <button
                className={proofIdentity('button-photo')}
                type="button"
                onClick={() => deletePhoto(videoRef)}>
                Удалить
              </button>
            </div>
          )}
          <p className={proofIdentity('photos-description')}>Фото паспорта</p>
          <div className={proofIdentity('photos-buttons-wrap')}>
            <Button
              text="Загрузить фото"
              transparent
              externalClassName={proofIdentity('photos-buttons')}
              handleExternal={uploadPhoto}
            />
            <Button
              text="Сделать фото"
              transparent
              externalClassName={proofIdentity('photos-buttons')}
              handleExternal={() => setEnabled(!isEnabled)}
            />
          </div>
        </div>
        <div className={proofIdentity('photos')}>
          {!videoUserPassportInHand ? (
            <div className={proofIdentity('photo', { disabled: isEnabledPassport })}>
              {!isEnabledPassport ? (
                <img
                  className={proofIdentity('photo-default')}
                  src={photo_default}
                  alt={photo_default}
                />
              ) : (
                <div className={proofIdentity('make-photo-box')}>
                  <video
                    className={proofIdentity('camera-photo')}
                    ref={videoHandPassportRef}
                    autoPlay
                    muted
                    playsInline
                  />
                  <p
                    className={proofIdentity('make-video', { active: isRecording })}
                    onClick={() => handleStartRecording()}
                  />
                  <button
                    type="button"
                    className={proofIdentity('flip-camera')}
                    onClick={() => setFacing(facing === 'user' ? 'environment' : 'user')}>
                    Перевернуть
                  </button>
                  <button
                    className={proofIdentity('button-photo')}
                    type="button"
                    onClick={() => setEnabledPassport(!isEnabledPassport)}>
                    Закрыть
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className={proofIdentity('photo', { disabled: isEnabledPassport })}>
              {videoUserPassportInHand && (
                // eslint-disable-next-line
                <video
                  className={proofIdentity('received-photo')}
                  controls
                  src={URL.createObjectURL(videoUserPassportInHand)}
                />
              )}
              <button
                className={proofIdentity('button-photo')}
                type="button"
                onClick={() => deletePhoto(videoHandPassportRef)}>
                Удалить
              </button>
            </div>
          )}
          <p className={proofIdentity('photos-description')}>Видео с паспортом в руках</p>
          <div className={proofIdentity('photos-buttons-wrap')}>
            <Button
              text="Снять видео"
              transparent
              externalClassName={proofIdentity('photos-buttons')}
              handleExternal={() => setEnabledPassport(!isEnabledPassport)}
            />
          </div>
        </div>
      </div>
      <Button
        handleExternal={handleSavePassportDataPhoto}
        text="Подтвердить личность"
        disable={!disabledButton}
        externalClassName={proofIdentity('button')}
      />
      <div className={proofIdentity('text')}>
        <h4 className={proofIdentity('text-title')}>Зачем нужно подтверждение личности?</h4>
        <p className={proofIdentity('text-description')}>
          После подтверждения личность Вам будет доступно моментальное бронирование и{' '}
          <Link onClick={() => handleOpenModal()} to="#proof-of-identity">
            бесконтактное заселение.
          </Link>
          В случае других способов бронирования Вы сможете заключить договор непосредственно в
          квартире при встрече с агентом.
        </p>
        <Modal open={isOpen} handleClose={handleCloseModal}>
          <h3>Что такое бесконтактное заселение?</h3>
          <p className={proofIdentity('contactless-text')}>
            Квартиры с бесконтактным заселением оборудованы кодовым замком. После бронирования и
            оплаты в СМС или в нашем мобильном приложении Вы получите код доступа в квартиру,
            который будет действовать весь срок Вашего пребывания.
          </p>
          <p className={proofIdentity('contactless-text')}>
            Опция доступна для гостей,{' '}
            <Link to="#proof-of-identity">подтвердивших свою личность</Link> и{' '}
            <Link to="/">согласившихся с правилами проживания</Link>. Способы подтверждения и также
            правила проживания доступны зарегистрированным пользователям в личном кабинете
          </p>
          <Button
            externalClassName={proofIdentity('button-contactless')}
            text="Ок"
            handleExternal={handleCloseModal}
          />
        </Modal>
      </div>
      <div className={proofIdentity('text')}>
        <h4 className={proofIdentity('text-title')}>
          Сколько времени займет подтверждение личности?
        </h4>
        <p className={proofIdentity('text-description')}>
          Подтверждение личности может занять от нескольких минут до нескольких часов, об успешном
          подтверждении мы уведомим Вас через СМС.
        </p>
      </div>
    </div>
  ) : (
    <div className={proofIdentity('verified')}>
      <div className={proofIdentity('verified-title')}>
        <div className={proofIdentity('icon-box')}>
          <Icon externalClassName={proofIdentity('icon')} iconName="checked" />
        </div>
        <h2>Личность подтверждена</h2>
      </div>
      <div className={proofIdentity('')}>
        <p>
          Ваша личность подтверждена, теперь Вам доступно моментальное броинрование и бесконтактное
          заселение
        </p>
        <Link className={proofIdentity('link')} to="#t">
          бесконтактное заселение
        </Link>
      </div>
    </div>
  );
};

export default ProofIdentity;
