import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { IModal } from './interface';
import bemClassName from '../../utils/bem';

import './index.scss';
import Icon from '../Icon';
import { RootState } from '../../store';

const modal = bemClassName('modal');

const Modal: React.FC<IModal> = ({ open, handleClose, children, photosMoodal }) => {
  const hendleWrapperClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    handleClose();
  };

  const windowWidth = useSelector((state: RootState) => state.window.windowWidth);

  useEffect(() => {
    if (open) {
      if (!document.body.hasAttribute('style')) {
        document.body.style.overflow = 'hidden';
      }
    } else if (!open) {
      if (document.body.hasAttribute('style')) {
        document.body.removeAttribute('style');
      }
    }
  }, [open, handleClose]);

  return (
    <div className={modal({ open, photosMoodal })}>
      <div className={modal('inner')}>
        <div className={modal('swipe-button', { detail_modal: photosMoodal })} />
        {children}
        {photosMoodal && (
          <div
            className={modal('close_content', { mobail_modal_button: photosMoodal })}
            onClick={handleClose}>
            <Icon iconName="close_modal_photos" externalClassName={modal('close-icon')} />
            {windowWidth >= 1024 && <p className={modal('close-text')}>Закрыть</p>}
          </div>
        )}
      </div>
      <div className={modal('wrapper')} onClick={(event) => hendleWrapperClick(event)} />
    </div>
  );
};

export default Modal;
