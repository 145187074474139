import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import bemClassName from '../../utils/bem';

import './index.scss';
import { IApartamentPayment } from './interface';

const apartamentPayment = bemClassName('apartament-payment');

const ApartamentPayment: React.FC<IApartamentPayment> = ({ data }) => {
  useEffect(() => {}, []);

  return (
    data && (
      <Link to="/" className={apartamentPayment('')}>
        <Swiper
          className={apartamentPayment('carousel')}
          spaceBetween={0}
          slidesPerView={1}
          modules={[Pagination]}
          pagination={{ clickable: true }}
          loop>
          {data.photos &&
            data.photos_url !== undefined &&
            data.photos.slice(0, 5).map((item: any, id: any) => {
              // if (id <= 4) {
              return (
                <SwiperSlide key={id}>
                  <picture className={apartamentPayment('picture')}>
                    <source
                      media="(max-width: 720px)"
                      srcSet={`${data.photos_url}/${item}-578x388.jpg, ${data.photos_url}/${item}-1156x776.jpg 2x, ${data.photos_url}/${item}-1734x1164.jpg 3x`}
                    />
                    <source
                      media="(max-width: 720px)"
                      type="image/webp"
                      srcSet={`${data.photos_url}/${item}-578x388.webp, ${data.photos_url}/${item}-1156x776.webp 2x, ${data.photos_url}/${item}-1734x1164.webp 3x`}
                    />
                    <source
                      media="(max-width: 720px)"
                      type="image/jpeg"
                      srcSet={`${data.photos_url}/${item}-578x388.jpg, ${data.photos_url}/${item}-1156x776.jpg 2x, ${data.photos_url}/${item}-1734x1164.jpg 3x`}
                    />
                    <source
                      type="image/webp"
                      srcSet={`${data.photos_url}/${item}-298x298.webp, ${data.photos_url}/${item}-596x596.webp 2x, ${data.photos_url}/${item}-894x894.webp 3x`}
                    />
                    <img
                      className={apartamentPayment('image')}
                      src={`${data.photos_url}/${item}-298x298.jpg`}
                      srcSet={`${data.photos_url}/${item}-596x596.jpg 2x, ${data.photos_url}/${item}-894x894.jpg 3x`}
                      alt=""
                    />
                  </picture>
                  {data.photos && data.photos.length > 5 && id === 4 && (
                    <p className={apartamentPayment('last-slide-more')}>
                      Ещё {data.photos.length - 5} фото
                    </p>
                  )}
                </SwiperSlide>
              );
              // }
            })}
        </Swiper>
        <div className={apartamentPayment('info')}>
          <p className={apartamentPayment('date')}>г. Оренбург, с 11 марта по 27 марта</p>
          <div className={apartamentPayment('content')}>
            <h4>3-к. квартира на ул. Лесозащитная 14</h4>
            <div className={apartamentPayment('square')}>
              <p className={apartamentPayment('description', { full_width: true })}>
                2 + 2 спальных мест
              </p>
              <p className={apartamentPayment('description')}>
                24 м<sup>2</sup> общая площадь
              </p>
              <p className={apartamentPayment('description')}>
                10 м<sup>2</sup> площадь кухни
              </p>
            </div>
            <div>
              <p className={apartamentPayment('date')}>Бронь действительна до 14:00 15.05.2024 </p>
              <p className={apartamentPayment('description')}>
                Необходимо внести предоплату до этого времени.
              </p>
            </div>
            <p>Телефон менеджера 8 942 323-32-33</p>
            <div className={apartamentPayment('price')}>
              <div>
                <h3 className={apartamentPayment('discount')}> 1500 Р</h3>
                <p className={apartamentPayment('description')}>Cкидка</p>
              </div>
              <div>
                <h2 className={apartamentPayment('total')}> 8500 Р</h2>
                <p className={apartamentPayment('description')}>Итого</p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    )
  );
};

export default ApartamentPayment;
