import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import Validate from '../../../API/Validate';

export const fetchCheckFioValidate = createAsyncThunk(
  'data/fetchCheckFioValidate',
  Validate.checkName,
);

export const fetchHelperFioValidate = createAsyncThunk(
  'data/fetchHelperFioValidate',
  Validate.helperName,
);

export interface DataState {
  // surname: string;
  // name: string;
  // middlename: string;
  fio: string;
  fioIsValid: boolean | null;
  fioErrorText: string | null;
  // fioHelperArray: Array<any> | null;
  fioHelperArray: IFioHelper | null;
  // dateOfBirth: string | null;
  // phoneNumber: string;
  // email: string | null;
  // genderValue: string | null;
  // company: string | null;
}

export interface ICheckName {
  result: boolean;
  error?: string;
  warning?: string;
}

export interface IFioHelper {
  help: Array<string>;
  foreign: boolean;
  fio: IFIO;
  words: Array<string>;
}

export interface IFIO {
  surname: string;
  name: string;
  middlename: string;
}

const initialState: DataState = {
  // surname: '',
  // name: '',
  // middlename: '',
  fio: '',
  // company: '',
  fioIsValid: null,
  fioErrorText: null,
  fioHelperArray: null,
  // dateOfBirth: null,
  // phoneNumber: '',
  // email: null,
  // genderValue: null,
};

export const personalDataSlice = createSlice({
  name: 'personalData',
  initialState,
  reducers: {
    // setSurname: (state, action: PayloadAction<string>) => {
    //   state.surname = action.payload;
    // },
    // setName: (state, action: PayloadAction<string>) => {
    //   state.name = action.payload;
    // },
    // setMiddlename: (state, action: PayloadAction<string>) => {
    //   state.middlename = action.payload;
    // },
    setDefaultValueFioValidate: (state) => {
      state.fioIsValid = null;
      state.fioErrorText = null;
    },
    // setDateOfBirth: (state, action: PayloadAction<string | null>) => {
    //   state.dateOfBirth = action.payload;
    // },
    // setPhoneNumber: (state, action: PayloadAction<string>) => {
    //   state.phoneNumber = action.payload;
    // },
    // setEmail: (state, action: PayloadAction<string | null>) => {
    //   state.email = action.payload;
    // },
    // setGenderValue: (state, action: PayloadAction<string | null>) => {
    //   state.genderValue = action.payload;
    // },
    // setCompanyValue: (state, action: PayloadAction<string | null>) => {
    //   state.company = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCheckFioValidate.fulfilled, (state, action: PayloadAction<ICheckName>) => {
      state.fioIsValid = action.payload.result;
      if (action.payload.warning) {
        state.fioErrorText = action.payload.warning;
      }
      if (action.payload.error) {
        state.fioErrorText = action.payload.error;
      }
    });
    //   builder.addCase(
    //     fetchHelperFioValidate.fulfilled,
    //     (state, action: PayloadAction<Array<any>>) => {
    //       if (typeof action.payload === 'object' && Array.isArray(action.payload)) {
    //         state.fioHelperArray = action.payload.map((item) => item.name);
    //       }
    //     },
    //   );
    // },
    builder.addCase(
      fetchHelperFioValidate.fulfilled,
      (state, action: PayloadAction<IFioHelper>) => {
        // if (typeof action.payload === 'object' && Array.isArray(action.payload)) {
        //   state.fioHelperArray = action.payload.map((item) => item.name);
        // }
        state.fioHelperArray = action.payload;
      },
    );
  },
});

export const { setDefaultValueFioValidate } = personalDataSlice.actions;

export default personalDataSlice.reducer;
