import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import FooterLinksData from '../../../API/FooterLinksData';

export const fetchFooterLinksData = createAsyncThunk(
  'data/fetchFooterLinksData',
  FooterLinksData.getAll,
);

export interface DataState {
  data: Array<any>;
}

const initialState: DataState = {
  data: [],
};

export const footerLinksDataSlice = createSlice({
  name: 'footerLinksData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFooterLinksData.fulfilled, (state, action: PayloadAction<Array<any>>) => {
      state.data = action.payload;
    });
  },
});

// export const {} = footerLinksDataSlice.actions;

export default footerLinksDataSlice.reducer;
