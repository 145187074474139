import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import Raiting from '../../UI/Raiting';
import Button from '../../UI/Button';

import { IReviewModal } from './interface';
import bemClassName from '../../utils/bem';

import './index.scss';
import PrivateData from '../../API/PrivateData';

const reviewModal = bemClassName('review-modal');

const ReviewModal: React.FC<IReviewModal> = ({
  id,
  locationLink,
  image,
  image_url,
  city,
  address,
  handleClose,
  open,
}) => {
  const modalInner = useRef<HTMLInputElement>(null);

  const [text, setText] = useState<string | null>();
  const [count, setCount] = useState<number | null>();

  const [disableButton, setDisabledButton] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const handleCloseModal = (event: any) => {
    event?.stopPropagation();
    event?.preventDefault();
    if (!modalInner.current?.contains(event.target)) {
      handleClose();
    }
  };

  const handleSetReview = () => {
    if (id && text && count) {
      PrivateData.getPrivateUserAddetReview(id, text, count);
    }
  };

  useEffect(() => {
    if (count && text) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [count, text]);

  return (
    <div className={reviewModal({ open })} onClick={(event) => handleCloseModal(event)}>
      <div ref={modalInner} className={reviewModal('inner')}>
        <h3 className={reviewModal('title')}>Мой отзыв</h3>
        <Link className={reviewModal('apartament-link')} to={locationLink}>
          {/* <img className={reviewModal('apartament-image')} src={image} alt="" /> */}
          <picture className={reviewModal('apartament-image')}>
            <source
              type="image/webp"
              srcSet={`${image_url}/${image}-600x348.webp, ${image_url}/${image}-1200x696.webp 2x, ${image_url}/${image}-1800x1044.webp 3x`}
            />
            <img
              className={reviewModal('apartament-image')}
              src={`${image_url}/${image}-298x298.jpg`}
              srcSet={`${image_url}/${image}-596x596.jpg 2x, ${image_url}/${image}-894x894.jpg 3x`}
              alt=""
            />
          </picture>
          <div className={reviewModal('apartament-location')}>
            <h4 className={reviewModal('city')}>{city}</h4>
            <p className={reviewModal('apartament-address')}>{address}</p>
          </div>
        </Link>
        <h5 className={reviewModal('raiting-wrap')}>
          Поставьте оценку
          <Raiting
            // count={count}
            handleExternal={(value: any) => setCount(value)}
            externalClassName={reviewModal('raiting')}
          />
        </h5>
        <textarea
          className={reviewModal('textarea')}
          placeholder="Напишите отзыв..."
          value={text ?? ''}
          onChange={handleChange}
        />
        <div className={reviewModal('buttons-wrap')}>
          <Button
            text="Отправить отзыв"
            externalClassName={reviewModal('button')}
            handleExternal={handleSetReview}
            disable={!disableButton}
          />
          <Button
            text="Закрыть"
            transparent
            red
            handleExternal={handleClose}
            externalClassName={reviewModal('button')}
          />
        </div>
      </div>
    </div>
  );
};

export default ReviewModal;
