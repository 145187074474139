import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { IRangeSlider } from './interface';
import bemClassName from '../../utils/bem';

import './index.scss';

const rangeSlider = bemClassName('range-slider');

const RangeSlider: React.FC<IRangeSlider> = ({
  minValue = 0,
  maxValue = 10000,
  step = 1,
  fromParametr,
  toParametr,
  from,
  to,
  externalClassName = '',
  title,
  action,
}) => {
  const [fromValue, setFromValue] = useState<number | null>(null);
  const [toValue, setToValue] = useState<number | null>(null);

  const range = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  const handleChangeFromValue = (value: string) => {
    const currentValue = +value.replace(/[^\d]/g, '');
    if (currentValue >= minValue) {
      if (toValue && currentValue >= toValue) {
        setFromValue(toValue);
      } else if (toValue) {
        setFromValue(currentValue);
      } else {
        setFromValue(minValue);
      }
    }
  };

  const handleChangeToValue = (value: string) => {
    const currentValue = +value.replace(/[^\d]/g, '');
    if (currentValue <= maxValue) {
      if (fromValue && currentValue <= fromValue) {
        setToValue(fromValue);
      } else {
        setToValue(currentValue);
      }
    } else {
      setToValue(maxValue);
    }
  };

  useEffect(() => {
    if (range.current) {
      if (fromValue !== null) {
        range.current.style.left = `${(fromValue / maxValue) * 100}%`;
      }
      if (toValue !== null) {
        range.current.style.right = `${100 - (toValue / maxValue) * 100}%`;
      }
    }
    if (action && fromValue !== null && toValue !== null) {
      dispatch(action([fromValue, toValue]));
    }
  }, [toValue, fromValue]);

  useEffect(() => {
    if (
      fromParametr !== undefined &&
      toParametr !== undefined &&
      fromParametr >= minValue &&
      toParametr <= maxValue &&
      fromParametr <= maxValue &&
      toParametr >= minValue
    ) {
      setFromValue(fromParametr);
      setToValue(toParametr);
    } else {
      setFromValue(minValue);
      setToValue(maxValue);
    }
  }, [minValue, maxValue]);

  useEffect(() => {
    if (fromParametr === undefined && minValue) {
      setFromValue(minValue);
    }
    if (toParametr === undefined && maxValue) {
      setToValue(maxValue);
    }
  }, [fromParametr, toParametr]);

  useEffect(() => {
    if (fromParametr !== undefined) {
      setFromValue(fromParametr);
    }
    if (toParametr !== undefined) {
      setToValue(toParametr);
    }
    if (range.current) {
      range.current.style.left = `${(minValue / maxValue) * 100}%`;
      range.current.style.right = `${100 - (maxValue / maxValue) * 100}%`;
    }
  }, []);

  return (
    <div className={`${rangeSlider()} ${externalClassName}`}>
      {title && <p className={rangeSlider('title')}>{title}</p>}
      <div className={rangeSlider('field-wrapper')}>
        <div className={rangeSlider('input-wrapper')}>
          <div className={rangeSlider('field')}>
            {from && <span className={rangeSlider('field-placeholder')}>{from}</span>}
            <input
              type="number"
              className={rangeSlider('input')}
              value={`${fromValue}`}
              onInput={(event) => handleChangeFromValue((event.target as HTMLInputElement).value)}
              min={minValue}
              max={toValue || maxValue}
              step={step}
              pattern="[0-9]"
              // onChange={handleClickChangeValue}
            />
            {!from && !to && <span className={rangeSlider('field-placeholder')}>₽</span>}
          </div>
          <div className={rangeSlider('separator')} />
          <div className={rangeSlider('field')}>
            {to && <span className={rangeSlider('field-placeholder')}>{to}</span>}
            <input
              type="number"
              className={rangeSlider('input')}
              value={`${toValue}`}
              onInput={(event) => handleChangeToValue((event.target as HTMLInputElement).value)}
              min={fromValue || minValue}
              max={maxValue}
              step={step}
              // onChange={handleClickChangeValue}
            />
            {!from && !to && <span className={rangeSlider('field-placeholder')}>₽</span>}
          </div>
        </div>
        <div className={rangeSlider('slider')}>
          <div className={rangeSlider('slider-back')} />
          <div className={rangeSlider('progress-wrap')}>
            <div className={rangeSlider('progress')} ref={range} />
          </div>
          <div className={rangeSlider('range-wrapper')}>
            <input
              type="range"
              className={rangeSlider('range', { left: true })}
              min={minValue}
              max={maxValue}
              value={fromValue !== null ? fromValue : 0}
              step={step}
              onChange={(event) => handleChangeFromValue(event.target.value)}
              // onClick={handleClickChangeValue}
            />
            <input
              type="range"
              className={rangeSlider('range', { right: true })}
              min={minValue}
              max={maxValue}
              value={toValue !== null ? toValue : 0}
              step={step}
              onChange={(event) => handleChangeToValue(event.target.value)}
              // onClick={handleClickChangeValue}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RangeSlider;
