import bemClassName from '../../../utils/bem';
import './index.scss';
import { WEEK } from '../../../app_constants';

const weekdays = bemClassName('weekdays');

const WeekDays = () => {
  return (
    <div className={weekdays()}>
      {WEEK.map((dayOfWeek) => {
        return (
          <div className={weekdays('days')} key={dayOfWeek}>
            {dayOfWeek}
          </div>
        );
      })}
    </div>
  );
};

export default WeekDays;
