import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface DataState {
  totalBasketPrice: number | null;
}

const initialState: DataState = {
  totalBasketPrice: null,
};

export const BasketDataSlice = createSlice({
  name: 'basketData',
  initialState,
  reducers: {
    setTotalBasketPrice: (state, action: PayloadAction<number | null>) => {
      state.totalBasketPrice = action.payload;
    },
  },
});

export const { setTotalBasketPrice } = BasketDataSlice.actions;

export default BasketDataSlice.reducer;
