// import axios from 'axios';
// import { BASE_API_URL, CLIENT_SECRET } from '../../app_constants';

// const controllerInterseptorRequest = new AbortController();

// export const axiosInterseptor = axios.create({
//   baseURL: BASE_API_URL,
// });

// let copyArray: any = [];

// const refreshDeadToken = () => {
//   controllerInterseptorRequest.abort();
//   const refreshToken = localStorage.getItem('refresh_token');
//   return (
//     axios
//       .post('https://hoho24.ru/web/token', {
//         refresh_token: refreshToken,
//         client_secret: CLIENT_SECRET,
//       })
//       .then((res) => {
//         if (res.status === 200) {
//           const newTokenExpirationTime = Date.now() + +res.data.expires_in * 1000;
//           localStorage.setItem('access_token', res.data.access_token);
//           localStorage.setItem('refresh_token', res.data.refresh_token);
//           localStorage.setItem('tokenExpirationTime', String(newTokenExpirationTime));
//           if (copyArray.length > 0) {
//             copyArray.map((item: any) => {
//               // eslint-disable-next-line
//               item.retry = true;
//               // eslint-disable-next-line
//               if (
//                 res.status === 200 &&
//                 res &&
//                 res.data &&
//                 res.data.access_token &&
//                 res.data.access_token !== '' &&
//                 res.data.access_token !== null &&
//                 res.data.access_token !== undefined &&
//                 item &&
//                 item.headers &&
//                 item.headers.Authorization
//               ) {
//                 // eslint-disable-next-line
//                 item.headers.Authorization = `Bearer ${res.data.access_token}`;
//               }
//               return (item.signal && item.signal.aborted !== true) || item.signal === undefined
//                 ? axiosInterseptor.request(item)
//                 : undefined;
//             });
//           }
//           copyArray = [];
//         }
//       })
//       // .finally(() => {
//       //   window.location.reload();
//       // })
//       .catch((error) => {
//         if (error.name === 'CanceledError') {
//           // console.log(error);
//         }
//         if (error.request) {
//           localStorage.removeItem('access_token');
//           localStorage.removeItem('refresh_token');
//           localStorage.removeItem('tokenExpirationTime');
//           localStorage.removeItem('id_token');
//           window.location.href = '/';
//         }
//       })
//   );
// };

// let copy = false;

// export const refreshTokenInterseptor = () => {
//   return axiosInterseptor.interceptors.request.use(
//     (config) => {
//       const accessToken = localStorage.getItem('access_token');
//       const tokenExpirationTime = localStorage.getItem('tokenExpirationTime');

//       // if (config) {
//       let configuration = { ...config };
//       if (accessToken !== null && accessToken !== '') {
//         if (tokenExpirationTime && new Date().getTime() < Number(tokenExpirationTime)) {
//           if (copy) {
//             copy = false;
//           }
//           if (
//             configuration &&
//             config &&
//             accessToken &&
//             accessToken !== '' &&
//             accessToken !== null &&
//             accessToken !== undefined
//           ) {
//             configuration.headers.Authorization = `Bearer ${accessToken}`;
//           }
//         } else {
//           configuration = { ...config, signal: { aborted: true } };
//           // controllerInterseptorRequest.abort();
//           // console.log('configuration', configuration);
//           copyArray.push(config);
//           if (!copy) {
//             copy = true;
//             refreshDeadToken();
//           }
//         }
//       }
//       // }
//       return configuration;
//     },
//     (error) => {
//       localStorage.removeItem('access_token');
//       localStorage.removeItem('refresh_token');
//       localStorage.removeItem('tokenExpirationTime');
//       localStorage.removeItem('id_token');
//       window.location.href = '/';
//       console.error('Ошибка в ответе', error);
//       if (error.name === 'CanceledError') {
//         // console.log(error);
//       }
//       return Promise.reject(error);
//     },
//   );
// };

import axios from 'axios';
import { BASE_API_URL, BASE_API_URL_SECRET, CLIENT_SECRET } from '../../app_constants';

// const controllerInterseptorRequest = new AbortController();

const axiosInterseptor = axios.create();

// let copyArray: any = [];
let waitRefreshToken: any = null;

const refreshDeadToken = () => {
  waitRefreshToken = 1;
  const refreshToken = localStorage.getItem('refresh_token');
  waitRefreshToken = new Promise((resolve) => {
    axios
      .post('https://hoho24.ru/web/token', {
        refresh_token: refreshToken,
        client_secret: CLIENT_SECRET,
      })
      .then((res) => {
        if (res.status === 200) {
          const newTokenExpirationTime = Date.now() + +res.data.expires_in * 1000;
          localStorage.setItem('access_token', res.data.access_token);
          localStorage.setItem('refresh_token', res.data.refresh_token);
          localStorage.setItem('tokenExpirationTime', String(newTokenExpirationTime));
          resolve(res.data.access_token);
        }
      })
      .catch((error) => {
        if (error.name === 'CanceledError') {
          // console.log(error);
        }
        if (error.request) {
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('tokenExpirationTime');
          localStorage.removeItem('id_token');
          window.location.href = '/';
        }
      });
  });
  return waitRefreshToken;
  // return axios
  //   .post('https://hoho24.ru/web/token', {
  //     refresh_token: refreshToken,
  //     client_secret: CLIENT_SECRET,
  //   })
  //   .then((res) => {
  //     if (res.status === 200) {
  //       const newTokenExpirationTime = Date.now() + +res.data.expires_in * 1000;
  //       localStorage.setItem('access_token', res.data.access_token);
  //       localStorage.setItem('refresh_token', res.data.refresh_token);
  //       localStorage.setItem('tokenExpirationTime', String(newTokenExpirationTime));
  //       if (copyArray.length > 0) {
  //         copyArray.map((item: any) => {
  //           // eslint-disable-next-line
  //           item.retry = true;
  //           // eslint-disable-next-line
  //           if (
  //             res.status === 200 &&
  //             res &&
  //             res.data &&
  //             res.data.access_token &&
  //             res.data.access_token !== '' &&
  //             res.data.access_token !== null &&
  //             res.data.access_token !== undefined &&
  //             item &&
  //             item.headers &&
  //             item.headers.Authorization
  //           ) {
  //             // eslint-disable-next-line
  //             item.headers.Authorization = `Bearer ${res.data.access_token}`;
  //           }
  //           return (item.signal && item.signal.aborted !== true) || item.signal === undefined
  //             ? axiosInterseptor.request(item)
  //             : undefined;
  //         });
  //       }
  //       copyArray = [];
  //     }
  //   })
  //   .catch((error) => {
  //     if (error.name === 'CanceledError') {
  //       // console.log(error);
  //     }
  //     if (error.request) {
  //       localStorage.removeItem('access_token');
  //       localStorage.removeItem('refresh_token');
  //       localStorage.removeItem('tokenExpirationTime');
  //       localStorage.removeItem('id_token');
  //       window.location.href = '/';
  //     }
  //   });
};

// let copy = false;

// export const refreshTokenInterseptor = () => {
// return
axiosInterseptor.interceptors.request.use(
  // eslint-disable-next-line
  async (config: any) => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      // if (axiosInterseptor.defaults.baseURL !== BASE_API_URL_SECRET) {
      //   // axiosInterseptor.defaults.baseURL = BASE_API_URL_SECRET;
      // eslint-disable-next-line
      // config.baseURL = BASE_API_URL_SECRET;
      //   // console.log(config);
      // }
      const tokenExpirationTime = localStorage.getItem('tokenExpirationTime');
      const configuration = { ...config, baseURL: BASE_API_URL_SECRET };
      if (accessToken !== null && accessToken !== '') {
        if (tokenExpirationTime && new Date().getTime() < Number(tokenExpirationTime)) {
          if (waitRefreshToken) {
            waitRefreshToken = null;
          }
          if (
            configuration &&
            config &&
            accessToken &&
            accessToken !== '' &&
            accessToken !== null &&
            accessToken !== undefined
          ) {
            configuration.headers.Authorization = `Bearer ${accessToken}`;
            return configuration;
          }
        } else {
          if (waitRefreshToken === null) {
            configuration.headers.Authorization = await refreshDeadToken();
          } else {
            configuration.headers.Authorization = await waitRefreshToken;
          }
          return configuration;
        }
      }
    } else {
      // axiosInterseptor.defaults.baseURL = BASE_API_URL;
      // eslint-disable-next-line
      return { ...config, baseURL: BASE_API_URL };
    }
  },
  (error) => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('tokenExpirationTime');
    localStorage.removeItem('id_token');
    window.location.href = '/';
    console.error('Ошибка в ответе', error);
    if (error.name === 'CanceledError') {
      // console.log(error);
    }
    return Promise.reject(error);
  },
);
// };

export default axiosInterseptor;
