import { useSelector, useDispatch } from 'react-redux';
import bemClassName from '../../../utils/bem';
import './index.scss';

import { RootState } from '../../../store';
import { setCheckIn, setDeparture } from '../../../store/reducers/search/searchDataSlice';
import { ADD_DAYS } from '../../../app_constants';

const buttonAdd = bemClassName('button-add');

const AddDays = () => {
  const calendarSelected = useSelector((state: RootState) => state.searchData.calendarSelected);
  const maxDate = new Date(
    useSelector((state: RootState) => state.calendarData.dateInYear.maxDate),
  );
  const minDate = new Date(
    useSelector((state: RootState) => state.calendarData.dateInYear.minDate),
  );
  const dispatch = useDispatch();
  const handleAddDays = (numDays: number) => {
    if (calendarSelected) {
      if (calendarSelected.departure) {
        const newDate = new Date(calendarSelected.departure);
        newDate.setDate(newDate.getDate() + numDays);
        if (newDate > maxDate) {
          dispatch(setDeparture(maxDate.toString()));
        } else if (newDate >= minDate) {
          dispatch(setDeparture(newDate.toString()));
        }
      }
      if (calendarSelected.checkIn) {
        const newDate = new Date(calendarSelected.checkIn);
        newDate.setDate(newDate.getDate() - numDays);
        if (newDate < minDate) {
          dispatch(setCheckIn(minDate.toString()));
        } else if (newDate <= maxDate) {
          dispatch(setCheckIn(newDate.toString()));
        }
        if (!calendarSelected.departure) {
          const newDateCheckIn = new Date(calendarSelected.checkIn);
          newDateCheckIn.setDate(newDateCheckIn.getDate() + numDays);
          dispatch(setDeparture(newDateCheckIn.toString()));
        }
      }
    }
  };

  const buttons = Array.from(ADD_DAYS, (numDays, index) => (
    <button
      type="button"
      key={index}
      className={buttonAdd('days')}
      onClick={() => handleAddDays(numDays)}>
      ± {numDays} день
    </button>
  ));

  return <div className={buttonAdd()}>{buttons}</div>;
};

export default AddDays;
