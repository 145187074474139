import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Container from '../../UI/Container';
import MainPageBanner from '../../components/MainPageBanner';
import Advantages from '../../components/Advantages';
import City from '../../components/City';
import MobileApp from '../../components/MobileApp';
import Review from '../../components/Review';
import Search from '../../components/Search';

import bemClassName from '../../utils/bem';

import './index.scss';
import { RootState } from '../../store';
import { setCitySelected } from '../../store/reducers/search/searchDataSlice';
import ReviewData from '../../API/ReviewData';
import AppData from '../../API/AppData';

const homePage = bemClassName('home-page');

const Home = () => {
  const [dataReview, setDataReview] = useState<any[]>();
  const [dataCityCard, setDataCityCard] = useState<any[]>();

  useEffect(() => {
    document.title = 'Home Hotel 24';

    AppData.getCityCardData()
      .then((item) => {
        setDataCityCard(item);
      })
      .catch(() => {
        return null;
      });

    ReviewData.getCollectionReviews()
      .then((items) => {
        setDataReview(Object.values(items));
      })
      .catch((error) => {
        if (error) {
          setDataReview(undefined);
        }
      });
  }, []);

  const cityData = useSelector((state: RootState) => state.appData.cityData);

  const dispatch = useDispatch();

  const handleClick = (event: React.MouseEvent, cityName: string) => {
    event.stopPropagation();
    const cityFiltredName = cityData.find((item) => item.name === cityName);
    if (cityFiltredName !== undefined) {
      dispatch(setCitySelected(cityFiltredName));
    }
  };

  const cityArray = ['Оренбург', 'Самара', 'Уфа'];

  return (
    <>
      <MainPageBanner>
        <h3 className={homePage('title')}>Аренда квартир на сутки</h3>
        <h1 className={homePage('sub-title')}>Возможность бесконтактного заселения</h1>
        <Search />
        <div className={homePage('serch-link-wrap')}>
          {cityArray.map((item) => {
            return (
              <Link
                onClick={(event) => handleClick(event, item)}
                key={item}
                className={homePage('serch-link')}
                to={`/catalog/${item}`}>
                {item}
              </Link>
            );
          })}

          {/* <Link className={homePage("serch-link")} to={"/catalog/"}>
            Самара
          </Link>
          <Link className={homePage('serch-link')} to="/catalog/">
            Уфа
          </Link> */}
        </div>
      </MainPageBanner>
      <Container>
        <div className={homePage('inner')}>
          <Advantages />
          {dataCityCard && dataCityCard.length > 0 && <City data={dataCityCard} />}
          <MobileApp />
        </div>
      </Container>
      {dataReview && dataReview.length > 5 && <Review data={dataReview} />}
    </>
  );
};

export default Home;
