import { useEffect, useState } from 'react';

import { IScrollingButtons } from './interface';
import bemClassName from '../../utils/bem';

import './index.scss';

const scrollingButtons = bemClassName('scrolling-buttons');

const ScrollingButtons: React.FC<IScrollingButtons> = ({
  data,
  defaultSelected,
  handleExternal,
  externalClassName = '',
}) => {
  const [selectedItem, setSelectedItem] = useState('');

  const handleSelect = (item: string) => {
    setSelectedItem(item);
  };

  useEffect(() => {
    setSelectedItem(defaultSelected);
  }, []);

  useEffect(() => {
    if (handleExternal) {
      handleExternal(selectedItem);
    }
  }, [selectedItem]);
  return (
    <div className={`${scrollingButtons()} ${externalClassName}`}>
      <div className={scrollingButtons('inner')}>
        {Array.isArray(data[0]) &&
          data[0].map((item: any, id: any) => {
            if (typeof item === 'object' && item.name) {
              return (
                <div
                  className={scrollingButtons('button', {
                    active: selectedItem === item.name,
                  })}
                  key={id}
                  onClick={() => handleSelect(item.name)}>
                  {item.name}
                </div>
              );
            }
            return null;
          })}
        {data.map((item, id) => {
          if (typeof item === 'string')
            return (
              <div
                className={scrollingButtons('button', {
                  active: selectedItem === item,
                })}
                key={id}
                onClick={() => handleSelect(item)}>
                {item}
              </div>
            );
          return null;
        })}
      </div>
    </div>
  );
};

export default ScrollingButtons;
