import svgProduct from '../../assets/svg/room.svg';
import { ICatalogItemData } from '../../components/CatalogItem/interface';
import { IDetailCatalog } from '../../pages/CatalogDetail/interface';
import { getTypeCatalogItems } from '../catalogUtils';
import svgButtonIn from '../../assets/svg/button_pluse.svg';
import svgButtonOut from '../../assets/svg/button_minus.svg';

const getApartment = (condition: number) => {
  const apartment = document.createElement('span');
  apartment.className = 'custom-marker__apartment';
  apartment.textContent = getTypeCatalogItems(condition);
  return apartment;
};

const getPlase = (place: any) => {
  const places = document.createElement('span');
  places.className = 'custom-marker__info';
  places.textContent = `${place}спальных мест`;
  return places;
};

const getPrice = (priceValue: number) => {
  const price = document.createElement('span');
  price.className = 'custom-marker__price';
  price.textContent = `${priceValue} ₽`;
  return price;
};

const getArea = (areaValue: number) => {
  const area = document.createElement('span');
  area.className = 'custom-marker__info';
  area.textContent = `${areaValue} м2 общая площадь`;
  return area;
};

const getPhotos = (imageSrc: string, photos_url?: string) => {
  const image = document.createElement('img');
  // image.src = images.find((i, j) => j === 0) ?? '';
  image.src = photos_url ? `${photos_url}/${imageSrc}-596x596.jpg` : imageSrc;
  image.className = 'custom-marker__placeholder-image';
  return image;
};

const getRoomsLenght = (count: number) => {
  switch (true) {
    case count % 100 >= 11 && count % 100 <= 20:
      return 'квартир';
    case count % 10 === 1:
      return 'квартира';
    case count % 10 >= 2 && count % 10 <= 4:
      return 'квартиры';
    default:
      return 'квартир';
  }
};

export const getMarker = (item: ICatalogItemData, cityName: string, mobile?: any) => {
  const customMarker = document.createElement('div');
  customMarker.className = 'custom-marker';

  if (item && item.coords) {
    // const coords = Object.values(item.coords).toString();
    // console.log(coords);
    // const duble = coords;
    // const arrItem = Object.values(item);
    // const allValues = arrItem.flatMap((items) => Object.values(items));
    // console.log(arrItem);
  }

  const customMarkerSpan = document.createElement('span');
  customMarkerSpan.className = 'custom-marker__rooms-quantity';
  customMarkerSpan.textContent = '- комнатная';

  if (item.condition && item.condition.type === (3 || 2)) {
    customMarker.classList.add('custom-marker--orange');
  }
  if (item.condition && item.condition.type === 1) {
    customMarker.classList.add('custom-marker--blue');
  }
  if (item.condition && item.condition.type === 0) {
    customMarker.classList.add('custom-marker--gray');
  }

  const customMarkerTitle = document.createElement('span');
  if (item.rooms) {
    customMarkerTitle.textContent = item.rooms.toString();
    customMarkerTitle.className = 'custom-marker__title';
  }

  const customMarkerImage = document.createElement('img');
  customMarkerImage.src = svgProduct;
  customMarkerImage.className = 'custom-marker__image';

  const customMarkerPlacholder = document.createElement('a');
  customMarkerPlacholder.className = 'custom-marker__placeholder';
  customMarkerPlacholder.href = `/catalog/${cityName}/${item.id}`;

  if (item.condition && (item.condition.type === 3 || item.condition.type === 2)) {
    customMarkerPlacholder.classList.add('custom-marker__placeholder--orange');
  }
  if (item.condition && item.condition.type === 1) {
    customMarkerPlacholder.classList.add('custom-marker__placeholder--blue');
  }
  if (item.condition && item.condition.type === 0) {
    customMarkerPlacholder.classList.add('custom-marker__placeholder--gray');
  }

  const customMarkerPlacholderContent = document.createElement('div');
  customMarkerPlacholderContent.className = 'custom-marker__placeholder-content';

  customMarker.appendChild(customMarkerImage);
  customMarker.appendChild(customMarkerTitle);

  if (item.condition && item.condition.type !== undefined) {
    customMarkerPlacholderContent.appendChild(getApartment(item.condition.type));
  }
  if (item.bedcode) {
    customMarkerPlacholderContent.appendChild(getPlase(item.bedcode));
  }
  if (item.area && item.area.total !== undefined && item.area.total !== null) {
    customMarkerPlacholderContent.appendChild(getArea(item.area.total));
  }
  if (item.price !== undefined && item.price !== null) {
    customMarkerPlacholderContent.appendChild(getPrice(item.price));
  }
  if (
    item.photos !== undefined &&
    item.photos !== null &&
    item.photos.length > 0 &&
    item.photos_url
  ) {
    customMarkerPlacholder.appendChild(getPhotos(item.photos[0], item.photos_url));
  }

  customMarker.appendChild(customMarkerSpan);
  customMarkerPlacholder.appendChild(customMarkerPlacholderContent);

  customMarker.appendChild(customMarkerPlacholder);

  const mouselog = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const mapElement = document.getElementById('map');
    if (mapElement) {
      mapElement.addEventListener('touchstart', (e) => {
        if (
          (e.target && !customMarkerPlacholder.contains(e.target as Node)) ||
          e.target !== mapElement
        ) {
          if (event.target === customMarkerPlacholder) {
            customMarkerPlacholder.classList.remove('custom-marker__placeholder--mobile');
            customMarker.appendChild(customMarkerPlacholder);
            event.stopPropagation();
          }
        }
      });
    }

    if (event.type === 'touchstart') {
      if (mapElement && mapElement.contains(customMarkerPlacholder)) {
        const existingMarkers = document.querySelectorAll(
          '.custom-marker__placeholder-box--mobile',
        );
        existingMarkers.forEach((marker) =>
          marker.classList.remove('custom-marker__placeholder-box--mobile'),
        );
        customMarkerPlacholder.classList.add('custom-marker__placeholder--mobile');
        mapElement.appendChild(customMarkerPlacholder);
      }
    }

    if (mapElement) {
      mapElement.addEventListener('touchstart', (events) => {
        if (!customMarkerPlacholder.contains(events.target as Node)) {
          customMarkerPlacholder.classList.remove('custom-marker__placeholder--mobile');
          event.stopPropagation();
        }
      });
    }
  };

  if (mobile) {
    customMarker.addEventListener('touchstart', mouselog);
  }

  return customMarker;
};

export const getMarkerDoubler = (item: Array<ICatalogItemData>, cityName: string, mobile?: any) => {
  const customMarker = document.createElement('div');
  customMarker.className = 'custom-marker';

  if (item.some((element) => element.condition && element.condition.type === 4)) {
    //
  } else if (
    item.some(
      (element) =>
        element.condition && (element.condition.type === 3 || element.condition.type === 2),
    )
  ) {
    customMarker.classList.add('custom-marker--orange');
  } else {
    item.map((element) => {
      if (element.condition && element.condition.type === 1) {
        customMarker.classList.add('custom-marker--blue');
      }
      if (element.condition && element.condition.type === 0) {
        customMarker.classList.add('custom-marker--gray');
      }
      return null;
    });
  }

  const customMarkerTitle = document.createElement('span');
  if (item) {
    customMarkerTitle.textContent = `${item.length.toString()} ${getRoomsLenght(item.length)}`;
    customMarkerTitle.className = 'custom-marker__title';
    customMarker.appendChild(customMarkerTitle);
  }

  const customMarkerPlacholderBox = document.createElement('div');
  customMarkerPlacholderBox.className = 'custom-marker__placeholder-box';
  customMarker.appendChild(customMarkerPlacholderBox);

  const customMarkerBoxContent = item.map((element) => {
    const content = document.createElement('a');
    content.className = 'custom-marker__placeholder';
    content.classList.add('custom-marker--dubler');
    const innerContent = document.createElement('div');
    innerContent.className = 'custom-marker__placeholder-content';

    if (element) {
      content.href = `/catalog/${cityName}/${element.id}`;
    }
    if (
      element.photos !== undefined &&
      element.photos !== null &&
      element.photos.length > 0 &&
      element.photos_url
    ) {
      content.appendChild(getPhotos(element.photos[0], element.photos_url));
    }
    if (element.condition && element.condition.type !== undefined) {
      innerContent.appendChild(getApartment(element.condition.type));
    }
    if (element.bedcode) {
      innerContent.appendChild(getPlase(element.bedcode));
    }
    if (element.area && element.area.total !== undefined && element.area.total !== null) {
      innerContent.appendChild(getArea(element.area.total));
    }
    if (element.price !== undefined && element.price !== null) {
      innerContent.appendChild(getPrice(element.price));
    }

    if (element.condition && (element.condition.type === 3 || element.condition.type === 2)) {
      content.classList.add('custom-marker__placeholder--orange');
    }
    if (element.condition && element.condition.type === 1) {
      content.classList.add('custom-marker__placeholder--blue');
    }
    if (element.condition && element.condition.type === 0) {
      content.classList.add('custom-marker__placeholder--gray');
    }

    content.appendChild(innerContent);
    return content;
  });

  customMarkerPlacholderBox.append(...customMarkerBoxContent);

  const mouselog = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const mapElement = document.getElementById('map');

    if (mapElement) {
      mapElement.addEventListener('touchstart', (e) => {
        const customMarkerBoxContentLinks = customMarkerBoxContent.filter(
          (element) => element instanceof HTMLAnchorElement,
        );

        if (
          e.target &&
          !customMarkerPlacholderBox.contains(e.target as Node) &&
          !customMarkerBoxContentLinks.includes(e.target as HTMLAnchorElement)
        ) {
          customMarkerPlacholderBox.classList.remove('custom-marker__placeholder-box--mobile');
          event.stopPropagation();
        }
      });
    }

    if (event.type === 'touchstart') {
      if (mapElement && mapElement.contains(customMarkerPlacholderBox)) {
        const existingMarkers = document.querySelectorAll(
          '.custom-marker__placeholder-box--mobile',
        );
        existingMarkers.forEach((marker) =>
          marker.classList.remove('custom-marker__placeholder-box--mobile'),
        );

        customMarkerPlacholderBox.classList.add('custom-marker__placeholder-box--mobile');
        mapElement.appendChild(customMarkerPlacholderBox);
      }
    }

    if (mapElement) {
      mapElement.addEventListener('touchstart', (events) => {
        if (!customMarkerPlacholderBox.contains(events.target as Node)) {
          customMarkerPlacholderBox.classList.remove('custom-marker__placeholder-box--mobile');
          event.stopPropagation();
        }
      });
    }
  };

  if (mobile) {
    customMarker.addEventListener('touchstart', mouselog);
  }

  return customMarker;
};

export const getMarkerRoom = (item: IDetailCatalog) => {
  const customMarker = document.createElement('div');
  customMarker.className = 'custom-marker';

  // if (item.condition && item.condition.type === (3 || 2)) {
  //   customMarker.classList.add('custom-marker--orange');
  // }
  // if (item.condition && item.condition.type === 1) {
  //   customMarker.classList.add('custom-marker--blue');
  // }
  // if (item.condition && item.condition.type === 0) {
  //   customMarker.classList.add('custom-marker--gray');
  // }

  // const customMarkerTitle = document.createElement('span');
  // if (item.rooms) {
  //   customMarkerTitle.textContent = item.rooms.toString();
  //   customMarkerTitle.className = 'custom-marker__title';
  // }

  const customMarkerImage = document.createElement('img');
  customMarkerImage.src = svgProduct;
  customMarkerImage.className = 'custom-marker__image';

  const customMarkerPlacholder = document.createElement('div');
  customMarkerPlacholder.className = 'custom-marker__placeholder';

  const customMarkerPlacholderContent = document.createElement('div');
  customMarkerPlacholderContent.className = 'custom-marker__placeholder-content';

  customMarker.appendChild(customMarkerImage);
  // customMarker.appendChild(customMarkerTitle);

  // if (item.condition && item.condition.type !== undefined) {
  //   customMarkerPlacholderContent.appendChild(getApartment(item.condition.type));
  // }
  // if (item.bedcode) {
  //   customMarkerPlacholderContent.appendChild(getPlase(item.bedcode));
  // }
  // if (item.area && item.area.total !== undefined && item.area.total !== null) {
  //   customMarkerPlacholderContent.appendChild(getArea(item.area.total));
  // }
  if (item.price !== undefined && item.price !== null) {
    customMarkerPlacholderContent.appendChild(getPrice(item.price));
  }
  if (item.photos !== undefined && item.photos !== null && item.photos.length > 0) {
    customMarkerPlacholder.appendChild(getPhotos(item.photos[0]));
  }

  // customMarkerPlacholder.appendChild(customMarkerPlacholderContent);

  // customMarker.appendChild(customMarkerPlacholder);

  return customMarker;
};

export const getGroupedData = (dataGruop: Array<ICatalogItemData>) => {
  const groupDublicate: Array<ICatalogItemData | Array<ICatalogItemData>> = [];
  dataGruop.forEach((item) => {
    const itemDubler = groupDublicate.find(
      (i) =>
        !Array.isArray(i) &&
        i.coords &&
        item.coords &&
        i.coords.lat === item.coords.lat &&
        i.coords.lon === item.coords.lon,
    );
    const itemDublerArray = groupDublicate.find(
      (i) =>
        Array.isArray(i) &&
        i[0].coords &&
        item.coords &&
        i[0].coords.lat === item.coords.lat &&
        i[0].coords.lon === item.coords.lon,
    );

    if (!(itemDubler || itemDublerArray)) {
      groupDublicate.push(item);
    } else if (itemDubler) {
      const removed = groupDublicate.splice(groupDublicate.indexOf(itemDubler), 1);

      removed.push(item);
      groupDublicate.push(removed as Array<ICatalogItemData>);
    } else if (itemDublerArray && Array.isArray(itemDublerArray)) {
      itemDublerArray.push(item);
    }
  });
  return groupDublicate;
};

export const getButtonInOutIcon = () => {
  const result = () => {
    const plusButtonImg = document.createElement('img');
    plusButtonImg.src = svgButtonIn;

    const minusButtonImg = document.createElement('img');
    minusButtonImg.src = svgButtonOut;

    const olZoomInElement = document.querySelector('.ol-zoom-in');
    const olZoomOutElement = document.querySelector('.ol-zoom-out');

    if (olZoomInElement && olZoomOutElement) {
      olZoomInElement.textContent = '';
      olZoomOutElement.textContent = '';
      olZoomInElement.appendChild(plusButtonImg);
      olZoomOutElement.appendChild(minusButtonImg);
    }
  };
  return result();
};
