import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { IFooterLinks } from './interface';
import bemClassName from '../../utils/bem';

import belcard from '../../assets/images/logotip-belkart-v-odnom-tsvete-gorizontalnyy.png';
import mir from '../../assets/images/national-payment-system-mir.png';
import visa from '../../assets/images/payment-system-visa.png';
import mastercard from '../../assets/images/payment-system-mastercard.png';
import googlePlay from '../../assets/images/google-play-button.png';
import appStore from '../../assets/images/app-store-button.png';

import './index.scss';
import { RootState } from '../../store';

const footerLinks = bemClassName('footer-links');

const FooterLinks: React.FC<IFooterLinks> = ({ dataLinks, title, special }) => {
  const contactPhones = useSelector((state: RootState) => state.contactData.contactPhones);

  return (
    <>
      {!special && (
        <div className={footerLinks()}>
          <h5 className={footerLinks('list-title')}>{title}</h5>
          <div className={footerLinks('list')}>
            {dataLinks?.map((item, id) => {
              return (
                <Link className={footerLinks('link')} key={id} to={item.link}>
                  {item.name}
                </Link>
              );
            })}
          </div>
        </div>
      )}
      {special === 'phones' && (
        <div className={footerLinks()}>
          <h5 className={footerLinks('list-title')}>{title}</h5>
          <div className={footerLinks('list')}>
            {contactPhones &&
              contactPhones.data &&
              contactPhones.data.map((items, id) => {
                return (
                  <Link key={id} to={`tel:${items.phone.E164}`} className={footerLinks('link')}>
                    {items.phone.international}
                  </Link>
                );
              })}
          </div>
        </div>
      )}
      {special === 'payment' && (
        <div className={footerLinks({ payment: true })}>
          <h5 className={footerLinks('list-title')}>{title}</h5>
          <div className={footerLinks('list', { payment: true })}>
            <div className={footerLinks('item', { payment: true })}>
              <img className={footerLinks('image')} src={belcard} alt="Белкарт" />
            </div>
            <div className={footerLinks('item', { payment: true })}>
              <img className={footerLinks('image')} src={mir} alt="МИР" />
            </div>
            <div className={footerLinks('item', { payment: true })}>
              <img className={footerLinks('image')} src={visa} alt="Visa" />
            </div>
            <div className={footerLinks('item', { payment: true })}>
              <img className={footerLinks('image')} src={mastercard} alt="Mastercard" />
            </div>
          </div>
        </div>
      )}
      {special === 'apps' && (
        <div className={footerLinks()}>
          <h5 className={footerLinks('list-title')}>{title}</h5>
          <div className={footerLinks('list')}>
            <Link to="#t" className={footerLinks('link', { apps: true })}>
              <img className={footerLinks('image', { apps: true })} src={googlePlay} alt="" />
            </Link>
            <Link to="#t" className={footerLinks('link', { apps: true })}>
              <img className={footerLinks('image', { apps: true })} src={appStore} alt="" />
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default FooterLinks;
