import axiosInterseptor from '../../utils/axiosInterseptor';

export default class AppData {
  static async getCityData() {
    try {
      const response = await axiosInterseptor.get('addreses/cites/all?mode=data');
      const { data } = response;
      return data.data;
    } catch (error) {
      return error;
    }
  }

  static async getCityCardData() {
    try {
      const response = await axiosInterseptor.get('addreses/cites/all?mode=search');
      const { data } = response;
      return data.data;
    } catch (error) {
      return error;
    }
  }

  static async getRoomsData() {
    const response = await fetch(`/roomsData.json`);
    const data = await response.json();
    return data;
  }
}
