import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import ScrollingButtons from '../../UI/ScrollingButtons';
import Pagination from '../../UI/Pagination';
import HistoryBookingList from '../../components/HistoryBookingList';
import Loading from '../../UI/Loading';

import bemClassName from '../../utils/bem';
import PrivateData from '../../API/PrivateData';
import formatedDateOfStay from '../../utils/historyBookingsUtils';
import AppData from '../../API/AppData';

import './index.scss';

const historyBookings = bemClassName('history-bookings');

const HistoryBookings = () => {
  const [data, setData] = useState<Array<any>>();
  const [metaData, setMetaData] = useState<any>();
  const [citySelectedHistoryBookings, setCitySelectedHistoryBookings] = useState<any>();
  const [cityId, setCityId] = useState<any>();
  const [cityData, setCityData] = useState<any>();
  const [pageHistoryBookings, setPageHistoryBookings] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isLogin = useSelector((state: RootState) => state.autorizationStatus.isLogin);

  const controllerHistoryBooking = new AbortController();

  useEffect(() => {
    setIsLoading(true);
    if (isLogin !== null && isLogin) {
      let params;
      if (citySelectedHistoryBookings !== 'Все') {
        params = { id: cityId };
      }
      PrivateData.getPrivateUserDealsHistoryBooking({
        ...params,
        signal: controllerHistoryBooking.signal,
      }).then((item) => {
        if (item.data && item.meta) {
          setData(item.data);
          setMetaData(item.meta);
          setIsLoading(false);
        } else {
          setIsLoading(true);
        }
      });
    }

    return () => {
      setIsLoading(true);
      controllerHistoryBooking.abort();
    };
  }, [isLogin, cityId, citySelectedHistoryBookings]);

  useEffect(() => {
    if (cityData) {
      const cityItem = cityData.find((item: any) => item.name === citySelectedHistoryBookings);
      if (cityItem) {
        setCityId(cityItem.id);
      }
    }
    if (citySelectedHistoryBookings !== cityData) {
      setPageHistoryBookings(1);
    }
  }, [cityData, citySelectedHistoryBookings]);

  useEffect(() => {
    document.title = 'История бронирований';

    AppData.getCityData().then((item) => {
      setCityData(item);
    });
  }, []);

  return (
    <>
      <ScrollingButtons
        data={[cityData, 'Все']}
        defaultSelected="Все"
        externalClassName={historyBookings('city')}
        handleExternal={(value: string) => setCitySelectedHistoryBookings(value)}
      />
      {isLoading ? (
        <Loading externalClassName={historyBookings('loading')} />
      ) : (
        <>
          {data && data.length > 0 ? (
            data.map((item: any) => {
              let statusBooking;
              if (item.executing === true) {
                statusBooking = 'Успешно прожил';
              } else if (item.lose === true) {
                statusBooking = 'Отменено';
              } else {
                statusBooking = 'Нет данных';
              }
              return (
                <HistoryBookingList
                  data={[
                    {
                      id: item.product.id,
                      image: item.product.photos[0],
                      photos_url: item.product.photos_url,
                      city: item.product.city.name,
                      address: `${item.product.rooms}-к. квартира на ${item.address.name}`,
                      dateBooking: formatedDateOfStay(item.begindate, item.closedate),
                      statusBooking,
                      review: false,
                    },
                  ]}
                />
              );
            })
          ) : (
            <div className={historyBookings('not-found')}>
              <h2>Извините, ничего не найдено</h2>
              <p className={historyBookings('not-found-text')}>
                По вашему запросу не обнаружено истории бронирований.
              </p>
            </div>
          )}
        </>
      )}
      {metaData && metaData.pages > 1 && (
        <Pagination
          totalPageCount={metaData.pages}
          currentPage={pageHistoryBookings}
          externalClassName={historyBookings('pagination')}
          handleExternal={(value: number) => setPageHistoryBookings(value)}
        />
      )}
    </>
  );
};

export default HistoryBookings;
