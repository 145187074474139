import React from 'react';
import { useLocation, Link } from 'react-router-dom';

import { ISideMenu } from './interface';
import bemClassName from '../../utils/bem';

import './index.scss';
import { CHANGE_PASSWORD } from '../../app_constants';

const sideMenu = bemClassName('side-menu');

const SideMenu: React.FC<ISideMenu> = ({ data, externalClassName = '' }) => {
  const location = useLocation();

  return (
    <div className={`${sideMenu()} ${externalClassName}`}>
      {data.map((item, id) => {
        // const hash = item.hash ? `#${item.hash}` : '';
        // const hash = item.hash ? (item.hash === CHANGE_PASSWORD ? item.hash : `#${item.hash}`) : '';
        let hash = '';
        if (item.hash) {
          if (item.hash === CHANGE_PASSWORD) {
            hash = item.hash;
          } else {
            hash = `#${item.hash}`;
          }
        }
        return (
          <Link
            className={sideMenu('link', {
              active:
                location.hash === hash || (location.hash.startsWith(hash) && hash === '#messages'),
            })}
            key={id}
            to={hash}>
            {item.text}
          </Link>
        );
      })}
    </div>
  );
};

export default SideMenu;
