import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { closeSearchModal } from '../../store/reducers/modal/modalSearchSlice';
import { setAdults, setKids } from '../../store/reducers/search/searchDataSlice';

import Button from '../../UI/Button';
import Counter from '../../UI/Counter/Index';

import bemClassName from '../../utils/bem';

import './index.scss';

const guests = bemClassName('guests');

const Guests = () => {
  const guestsData = useSelector((state: RootState) => state.searchData.guests);
  const dispatch = useDispatch();

  const handleClickSetButton = () => {
    dispatch(closeSearchModal());
  };

  const handleSetAdults = (value: number) => dispatch(setAdults(value));
  const handleSetKids = (value: number) => dispatch(setKids(value));

  return (
    <div className={guests()}>
      <Counter
        value={guestsData !== null ? guestsData[0] : 0}
        handleExternal={handleSetAdults}
        maxValue={10}
        text="Взрослые"
      />
      <Counter
        value={guestsData !== null ? guestsData[1] : 0}
        handleExternal={handleSetKids}
        maxValue={10}
        text="Дети"
        note="до 3 лет"
      />
      <Button
        text="Применить"
        handleExternal={handleClickSetButton}
        externalClassName={guests('set-button')}
      />
    </div>
  );
};

export default Guests;
