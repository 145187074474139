import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../store';
import { toggleMenuModal } from '../../store/reducers/modal/modalMenuSlice';
import {
  logout,
  setAccessToken,
  setExpires,
} from '../../store/reducers/profile/autorizationStatutsSlice';

import Icon from '../../UI/Icon';

import bemClassName from '../../utils/bem';

import './index.scss';
import getAuthUrl from '../../utils/getAuthUrl';
import PrivateData from '../../API/PrivateData';
import { CHANGE_PASSWORD } from '../../app_constants';

const modalMenu = bemClassName('modal-menu');

const ModalMenu = () => {
  const isLogin = useSelector((state: RootState) => state.autorizationStatus.isLogin);
  const contacntHelperPhones = useSelector(
    (state: RootState) => state.contactData.contacntHelperPhones,
  );
  const isPaswordVerified = useSelector(
    (state: RootState) => state.autorizationStatus.isPaswordVerified,
  );
  const isOpen = useSelector((state: RootState) => state.modalMenu.isOpen);

  const redirectToLogin = (event: React.BaseSyntheticEvent<MouseEvent>) => {
    event.preventDefault();
    const url = getAuthUrl();
    if (url && url !== undefined) {
      window.location.href = url;
    }
  };

  const dispatch = useDispatch();

  const handlerLogout = async () => {
    await PrivateData.getPrivateLoggingOut();
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('tokenExpirationTime');
    dispatch(setAccessToken(''));
    dispatch(setExpires(0));
    dispatch(logout());
  };
  return (
    <div className={modalMenu({ active: isOpen })}>
      <h2 className={modalMenu('title')}>
        Меню
        <Icon
          externalClassName={modalMenu('close')}
          iconName="close"
          handleClick={() => dispatch(toggleMenuModal())}
        />
      </h2>
      <div className={modalMenu('list')}>
        <Link className={modalMenu('item')} to="/account#favourites">
          <Icon externalClassName={modalMenu('icon')} iconName="like" />
          избранное
          <Icon externalClassName={modalMenu('arrow')} iconName="arrow" />
        </Link>
        <hr className={modalMenu('line')} />
        <Link className={modalMenu('item')} to="/account#current-bookings">
          <Icon externalClassName={modalMenu('icon')} iconName="booking" />
          бронирования
          <Icon externalClassName={modalMenu('arrow')} iconName="arrow" />
        </Link>
        <hr className={modalMenu('line')} />
        <div className={modalMenu('item')}>
          <Icon externalClassName={modalMenu('icon')} iconName="help" />
          помощь
          <div className={modalMenu('submenu')}>
            <Link className={modalMenu('submenu-link')} to="#t">
              Часто задаваемые вопросы
              <Icon externalClassName={modalMenu('arrow')} iconName="arrow" />
            </Link>
            {contacntHelperPhones &&
              contacntHelperPhones.data &&
              contacntHelperPhones.data.slice(0, 1).map((items, id) => {
                return (
                  <Link
                    key={id}
                    to={`tel:${items.phone.E164}`}
                    className={modalMenu('submenu-link')}>
                    {items.phone.international}
                    <Icon externalClassName={modalMenu('arrow')} iconName="arrow" />
                  </Link>
                );
              })}
          </div>
        </div>
        <hr className={modalMenu('line')} />
        {isLogin !== null && isLogin ? (
          <div className={modalMenu('item')}>
            <Icon externalClassName={modalMenu('icon')} stroke iconName="profile" />
            кабинет
            <div className={modalMenu('submenu')}>
              <Link className={modalMenu('submenu-link')} to="/account">
                Редактировать профиль
                <Icon externalClassName={modalMenu('arrow')} iconName="arrow" />
              </Link>
              {CHANGE_PASSWORD && (
                <Link className={modalMenu('submenu-link')} to={CHANGE_PASSWORD}>
                  Изменение пароля
                  <Icon externalClassName={modalMenu('arrow')} iconName="arrow" />
                </Link>
              )}
              <Link className={modalMenu('submenu-link')} to="/account#messages">
                Сообщения
                <Icon externalClassName={modalMenu('arrow')} iconName="arrow" />
              </Link>
              <p className={modalMenu('submenu-text')}>
                Статус: {isPaswordVerified ? <>верифицирован</> : <>не верифицирован</>}
              </p>
              <Link className={modalMenu('submenu-link')} to="/account#proof-of-identity">
                Подтвердить личность
                <Icon externalClassName={modalMenu('arrow')} iconName="arrow" />
              </Link>
              <p onClick={() => handlerLogout()} className={modalMenu('submenu-link')}>
                Выход
                <Icon externalClassName={modalMenu('arrow')} iconName="arrow" />
              </p>
            </div>
          </div>
        ) : (
          <Link
            onClick={(event) => redirectToLogin(event)}
            className={modalMenu('item')}
            to="/login">
            <Icon externalClassName={modalMenu('icon')} stroke iconName="profile" />
            войти
            <Icon externalClassName={modalMenu('arrow')} iconName="arrow" />
          </Link>
        )}
      </div>
    </div>
  );
};

export default ModalMenu;
