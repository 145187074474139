import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import ReviewData from '../../../API/ReviewData';
import { IReviewItem } from '../../../components/ReviewItem/interface';

export const fetchReviewData = createAsyncThunk('data/fetchReviewData', ReviewData.getAll);

export interface DataState {
  data: Array<IReviewItem>;
}

const initialState: DataState = {
  data: [],
};

export const reviewDataSlice = createSlice({
  name: 'reviewData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchReviewData.fulfilled,
      (state, action: PayloadAction<Array<IReviewItem>>) => {
        state.data = action.payload;
      },
    );
  },
});

// export const {} = reviewDataSlice.actions;

export default reviewDataSlice.reducer;
