import axiosInterseptor from '../../utils/axiosInterseptor';

export default class ReviewData {
  static async getAll() {
    const response = await fetch(`/reviewData.json`);
    const data = await response.json();
    return data;
  }

  static async getCollectionReviews() {
    try {
      const response = await axiosInterseptor.get(`products/reviews/scored`);
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getCollectionReviewsDateFilter(filter: string, page: number, signal: AbortSignal) {
    try {
      const response = await axiosInterseptor.get(
        `products/reviews/${filter}?mode=search&page=${page}`,
        {
          signal,
        },
      );
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }
}
