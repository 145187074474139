import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Grid, Autoplay } from 'swiper';
import ReviewItem from '../ReviewItem';

import bemClassName from '../../utils/bem';

import 'swiper/scss';
import 'swiper/scss/grid';
import 'swiper/scss/pagination';
import 'swiper/scss/autoplay';
import './index.scss';
import formatedDateCommit from '../../utils/reviewsUtils';

const review = bemClassName('review');

interface IReview {
  data: any[];
}

const Review: React.FC<IReview> = ({ data }) => {
  return (
    data && (
      <div className={review()}>
        <h2 className={review('title')}>Что о нас думают?</h2>
        {data && (
          <Swiper
            className={review('carousel')}
            spaceBetween={30}
            slidesPerView={1}
            modules={[Autoplay, Pagination, Grid]}
            pagination={{ clickable: true }}
            loop
            updateOnWindowResize
            observer
            observeParents
            observeSlideChildren
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
              stopOnLastSlide: false,
              pauseOnMouseEnter: true,
              // waitForTransition: false,
            }}
            breakpoints={{
              320: {
                autoplay: false,
              },
              448: {
                slidesPerView: 'auto',
                autoplay: false,
              },
              1025: {
                slidesPerView: 'auto',
                pagination: false,
                speed: 10000,
              },
            }}>
            {data &&
              data.map((item: any, id: number) => {
                return (
                  <SwiperSlide key={id}>
                    <ReviewItem
                      locationLink={`/catalog/${item.city}/${item.product_id}`}
                      photos={item.photos ? item.photos : ''}
                      photos_url={item.photos_url}
                      city={item.city}
                      address={item.title}
                      raiting={item.score}
                      link="/reviews/"
                      name={item.user_name}
                      date={formatedDateCommit(item.created_at)}
                      text={item.text}
                    />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        )}
      </div>
    )
  );
};

export default Review;
