import React, { useEffect, useRef } from 'react';
import 'ol/ol.css';
import { Feature, Map, Overlay, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import { Cluster, OSM, Vector as VectorSource } from 'ol/source';
import './index.scss';
import { fromLonLat } from 'ol/proj';

import VectorLayer from 'ol/layer/Vector';
import { Point } from 'ol/geom';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import { Circle as CircleStyle, Text } from 'ol/style';
import { createEmpty, extend, getHeight, getWidth } from 'ol/extent';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../UI/Icon';
import bemClassName from '../../utils/bem';
import { ICatalogItemData } from '../CatalogItem/interface';
import { IDetailCatalog } from '../../pages/CatalogDetail/interface';
import {
  getButtonInOutIcon,
  getGroupedData,
  getMarker,
  getMarkerDoubler,
  getMarkerRoom,
} from '../../utils/mapUtils';
import { RootState } from '../../store';
import { setIsModalMap } from '../../store/reducers/modal/modalMapSlice';

const map = bemClassName('map');

interface IMapComponentProps {
  data?: Array<ICatalogItemData>;
  dataRoom?: IDetailCatalog;
  externalClassName?: string;
  CenterCoordsValue?: Array<number>;
  mobileCatalog?: boolean;
  zoomMap?: number;
}

const MapComponent: React.FC<IMapComponentProps> = ({
  data,
  dataRoom,
  externalClassName = '',
  CenterCoordsValue,
  mobileCatalog,
  zoomMap,
}) => {
  // const [isModal, setIsModal] = useState(false);
  const mapRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const citySelected = useSelector((state: RootState) => state.searchData.citySelected);
  const isModal = useSelector((state: RootState) => state.mapModal.isModalMap);

  useEffect(() => {
    if (!mapRef.current) return undefined;
    if (CenterCoordsValue) {
      const mapElement = new Map({
        target: mapRef.current,
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
        ],
        view: new View({
          center: fromLonLat(CenterCoordsValue),
          zoom: zoomMap,
        }),
      });

      const markerSource = new VectorSource();

      const clusterMemberStyle = (clusterMember: any) => {
        clusterMember.values_.element.removeAttribute('style');
        return new Style({
          geometry: clusterMember.getGeometry(),
          image: new CircleStyle({
            radius: 0,
            stroke: new Stroke({
              color: '#002D72',
              width: 3,
            }),
            fill: new Fill({
              color: '#FFFFFF',
            }),
          }),
        });
      };

      const clusterStyle = (feature: any) => {
        const size = feature.get('features').length;
        const currentZoom = mapElement.getView().getZoom();

        const textFill = new Fill({
          color: '#002D72',
        });

        if (size > 1 && currentZoom && currentZoom < 17) {
          feature.get('features').forEach((featureEl: any) => {
            if (featureEl.values_.element.getAttribute('style') === null) {
              featureEl.values_.element.setAttribute('style', 'display: none');
            }
          });
          return new Style({
            image: new CircleStyle({
              radius: size < 100 ? 14 : 17,
              stroke: new Stroke({
                color: '#002D72',
                width: 3,
              }),
              fill: new Fill({
                color: '#FFFFFF',
              }),
            }),
            text: new Text({
              text: size.toString(),
              fill: textFill,
              font: 'Roboto',
              scale: 1.3,
            }),
          });
        }

        if (size > 1 && currentZoom && currentZoom >= 17) {
          feature.get('features').forEach((featureEl: any) => {
            if (featureEl.values_.element.getAttribute('style') !== null) {
              featureEl.values_.element.removeAttribute('style');
            }
          });
        }

        const originalFeature = feature.get('features')[0];
        return clusterMemberStyle(originalFeature);
      };

      if (data && data.length > 0) {
        getGroupedData(data).forEach((item) => {
          if (Array.isArray(item)) {
            if (
              citySelected &&
              item[0] &&
              item[0].coords &&
              item[0].coords.lon &&
              item[0].coords.lat
            ) {
              const elem = getMarkerDoubler(item, citySelected.name, mobileCatalog);

              item.forEach((items) => {
                if (citySelected && items && items.coords && items.coords.lon && items.coords.lat) {
                  const lonLat = fromLonLat([items.coords.lon, items.coords.lat]);

                  const markerFeature = new Feature({
                    element: elem,
                    item,
                    geometry: new Point(lonLat),
                  });

                  markerSource.addFeature(markerFeature);
                }
              });
              const marker = new Overlay({
                /* eslint no-underscore-dangle: 0 */
                element: elem,
                /* eslint no-underscore-dangle: 0 */
                position: fromLonLat([item[0].coords.lon, item[0].coords.lat]),
                positioning: 'bottom-center',
              });
              mapElement.addOverlay(marker);
            }
          } else if (item.coords && item.coords.lat && item.coords.lon && citySelected) {
            const elem = getMarker(item, citySelected.name, mobileCatalog);
            const markerFeature = new Feature({
              element: elem,
              item,
              geometry: new Point(fromLonLat([item.coords.lon, item.coords.lat])),
            });

            markerSource.addFeature(markerFeature);
            const marker = new Overlay({
              /* eslint no-underscore-dangle: 0 */
              element: elem,
              /* eslint no-underscore-dangle: 0 */
              position: fromLonLat([item.coords.lon, item.coords.lat]),
              positioning: 'bottom-center',
            });

            mapElement.addOverlay(marker);
          }
        });
      }
      if (dataRoom) {
        if (
          dataRoom &&
          dataRoom.address &&
          dataRoom.address.coords &&
          dataRoom.address.coords.lon &&
          dataRoom.address.coords.lat
        ) {
          const elem = getMarkerRoom(dataRoom);
          const markerFeature = new Feature({
            element: elem,
            item: dataRoom,
            geometry: new Point(
              fromLonLat([dataRoom.address.coords.lon, dataRoom.address.coords.lat]),
            ),
          });

          markerSource.addFeature(markerFeature);
          const marker = new Overlay({
            /* eslint no-underscore-dangle: 0 */
            element: elem,
            /* eslint no-underscore-dangle: 0 */
            position: fromLonLat([dataRoom.address.coords.lon, dataRoom.address.coords.lat]),
            positioning: 'bottom-center',
          });
          mapElement.addOverlay(marker);
        }
      }

      const clusterSource = new Cluster({
        distance: 60,
        source: markerSource,
      });

      const clusterLayer = new VectorLayer({
        source: clusterSource,
        style: clusterStyle,
      });

      mapElement.addLayer(clusterLayer);

      mapElement.on('pointermove', (event: any) => {
        const feature = mapElement.getFeaturesAtPixel(event.pixel)[0];
        mapElement.getTargetElement().style.cursor = feature ? 'pointer' : '';
      });

      mapElement.on('click', (event: any) => {
        clusterLayer.getFeatures(event.pixel).then((features: any) => {
          if (features.length > 0) {
            const clusterMembers = features[0].get('features');
            if (clusterMembers.length >= 1) {
              const averageCoordinates = clusterMembers
                .reduce(
                  (sum: number[], feature: any) => {
                    const geometry = feature.getGeometry();
                    const coordinates = geometry.getCoordinates();
                    return [sum[0] + coordinates[0], sum[1] + coordinates[1]];
                  },
                  [0, 0],
                )
                .map((coord: any) => coord / clusterMembers.length);

              const extent = createEmpty();
              clusterMembers.forEach((feature: any) =>
                extend(extent, feature.getGeometry().getExtent()),
              );

              const view = mapElement.getView();
              const resolution = mapElement.getView().getResolution();

              if (resolution) {
                if (
                  view.getZoom() === view.getMaxZoom() ||
                  (getWidth(extent) < resolution && getHeight(extent) < resolution)
                ) {
                  clusterLayer.setStyle(clusterStyle);
                }
                const curentZoom = view.getZoom();
                if (curentZoom !== undefined) {
                  const zoomScale = () => {
                    let scaleCount = 0;
                    if (curentZoom < 17) {
                      if (curentZoom < 14) {
                        scaleCount = curentZoom + 3;
                      } else {
                        scaleCount = curentZoom + (17 - curentZoom);
                      }
                    } else {
                      scaleCount = curentZoom + 1;
                    }
                    return scaleCount;
                  };
                  view.animate({
                    center: averageCoordinates,
                    duration: 500,
                    zoom: zoomScale(),
                  });
                }
              }
            }
          }
        });
      });

      getButtonInOutIcon();

      return () => {
        mapElement.setTarget();
      };
    }
    return () => {
      // mapElement.setTarget();
    };
  }, [data, isModal]);

  return mobileCatalog ? (
    <div id="map" className={`${map('', { modal: isModal })} ${externalClassName}`}>
      <div ref={mapRef} className={`${map('element')}`} />
      {isModal === false ? (
        <button
          onClick={() => dispatch(setIsModalMap())}
          className={map('mobile-open-full-map')}
          type="button">
          <Icon iconName="map_marker" externalClassName={map('icon-mobile-map-marker')} />
          <p className={map('mobile-map-text')}>Показать на карте</p>
        </button>
      ) : (
        <button
          onClick={() => dispatch(setIsModalMap())}
          type="button"
          className={`${map('button-full-screen')}`}>
          <Icon iconName="arrow_map_closed" externalClassName={map('icon-arrow')} />
        </button>
      )}
    </div>
  ) : (
    <div className={`${map('', { modal: isModal })} ${externalClassName}`}>
      <div ref={mapRef} className={`${map('element')}`} />
      <button
        onClick={() => dispatch(setIsModalMap())}
        type="button"
        className={`${map('button-full-screen')}`}>
        {isModal === false ? (
          <Icon iconName="arrow_open" externalClassName={map('icon-arrow')} />
        ) : (
          <Icon iconName="arrow_closed" externalClassName={map('icon-arrow')} />
        )}
      </button>
    </div>
  );
};

export default MapComponent;
