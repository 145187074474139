import React, { ReactNode, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import bemClassName from '../../utils/bem';

// import image from '../../assets/images/dogovor.jpg';

import './index.scss';
import PrivateData from '../../API/PrivateData';
import Loading from '../../UI/Loading';

const serviceAgreement = bemClassName('service-agreement');

const ServiceAgreement = () => {
  const [oferta, setOferta] = useState<string>();
  const [userDateValid, setUserDateValid] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  let parsedHTML;
  if (oferta) {
    parsedHTML = parse(oferta);
  }

  useEffect(() => {
    setIsLoading(true);
    if (userDateValid) {
      PrivateData.getPrivateUserOferta().then((item) => {
        setIsLoading(false);
        setOferta(item);
      });
    } else if (userDateValid !== undefined && !userDateValid) {
      setIsLoading(false);
    }
  }, [userDateValid]);

  useEffect(() => {
    document.title = 'Личные данные';

    PrivateData.getPrivateUser().then((item) => {
      if (item) {
        if (item.name !== null && item.surname !== null) {
          setUserDateValid(true);
        }
      }
    });
  }, []);

  return (
    <div className={serviceAgreement()}>
      {/* eslint-disable-next-line */}
      {!isLoading ? (
        userDateValid ? (
          (parsedHTML as ReactNode)
        ) : (
          <span className={serviceAgreement('tooltip')}>
            Пожалуйста заполните <Link to="#t">личные данные</Link>
          </span>
        )
      ) : (
        <Loading externalClassName={serviceAgreement('loading')} />
      )}
    </div>
  );
};

export default ServiceAgreement;
