import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface DataState {
  isOpen: boolean;
  typeOpenSearchModal: string | null;
}

const initialState: DataState = {
  isOpen: false,
  typeOpenSearchModal: null,
};

export const modalSearchSlice = createSlice({
  name: 'modalSearch',
  initialState,
  reducers: {
    closeSearchModal: (state) => {
      if (document.body.style.overflow === 'hidden' && state.typeOpenSearchModal !== null) {
        document.body.removeAttribute('style');
      }
      state.typeOpenSearchModal = null;
    },
    setTypeOpenSearchModal: (state, action: PayloadAction<string>) => {
      state.typeOpenSearchModal = action.payload;
      if (window.innerWidth < 1025 && state.typeOpenSearchModal !== null) {
        document.body.style.overflow = 'hidden';
      }
      // else if (document.body.style.overflow === 'hidden' && state.typeOpenSearchModal === null) {
      //   document.body.style.overflow = 'visible';
      // }
    },
  },
});

export const { closeSearchModal, setTypeOpenSearchModal } = modalSearchSlice.actions;

export default modalSearchSlice.reducer;
