import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import CatalogList from '../../components/CatalogList';
import Pagination from '../../UI/Pagination';

import bemClassName from '../../utils/bem';

import './index.scss';
import PrivateData from '../../API/PrivateData';
import { ICatalogItemData } from '../../components/CatalogItem/interface';
import ScrollingButtons from '../../UI/ScrollingButtons';
import AppData from '../../API/AppData';
import { RootState } from '../../store';
import CatalogData from '../../API/CatalodData';
import Loading from '../../UI/Loading';

const favourites = bemClassName('favourites');

const Favourites = () => {
  const [data, setData] = useState<Array<ICatalogItemData>>();
  const [citySelectedFavorite, setCitySelectedFavorite] = useState<string>();
  const [pageFavorite, setPageFavorite] = useState<number>(1);
  const [cityId, setCityId] = useState<any>();
  const [cityName, setCityName] = useState<string>();

  const [metaData, setMetaData] = useState<any>();
  const [cityData, setCityData] = useState<any>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const favoritesLocal = localStorage.getItem('favourites');

  const isLogin = useSelector((state: RootState) => state.autorizationStatus.isLogin);
  const favourite = useSelector((state: RootState) => state.favoritesData.favorites);

  const controllerFavorite = new AbortController();

  useEffect(() => {
    if (cityData) {
      const cityItem = cityData.find((item: any) => item.name === citySelectedFavorite);
      if (cityItem) {
        setCityId(cityItem.id);
        setCityName(cityItem.name);
      }
    }
    if (citySelectedFavorite !== cityData) {
      setPageFavorite(1);
    }
  }, [cityData, citySelectedFavorite]);

  useEffect(() => {
    setIsLoading(true);
    if (isLogin !== null && isLogin) {
      if (citySelectedFavorite === 'Все' && favourite) {
        PrivateData.getPrivateCollectionFavorite(
          favourite,
          pageFavorite,
          controllerFavorite.signal,
        ).then((item) => {
          if (item.data && item.meta) {
            setData(item.data);
            setMetaData(item.meta);
            setIsLoading(false);
          } else {
            setIsLoading(true);
          }
        });
      } else if (favourite && cityName) {
        if (cityId && citySelectedFavorite === cityName) {
          PrivateData.getPrivateCollectionFavorite(
            favourite,
            pageFavorite,
            controllerFavorite.signal,
            cityId,
          ).then((item) => {
            if (item.data && item.meta) {
              setData(item.data);
              setMetaData(item.meta);
              setIsLoading(false);
            } else {
              setIsLoading(true);
            }
          });
        }
      }
    } else if (isLogin !== null && !isLogin) {
      if (favourite && pageFavorite) {
        if (cityId && citySelectedFavorite === cityName) {
          CatalogData.getPublicFavorite(
            favourite,
            pageFavorite,
            controllerFavorite.signal,
            cityId,
          ).then((item) => {
            if (item.data && item.meta) {
              setData(item.data);
              setMetaData(item.meta);
              setIsLoading(false);
            } else {
              setIsLoading(true);
            }
          });
        } else if (isLogin !== null && !isLogin) {
          CatalogData.getPublicFavorite(favourite, pageFavorite, controllerFavorite.signal).then(
            (item) => {
              if (item.data && item.meta) {
                setData(item.data);
                setMetaData(item.meta);
                setIsLoading(false);
              } else {
                setIsLoading(true);
              }
            },
          );
        }
      }
    }
    return () => {
      controllerFavorite.abort();
      setIsLoading(true);
    };
  }, [isLogin, pageFavorite, citySelectedFavorite, favoritesLocal, cityId, cityName]);

  useEffect(() => {
    document.title = 'Избранное';
    AppData.getCityData().then((item) => setCityData(item));
  }, []);

  return (
    <>
      <ScrollingButtons
        externalClassName={favourites('scrolling-button')}
        data={[cityData, 'Все']}
        defaultSelected="Все"
        handleExternal={(value: string) => setCitySelectedFavorite(value)}
      />
      {!isLoading ? (
        <>
          {data && data.length > 0 ? (
            <CatalogList data={data} fav />
          ) : (
            <div className={favourites('not-found')}>
              <h2>Извините, ничего не найдено</h2>
              <p className={favourites('not-found-text')}>
                Добавьте пожалуйста квартиры в избранное.
              </p>
            </div>
          )}
          {metaData && metaData.pages > 1 && (
            <Pagination
              totalPageCount={metaData.pages}
              currentPage={pageFavorite}
              externalClassName={favourites('pagination')}
              handleExternal={(value: number) => setPageFavorite(value)}
            />
          )}
        </>
      ) : (
        <Loading externalClassName={favourites('loading')} />
      )}
    </>
  );
};

export default Favourites;
