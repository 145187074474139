import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import AppInput from '../../UI/AppInput';
import RadioButtons from '../../UI/RadiouButtons';
import Checkbox from '../../UI/Checkbox';
import Button from '../../UI/Button';

import { CALENDAR, EMAIL, TEL, TEXT } from '../../app_constants';
import bemClassName from '../../utils/bem';

import './index.scss';
import { fetchCheckFioValidate } from '../../store/reducers/profile/personalDataSlice';
import { RootState } from '../../store';
import PrivateData from '../../API/PrivateData';
import Validate from '../../API/Validate';
import { formatedDateDayBirdh } from '../../utils/date';

const personalData = bemClassName('personal-data');

const PersonalData = () => {
  const [dataList, setDataList] = useState<Array<string>>([]);
  const [profileData, setProfailData] = useState({});

  const [surname, setSurname] = useState<string>();
  const [name, setName] = useState<string>();
  const [middlename, setMiddlename] = useState<string>();

  const [company, setCompanyValue] = useState<string>();

  const [email, setEmail] = useState<string>();
  // const [emailDomainArray, setEmailDomainArray] = useState<Array<string>>();

  const [genderValue, setGenderValue] = useState<string>();

  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [additionalPhoneNumber, setAdditionalPhoneNumber] = useState<string>();
  const [phoneIsValid, setPhoneIsValid] = useState<boolean>();
  const [phoneAdditionalIsValid, setPhoneAdditionalIsValid] = useState<boolean>();
  // const [phoneErrorText, setPhoneErrorText] = useState<string>('');

  const [bonusCard, setBonusCard] = useState<string>();
  const [dateOfBirth, setDateOfBirth] = useState<string>();
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [checkbox, setCheckBox] = useState<boolean>();
  const [additionalPhone, setAdditionalPhone] = useState<boolean>(false);
  const [errorBoolean, setErrorBoolean] = useState<boolean>(false);

  const fioHelperArray = useSelector((state: RootState) => state.personalData.fioHelperArray);
  const isLogin = useSelector((state: RootState) => state.autorizationStatus.isLogin);

  const dispatchAsync = useDispatch<ThunkDispatch<any, any, any>>();

  const handleSaveUserData = () => {
    if (profileData) {
      setDisabledButton(false);
      PrivateData.getPrivateEditUserDate(profileData).then(() => setDisabledButton(true));
    }
  };

  const handleSetSurname = (value: string) => {
    setSurname(value);
  };

  const handleSetName = (value: string) => {
    setName(value);
  };

  const handleSetMiddlename = (value: string) => {
    setMiddlename(value);
  };

  const handleSetPhoneNumber = (value: string) => {
    setPhoneNumber(value);
  };

  const handleSetAdditionalPhoneNumber = (value: string) => {
    setAdditionalPhoneNumber(value);
  };

  const handleSetEmail = (value: string) => {
    setEmail(value);
  };

  const handleSetGenderValue = (value: string) => {
    setGenderValue(value);
  };

  const handleSetCompanyValue = (value: string) => {
    setCompanyValue(value);
  };

  const handleSetBonus = (value: string) => {
    setBonusCard(value);
  };

  const handleSetBirthDay = (value: string) => {
    if (value) {
      if (value.length > 10) {
        setDateOfBirth(formatedDateDayBirdh(value));
      } else {
        const day = value.slice(0, 2);
        const month = value.slice(3, 5);
        const year = value.slice(6, 10);
        setDateOfBirth(formatedDateDayBirdh(`${month}.${day}.${year}`));
      }
    }
  };

  const handleSetCheckBox = () => {
    if (!checkbox) {
      setCheckBox(true);
    } else {
      setCheckBox(false);
    }
  };

  const handleAddetAdditionalTel = () => {
    if (!additionalPhone) {
      setAdditionalPhone(true);
    } else {
      setAdditionalPhone(false);
      setAdditionalPhoneNumber('');
    }
  };

  useEffect(() => {
    if (
      additionalPhoneNumber &&
      additionalPhoneNumber !== undefined &&
      additionalPhoneNumber !== null
    ) {
      setAdditionalPhone(true);
    }
  }, [additionalPhoneNumber]);

  useEffect(() => {
    const abortControllerPhone = new AbortController();
    if (phoneNumber) {
      Validate.checkPhone(phoneNumber, abortControllerPhone.signal)
        .then((item) => {
          setPhoneIsValid(item.valid);
          if (item.valid === true) {
            setPhoneNumber(item.international);
          }
        })
        .catch((error) => {
          return error;
        });
    }

    return () => {
      abortControllerPhone.abort();
    };
  }, [phoneNumber, phoneIsValid]);

  useEffect(() => {
    const abortControllerPhoneAdditional = new AbortController();
    if (additionalPhoneNumber) {
      Validate.checkPhone(additionalPhoneNumber, abortControllerPhoneAdditional.signal)
        .then((item) => {
          setPhoneAdditionalIsValid(item.valid);
          if (item.valid === true) {
            setAdditionalPhoneNumber(item.international);
          }
        })
        .catch((error) => {
          return error;
        });
    }

    return () => {
      abortControllerPhoneAdditional.abort();
    };
  }, [additionalPhoneNumber, phoneAdditionalIsValid]);

  useEffect(() => {
    if (surname || name || middlename) {
      const fullName = [surname, name, middlename];
      const fullNameAsString = String(fullName.join(' '));

      Validate.helperName({ name: fullNameAsString, type: 'name' });
      dispatchAsync(fetchCheckFioValidate(fullNameAsString));
    }
  }, [surname, name, middlename]);

  useEffect(() => {
    if (fioHelperArray && Array.isArray(fioHelperArray) && fioHelperArray.length !== 0) {
      setDataList(fioHelperArray);
    }
  }, [fioHelperArray]);

  useEffect(() => {
    if (
      name ||
      surname ||
      middlename ||
      phoneNumber ||
      email ||
      company ||
      genderValue ||
      dateOfBirth
    ) {
      setProfailData({
        name: name ?? undefined,
        surname: surname ?? undefined,
        middlename: middlename ?? undefined,
        phone: phoneNumber ?? undefined,
        phone1: additionalPhoneNumber ?? undefined,
        email: email ?? undefined,
        company: company ?? undefined,
        card: bonusCard ?? undefined,
        gender: genderValue ?? undefined,
        birthday: dateOfBirth ?? undefined,
      });
    }

    if (surname && name && phoneNumber && phoneNumber.length > 15 && dateOfBirth && checkbox) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }

    setErrorBoolean(Boolean(name || surname || phoneNumber || dateOfBirth));
  }, [
    name,
    surname,
    middlename,
    phoneNumber,
    email,
    company,
    checkbox,
    genderValue,
    bonusCard,
    dateOfBirth,
    additionalPhoneNumber,
  ]);

  useEffect(() => {
    if (isLogin !== null && isLogin) {
      PrivateData.getPrivateUser().then((item) => {
        setName(item.name);
        setGenderValue(item.gender);
        setMiddlename(item.middlename);
        setSurname(item.surname);
        setEmail(item.email);
        setPhoneNumber(item.phone && item.phone.international);
        setAdditionalPhoneNumber(item.phone1 && item.phone1.international);
        setCompanyValue(item.company);
        if (item.birthday) {
          const day = item.birthday.slice(8, 10);
          const month = item.birthday.slice(5, 7);
          const year = item.birthday.slice(0, 5);
          setDateOfBirth(`${month}.${day}.${year}`);
        }
        setBonusCard(item.card);
      });
    }
  }, [isLogin]);

  useEffect(() => {
    document.title = 'Личные данные';
  }, []);

  return (
    <>
      <AppInput
        inputId="surname"
        text="Фамилия"
        type={TEXT}
        externalClassName={personalData('field')}
        required
        handleExternal={handleSetSurname}
        validate="Необходимо указать Фамилию, Имя и Отчество"
        dataListExternal={dataList}
        valueExternal={surname}
        valueBoolean={errorBoolean}
      />
      <AppInput
        inputId="name"
        text="Имя"
        type={TEXT}
        externalClassName={personalData('field')}
        required
        handleExternal={handleSetName}
        validate="Необходимо указать Фамилию, Имя и Отчество"
        dataListExternal={dataList}
        valueExternal={name}
        valueBoolean={errorBoolean}
      />
      <AppInput
        inputId="middlename"
        text="Отчество"
        type={TEXT}
        externalClassName={personalData('field')}
        handleExternal={handleSetMiddlename}
        valueExternal={middlename}
      />
      <AppInput
        inputId="company"
        text="Компания (не обязательно)"
        type={TEXT}
        externalClassName={personalData('field')}
        handleExternal={handleSetCompanyValue}
        placeholder="Введите название компании"
        valueExternal={company}
      />
      <RadioButtons
        data={[
          { text: 'Мужской', value: 'men' },
          { text: 'Женский', value: 'women' },
        ]}
        name="Gender"
        title="Пол"
        externalClassName={personalData('field')}
        handleExternal={handleSetGenderValue}
        valueExternal={genderValue}
      />
      <AppInput
        inputId="dateOfBirth"
        text="Дата рождения"
        type={CALENDAR}
        required
        externalClassName={personalData('field')}
        valueExternal={dateOfBirth ?? undefined}
        handleExternal={handleSetBirthDay}
        valueBoolean={errorBoolean}
      />
      <AppInput
        inputId="phoneNumber"
        text="Телефон"
        type={TEL}
        required
        externalClassName={personalData('field')}
        validate="Номер телефона не существует"
        isValidExternal={phoneIsValid}
        handleExternal={handleSetPhoneNumber}
        valueExternal={phoneNumber}
        valueBoolean={errorBoolean}
        additionalTelNumber={additionalPhone}
        handleAddetPhone={handleAddetAdditionalTel}
        personal
      />
      {additionalPhone && (
        <AppInput
          inputId="phoneNumberAdditional"
          text="Дополнительный-телефон"
          type={TEL}
          externalClassName={personalData('field')}
          validate="Номер телефона не существует"
          isValidExternal={phoneAdditionalIsValid}
          handleExternal={handleSetAdditionalPhoneNumber}
          valueExternal={additionalPhoneNumber}
          valueBoolean={errorBoolean}
          handleAddetPhone={handleAddetAdditionalTel}
          additionalInputPhoneNumber
        />
      )}
      <AppInput
        inputId="email"
        text="Электронная почта"
        type={EMAIL}
        externalClassName={personalData('field')}
        validate="E-mail адрес не существует"
        handleExternal={handleSetEmail}
        valueExternal={email ?? undefined}
      />
      <AppInput
        inputId="cashbackCard"
        text="Номер карты для получения кэшбека"
        type={TEXT}
        externalClassName={personalData('field')}
        handleExternal={handleSetBonus}
        valueExternal={bonusCard}
      />
      <Checkbox
        text="Я подтверждаю, что ознакомился"
        linkText="с условиями публичной оферты"
        externalClassName={personalData('checkbox')}
        handleExternal={handleSetCheckBox}
      />
      <Button
        handleExternal={() => handleSaveUserData()}
        text="Сохранить"
        disable={!disabledButton}
        externalClassName={personalData('button')}
      />
    </>
  );
};

export default PersonalData;
