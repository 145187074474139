import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { BAR, LINE } from '../../app_constants';
import { IAppChart } from './interface';
import bemClassName from '../../utils/bem';

import './index.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const appChart = bemClassName('app-chart');

const AppChart: React.FC<IAppChart> = ({ labels, title, dataset, type }) => {
  const datasets = dataset.map((item) => {
    return {
      label: item.label,
      data: item.data,
      borderWidth: 3,
      borderColor: item.chartColorType,
      backgroundColor: item.chartColorType,
      yAxisID: 'y',
    };
  });

  const options = {
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        labels: {
          font: { family: 'Roboto', size: 12, weight: '400' },
        },
      },
      title: {
        display: true,
        text: title,
        position: 'bottom' as const,
        font: { family: 'Rawline', weight: '600', size: 18 },
      },
    },
    scales: {
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
      },
    },
  };

  const data = {
    labels,
    datasets,
  };

  return (
    <div className={appChart()}>
      {type === LINE && <Line options={options} data={data} className={appChart('item')} />}
      {type === BAR && <Bar options={options} data={data} className={appChart('item')} />}
    </div>
  );
};

export default AppChart;
