import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import Validate from '../../../API/Validate';

// export const fetchCheckPhoneValidate = createAsyncThunk(
//   'data/fetchCheckPhoneValidate',
//   Validate.checkPhone,
// );
export const fetchCheckPhoneValidate = createAsyncThunk(
  'data/fetchCheckPhoneValidate',
  (phoneNumber: string, { signal }) => {
    try {
      const result = Validate.checkPhone(phoneNumber, signal);
      return result;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
);

export interface DataState {
  phoneIsValid: boolean | null;
  international: string | null;
  phoneErrorText: string | null;
}

const initialState: DataState = {
  phoneIsValid: null,
  international: null,
  phoneErrorText: null,
};

export const phoneValidateSlice = createSlice({
  name: 'phoneValidate',
  initialState,
  reducers: {
    setDefaultValuePhoneValidate: (state) => {
      state.phoneIsValid = null;
      state.phoneErrorText = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCheckPhoneValidate.fulfilled, (state, action: PayloadAction<any>) => {
      if (typeof action.payload.valid === 'boolean') {
        state.phoneIsValid = action.payload.valid;
        state.international = action.payload.international;
      } else if (action.payload.valid === undefined) {
        state.phoneIsValid = false;
        state.phoneErrorText = action.payload;
      }
    });
  },
});

export const { setDefaultValuePhoneValidate } = phoneValidateSlice.actions;

export default phoneValidateSlice.reducer;
