import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

import Raiting from '../../UI/Raiting';
import Icon from '../../UI/Icon';
import Tooltip from '../../UI/Tooltip';
import AdvantagesSticker from '../../UI/AdvantagesSticker';
import Like from '../../UI/Like';
import Button from '../../UI/Button';

import { ICatalogItemProps } from './interface';
import bemClassName from '../../utils/bem';

import './index.scss';
import {
  declinationWord,
  disabledIcons,
  getIconsArray,
  getTypeCatalogItems,
} from '../../utils/catalogUtils';
import { formatedBookingDays } from '../../utils/calendarDayUtils';
import {
  bookingStatusPayment,
  bookingStatusTextNotVerified,
  bookingStatusTextVerified,
} from '../../utils/bookingStatusUtils';
import { RootState } from '../../store';
import PrivateData from '../../API/PrivateData';
import MessageData from '../../API/MessageData';
import Modal from '../../UI/Modal';
import RadioButtons from '../../UI/RadiouButtons';

const catalogItem = bemClassName('catalog-item');

const CatalogItem: React.FC<ICatalogItemProps> = ({ data, fav, current, currentData, loading }) => {
  const location = useLocation();

  const [advantages, setAdvantages] = useState<Array<string>>([]);

  const [dataProduct, setDataProduct] = useState<any>();

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [causeCancellation, setСauseCancellation] = useState<string>();
  const [disabledCancellation, setDisabledCancellation] = useState<boolean>(false);

  const isPaswordVerified = useSelector(
    (state: RootState) => state.autorizationStatus.isPaswordVerified,
  );

  const handleCauseCancellationValue = (value: string) => {
    if (value) {
      setDisabledCancellation(true);
    }
    setСauseCancellation(value);
  };

  const handleOpenModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOpenModal(true);
  };

  const handleCloseModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault();
    }
    setOpenModal(false);
  };

  const deletUserBookingDeals = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (dataProduct) {
      loading(true);
      PrivateData.deleteUserDeals(dataProduct.id, Number(causeCancellation)).then(() => {
        loading(false);
        setOpenModal(false);
      });
    }
  };

  const addingOrForwardingChat = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (dataProduct && dataProduct.product) {
      loading(true);
      MessageData.getCreatedСhat(dataProduct.product.id).then((item) => {
        loading(false);
        if (item && item.chat_id) {
          window.location.href = `/account#messages_${item.chat_id}`;
        }
      });
    }
  };

  useEffect(() => {
    if (data && currentData) {
      const result = currentData.find((item: any) => item.product.id === data.id);
      if (result) {
        setDataProduct(result);
      }
    }
  }, [currentData, data]);

  useEffect(() => {}, [dataProduct]);

  useEffect(() => {
    if (data) {
      const advant: Array<string> = [];
      if (data.bonus && data.bonus !== 0 && data.bonus !== undefined) {
        advant.push(`+${data.bonus} к бонусным баллам`);
      }
      if (data.contactless === true) {
        advant.push('Бесконтактное заселение');
      }
      setAdvantages(advant);
    }
  }, [data]);

  return data && data.city && data.condition && data.condition.type !== 1 ? (
    <>
      <Link
        to={`/catalog/${data.city.name}/${data.id}/`}
        className={catalogItem({
          fits: data.condition && data.condition.type === 4,
          partially_fits:
            data.condition && (data.condition.type === 3 || data.condition.type === 2),
          fits_basic: data.condition && data.condition.type === 0,
          fav,
          current,
        })}>
        <Swiper
          className={catalogItem('carousel')}
          spaceBetween={0}
          slidesPerView={1}
          modules={[Pagination]}
          pagination={{ clickable: true }}
          loop>
          {data.photos &&
            data.photos_url !== undefined &&
            data.photos.slice(0, 5).map((item, id) => {
              // if (id <= 4) {
              return (
                <SwiperSlide key={id}>
                  <picture className={catalogItem('picture')}>
                    <source
                      media="(max-width: 720px)"
                      srcSet={`${data.photos_url}/${item}-578x388.jpg, ${data.photos_url}/${item}-1156x776.jpg 2x, ${data.photos_url}/${item}-1734x1164.jpg 3x`}
                    />
                    <source
                      media="(max-width: 720px)"
                      type="image/webp"
                      srcSet={`${data.photos_url}/${item}-578x388.webp, ${data.photos_url}/${item}-1156x776.webp 2x, ${data.photos_url}/${item}-1734x1164.webp 3x`}
                    />
                    <source
                      media="(max-width: 720px)"
                      type="image/jpeg"
                      srcSet={`${data.photos_url}/${item}-578x388.jpg, ${data.photos_url}/${item}-1156x776.jpg 2x, ${data.photos_url}/${item}-1734x1164.jpg 3x`}
                    />
                    <source
                      type="image/webp"
                      srcSet={`${data.photos_url}/${item}-298x298.webp, ${data.photos_url}/${item}-596x596.webp 2x, ${data.photos_url}/${item}-894x894.webp 3x`}
                    />
                    <img
                      className={catalogItem('image')}
                      src={`${data.photos_url}/${item}-298x298.jpg`}
                      srcSet={`${data.photos_url}/${item}-596x596.jpg 2x, ${data.photos_url}/${item}-894x894.jpg 3x`}
                      alt=""
                    />
                  </picture>
                  {data.photos && data.photos.length > 5 && id === 4 && (
                    <p className={catalogItem('last-slide-more')}>
                      Ещё {data.photos.length - 5} фото
                    </p>
                  )}
                </SwiperSlide>
              );
              // }
            })}
        </Swiper>
        <div className={catalogItem('info')}>
          <div className={catalogItem('title')}>
            {!fav && !current && (
              <p className={catalogItem('found-status')}>
                {/* {data.condition.name} */}
                {getTypeCatalogItems(data.condition.type)}
                {/* { location.pathname === '/account/favorites/'
                ? data.condition.type
                : <>
                  {data.condition.type === 4 && <>Подходящая квартира</>}
                  {data.condition.type === 3 && <>Частично подходящая квартира</>}
                  {data.condition.type === 2 && <>Подходит под базовые критерии</>}
                </>
              } */}
              </p>
            )}
            {(fav || current) && data && data.city && (
              <p className={catalogItem('city-name')}>{data.city.name}</p>
            )}
            <h4 className={catalogItem('address')}>
              {data.rooms ? `${data.rooms}-к. квартира на ` : ''}
              {data.address}
            </h4>
          </div>
          <Raiting
            count={data.reviews ? data.reviews.avr : null}
            full
            reviews_count={data.reviews ? data.reviews.scores : undefined}
          />
          <div className={catalogItem('square')}>
            {data.bedcode && (
              <p className={catalogItem('description', { full_width: true })}>
                {data.bedcode} спальных мест
              </p>
            )}
            {data.area.total > 0 && (
              <p className={catalogItem('description')}>
                {data.area.total} м<sup>2</sup> общая площадь
              </p>
            )}
            {data.area.kitchen > 0 && (
              <p className={catalogItem('description')}>
                {data.area.kitchen} м<sup>2</sup> площадь кухни
              </p>
            )}
          </div>
          {advantages && advantages.length > 0 && <AdvantagesSticker data={advantages} />}
          <div className={catalogItem('other')}>
            <div className={catalogItem('icons')}>
              <div className={catalogItem('icons-list')}>
                {data.features &&
                  [
                    ...(data.condition && data.condition.info && data.condition.info.features
                      ? disabledIcons(data.condition.info.features)
                      : []),
                    ...getIconsArray(data.features),
                  ]
                    .slice(0, 8)
                    .map((item, id) => (
                      <Tooltip
                        key={id}
                        text={
                          Array.isArray(item.title)
                            ? item.title.find((items) => items.apiKey === item.apiKey)?.title ?? ''
                            : item.title
                        }>
                        <Icon
                          externalClassName={catalogItem('icon', { disable: item.disabled })}
                          iconName={
                            Array.isArray(item.icon)
                              ? item.icon.find((items) => items.apiKey === item.apiKey)?.icon ?? ''
                              : item.icon
                          }
                        />
                        {item.disabled && (
                          <Icon
                            externalClassName={catalogItem('icon-crossed')}
                            iconName="crossed_red"
                          />
                        )}
                      </Tooltip>
                    ))}
              </div>
              {data.features_count && data.features_count > 8 && (
                <p className={catalogItem('more-icons')}>+{data.features_count - 8}</p>
              )}
            </div>
            <div className={catalogItem('price-info')}>
              {!current ? (
                <h2 className={catalogItem('price')}>
                  {data.total === 0 ? data.price : data.total} ₽
                </h2>
              ) : (
                dataProduct && <h2 className={catalogItem('price')}>{dataProduct.total} ₽</h2>
              )}
              {!current ? (
                <p className={catalogItem('price-for')}>
                  {data.duration === null
                    ? 'цена за сутки'
                    : `цена за ${data.duration} ${declinationWord(data.duration)}`}
                </p>
              ) : (
                dataProduct && (
                  <p className={catalogItem('price-for')}>
                    {dataProduct.duration === null
                      ? 'цена за сутки'
                      : `цена за ${dataProduct.duration} ${declinationWord(
                          dataProduct.duration ?? 0,
                        )}`}
                  </p>
                )
              )}
            </div>
          </div>
        </div>
        <Like
          id={data.id}
          externalClassName={catalogItem('like', {
            booking: location.pathname === '/account' && location.hash === '#current-bookings',
          })}
        />
        {location.pathname === '/account' &&
          location.hash === '#current-bookings' &&
          (isPaswordVerified ? (
            <div className={catalogItem('booking')}>
              {dataProduct && (
                <div>
                  {dataProduct.status && (
                    <p
                      className={catalogItem('booking-request', {
                        active:
                          dataProduct.status === 'Внесена предоплата' ||
                          dataProduct.status === 'Полная предоплата',
                      })}>
                      {bookingStatusTextVerified(dataProduct.status)}
                    </p>
                  )}
                  {dataProduct.begindate && dataProduct.closedate && (
                    <h4 className={catalogItem('booking-date')}>
                      {formatedBookingDays(
                        dataProduct.begindate,
                        dataProduct.closedate,
                        false,
                        true,
                      )}
                    </h4>
                  )}
                  {dataProduct.status && (
                    <h5 className={catalogItem('booking-status')}>
                      <span className={catalogItem('description')}>Статус бронирования </span>
                      <p>{bookingStatusPayment(dataProduct.status)}</p>
                    </h5>
                  )}
                </div>
              )}
              {dataProduct && (
                <div className={catalogItem('booking-btn-group')}>
                  {dataProduct.status !== 'Полная оплата' &&
                  dataProduct.status === 'Внесена предоплата' ? (
                    <Button text="Оплатить полностью" />
                  ) : (
                    <Button text="Внести предоплату" />
                  )}
                  <Button
                    handleExternal={(event: React.MouseEvent<HTMLButtonElement>) =>
                      addingOrForwardingChat(event)
                    }
                    text="Сообщения"
                    transparent
                  />{' '}
                  {(dataProduct.status === 'Предварительное бронирование' ||
                    dataProduct.status === 'Бронирование') && (
                    <Button
                      handleExternal={(event: React.MouseEvent<HTMLButtonElement>) =>
                        handleOpenModal(event)
                      }
                      text="Отменить бронирование"
                      red
                    />
                  )}
                </div>
              )}
            </div>
          ) : (
            dataProduct && (
              <div className={catalogItem('booking')}>
                <div className={catalogItem('not-verifed-content')}>
                  {dataProduct.status && (
                    <p
                      className={catalogItem('booking-request', {
                        active:
                          dataProduct.status === 'Внесена предоплата' ||
                          dataProduct.status === 'Полная предоплата',
                      })}>
                      {bookingStatusTextVerified(dataProduct.status)}
                    </p>
                  )}
                  {dataProduct.begindate && dataProduct.closedate && (
                    <h4 className={catalogItem('booking-date')}>
                      {formatedBookingDays(
                        dataProduct.begindate,
                        dataProduct.closedate,
                        false,
                        true,
                      )}
                    </h4>
                  )}
                  {dataProduct.status && <p>{bookingStatusTextNotVerified(dataProduct.status)}</p>}
                  <p>
                    Для того, чтобы моментально бронировать квартиру без подтверждения необходимо{' '}
                    <Link to="/account">подтвердить свою личность в личном кабинете.</Link>
                  </p>
                </div>
              </div>
            )
          ))}
      </Link>
      <Modal open={openModal} handleClose={handleCloseModal}>
        <h3 className={catalogItem('modal-header')}>Выберите причину отмены брони</h3>
        {dataProduct && (
          <RadioButtons
            data={[
              { text: 'Изменились обстоятельства', value: 2 },
              { text: 'Нашел дешевле', value: 6 },
              { text: 'Отменилась командировка', value: 1 },
              { text: 'Другое', value: 11 },
            ]}
            name={`causeCancellation_${data.id}_${dataProduct.begindate}_${dataProduct.closedate}`}
            externalClassName={catalogItem('field')}
            handleExternal={handleCauseCancellationValue}
            valueExternal={causeCancellation}
          />
        )}
        <div className={catalogItem('modal-button')}>
          <Button
            text="Закрыть"
            handleExternal={(event: React.MouseEvent<HTMLButtonElement>) => handleCloseModal(event)}
            red
          />
          <Button
            text="Отменить бронирование"
            handleExternal={(event: React.MouseEvent<HTMLButtonElement>) =>
              deletUserBookingDeals(event)
            }
            disable={!disabledCancellation}
            typeButton="submit"
          />
        </div>
      </Modal>
    </>
  ) : (
    <Link to={`${location.pathname}`} className={catalogItem({ relocation: true })}>
      <div className={catalogItem('info', { relocation: true })}>
        {/* {data.relocation_apartament && (
          <>
            <div className={catalogItem('apartament', { start: true })}>
              <img
                className={catalogItem('image')}
                src={data.relocation_apartament[0].images[0]}
                alt=""
              />
              <div className={catalogItem('title')}>
                <p className={catalogItem('found-status', { relocation: true })}>
                  {data.relocation_apartament[0].date}
                </p>
                <h4 className={catalogItem('address')}>{data.relocation_apartament[0].address}</h4>
                <Raiting count={data.relocation_apartament[0].raiting} full />
              </div>
            </div>
            <div className={catalogItem('apartament')}>
              <img
                className={catalogItem('image')}
                src={data.relocation_apartament[1].images[0]}
                alt=""
              />
              <div className={catalogItem('title')}>
                <p className={catalogItem('found-status', { relocation: true })}>
                  {data.relocation_apartament[1].date}
                </p>
                <h4 className={catalogItem('address')}>{data.relocation_apartament[1].address}</h4>
                <Raiting count={data.relocation_apartament[1].raiting} full />
              </div>
            </div>
          </>
        )} */}
      </div>
      <div className={catalogItem('other')}>
        <div className={catalogItem('date')}>
          {data && data.condition && (
            <p className={catalogItem('found-status')}>
              {getTypeCatalogItems(data.condition.type)}
            </p>
          )}
          {/* <h4>{data.full_date}</h4> */}
        </div>
        <div className={catalogItem('price-info')}>
          {data && data.price && <h2 className={catalogItem('price')}>{data.price} ₽</h2>}
          {/* <p className={catalogItem("price-for")}>{data.price_for}</p> */}
        </div>
      </div>
    </Link>
  );
};

export default CatalogItem;
