import iconsList from '../../featuresData.json';

interface IIconsArray {
  id: number;
  title: string;
  icon: string;
  apiKey: string;
  type: string;
  typeFilters?: string;
  disabled?: boolean;
}

export const getIconsArray = (features: any, isEnabled = true) => {
  const featuresArray = Object.keys(features);

  const filteredIcon = featuresArray.filter((item) => features[item as keyof any] === isEnabled);

  const result = iconsList.filter((item) => filteredIcon.includes(item.apiKey));

  return result as Array<IIconsArray>;
};

export const disabledIcons = (feature: any) => {
  return getIconsArray(feature, false).map((featureDesbled) => {
    const res = Object.assign(featureDesbled, { disabled: true });
    return res;
  });
};

export const getIconsType = (features: any, type: string) => {
  const result = getIconsArray(features)
    .filter((item) => item.type === type)
    .map((item) => ({ icon: item.icon, text: item.title }));
  return result;
};

export const getFlagsIcons = (flags: Array<string>, type?: string) => {
  const result = iconsList.filter((item) => flags.includes(item.apiKey));

  return type
    ? result
        .filter((item) => item.type === type)
        .map((item) => ({ icon: item.icon, text: item.title }))
    : result;
};

export const getSquare = (square: any) => {
  const result = [];
  if (square.bed2 !== null && square.bed2 !== 0) {
    result.push({ text: `двуспальная кровать (${square.bed2} штука)` });
  }
  if (square.bed1 !== null && square.bed1 !== 0) {
    result.push({ text: `односпальная кровать (${square.bed1} штука)` });
  }
  if (square.sofa2 !== null && square.sofa2 !== 0) {
    result.push({ text: `двуспальный диван (${square.sofa2} штука)` });
  }
  if (square.sofa1 !== null && square.sofa1 !== 0) {
    result.push({ text: `односпальный диван (${square.sofa1} штука)` });
  }

  return result;
};

export const toStringFilter = (filterParams: any) => {
  const stringFilter = JSON.stringify(filterParams);
  return stringFilter !== '{}' ? stringFilter : undefined;
};

export const getTypeCatalogItems = (type: number) => {
  switch (type) {
    case 4:
      return 'Подходящая квартира';
    case 3:
    case 2:
      return 'Частично подходящая квартира';
    case 1:
      return 'С переселением';
    case 0:
      return 'Подходит под базовые критерии';
    default:
      return 'Подходящая квартира';
  }
};

export const getSortSelected = (type: string) => {
  switch (type) {
    case 'Ближе к центру':
      return ['centerDistance', 'ASC'];
    case 'Дороже':
      return ['price', 'DESC'];
    case 'Дешевле':
      return ['price', 'ASC'];
    case 'Популярные':
      return ['Popular', 'ASC'];
    default:
      return ['centerDistance', 'ASC'];
  }
};

export const declinationWord = (dateValue: number): string => {
  if (dateValue % 10 === 1 && dateValue % 100 !== 11) {
    return 'ночь';
  }
  if (
    dateValue % 10 >= 2 &&
    dateValue % 10 <= 4 &&
    (dateValue % 100 < 10 || dateValue % 100 >= 20)
  ) {
    return 'ночи';
  }
  return 'ночей';
};
