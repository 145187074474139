import { useEffect } from 'react';

import AppInput from '../../UI/AppInput';
import Button from '../../UI/Button';

import { PASSWORD } from '../../app_constants';
import bemClassName from '../../utils/bem';

import './index.scss';

const security = bemClassName('security');

const Security = () => {
  useEffect(() => {
    document.title = 'Пароль и безопасность';
  }, []);

  return (
    <>
      <div className={security('text')}>
        <h2>Смена пароля</h2>
        <p>Пароль должен состоять минимум из 8 символов, содержать цифры и буквы</p>
      </div>
      <AppInput
        inputId="currentPassword"
        text="Текущий пароль"
        type={PASSWORD}
        externalClassName={security('field')}
        placeholder="Введите текущий пароль"
      />
      <AppInput
        inputId="newPassword"
        text="Новый пароль"
        type={PASSWORD}
        externalClassName={security('field')}
        placeholder="Введите новый пароль"
      />
      <AppInput
        inputId="ReplaceNewPassword"
        text="Повтор нового пароля"
        type={PASSWORD}
        externalClassName={security('field')}
        placeholder="Повторите новый пароль"
      />
      <Button text="Сохранить" disable externalClassName={security('button')} />
    </>
  );
};

export default Security;
