// import axios from 'axios';
// import { BASE_API_URL } from '../../app_constants';

import axiosInterseptor from '../../utils/axiosInterseptor';

class Public {
  // static city = {
  //   async getCityData(): Promise<any> {
  //     const response = await axios({
  //       method: 'get',
  //       url: `${BASE_API_URL}addreses/cites/all?mode=data`,
  //     });
  //     const { data } = response;
  //     return data;
  //   },
  // };

  static rooms = {
    async getRoomsData(): Promise<any> {
      const response = await fetch(`/roomsData.json`);
      const data = await response.json();
      return data;
    },
  };

  static async getQuestionAnswer() {
    try {
      const response = await axiosInterseptor.get('info/faq');
      const { data } = response;
      return data;
    } catch (error) {
      console.error('Ошибка при попытке получения данных с сервера:', error);
      throw error;
    }
  }

  static async getServiceRules() {
    try {
      const response = await axiosInterseptor.get('info/rules');
      const { data } = response;
      return data;
    } catch (error) {
      console.error('Ошибка при попытке получения данных с сервера:', error);
      throw error;
    }
  }

  static async getRequisites() {
    try {
      const response = await axiosInterseptor.get('info/props');
      const { data } = response;
      return data;
    } catch (error) {
      console.error('Ошибка при попытке получения данных с сервера:', error);
      throw error;
    }
  }

  static async getAboutCompany() {
    try {
      const response = await axiosInterseptor.get('info/about');
      const { data } = response;
      return data;
    } catch (error) {
      console.error('Ошибка при попытке получения данных с сервера:', error);
      throw error;
    }
  }

  static async getContact() {
    try {
      const response = await axiosInterseptor.get('');
      const { data } = response;
      return data;
    } catch (error) {
      console.error('Ошибка при попытке получения данных с сервера:', error);
      throw error;
    }
  }
}

export default Public;
