import React from 'react';
import bemClassName from '../../utils/bem';

import './index.scss';

const container = bemClassName('container');

interface IContainerProps {
  children: React.ReactNode;
  catalog?: boolean;
}

const Container: React.FC<IContainerProps> = ({ children, catalog }) => {
  return <div className={container({ catalog })}>{children}</div>;
};

export default Container;
