import axios from 'axios';
import axiosInterseptor from '../../utils/axiosInterseptor';
import { BASE_API_URL_SECRET } from '../../app_constants';

export default class PrivateData {
  static async getPrivateUser() {
    try {
      const response = await axiosInterseptor.get('user');
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getPrivateLoggingOut() {
    // const response = await getAxiosPrivateExample(BASE_API_URL_SECRET).delete('');
    // const refreshToken = localStorage.getItem('refresh_token');
    const accessToken = localStorage.getItem('access_token');
    const tokenId = localStorage.getItem('id_token');
    if (BASE_API_URL_SECRET && BASE_API_URL_SECRET !== undefined) {
      const response = await axios.delete(BASE_API_URL_SECRET, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          id_token: tokenId,
        },
      });

      const { data } = response;
      return data;
    }
    return undefined;
  }

  static async getPrivateEditUserDate(array: any) {
    try {
      const response = await axiosInterseptor.put('', array);
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getPrivateUserOferta() {
    try {
      const response = await axiosInterseptor.get('user/oferta');
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  // static async getPrivateUserDeals(id?: number) {
  //   try {
  //     const params = `&filters={"city":${id}}`;
  //     const response = await axiosInterseptor.get(
  //       id ? `user/deals?mode=filter${params}` : `user/deals?mode=filter`,
  //     );
  //     const { data } = response;
  //     return data;
  //   } catch (error) {
  //     return error;
  //   }
  // }

  static async getPrivateUserDealsHistoryBooking({
    id,
    signal,
  }: {
    id?: number;
    signal?: AbortSignal;
  }) {
    try {
      const filters: { stage: string[]; city?: number } = { stage: ['executing', 'lose'] };
      if (id) {
        filters.city = id;
      }

      const params = new URLSearchParams();
      params.append('filters', JSON.stringify(filters));

      const response = await axiosInterseptor.get(`user/deals?mode=filter&${params}`, { signal });
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getPrivateUserDealsCurrentBooking() {
    try {
      const filters: { stage: string[] } = { stage: ['incoming'] };

      const params = new URLSearchParams();
      params.append('filters', JSON.stringify(filters));

      const response = await axiosInterseptor.get(`user/deals?mode=filter&${params}`);
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getPrivateCancellationOferta() {
    try {
      const response = await axiosInterseptor.delete('user/oferta');
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getPrivateCollectionFavorite(
    ids: Array<number>,
    page: number,
    signal?: AbortSignal,
    cityId?: number,
  ) {
    const config = cityId
      ? {
          params: {
            products: JSON.stringify(ids),
            filters: JSON.stringify({ city: cityId }),
            page,
          },
          signal,
        }
      : {
          params: {
            products: JSON.stringify(ids),
            page,
          },
          signal,
        };

    try {
      const response = await axiosInterseptor.get('products/select?mode=filter', config);
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getPrivateFavoriteArrayId() {
    try {
      const response = await axiosInterseptor.get('user/favorites');
      const { data } = response;
      return data;
    } catch (error) {
      console.error('Ошибка при получении избранного массива ID');
      throw error;
    }
  }

  static async getPrivateFavoriteAddedArrayId(ids: Array<number>) {
    try {
      const response = await axiosInterseptor.post('user/favorites', {
        products: ids,
      });
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getPrivateAddetToCollectionFavorite(id: number, params: any) {
    try {
      const response = await axiosInterseptor.post('user/favorite', {
        product_id: id,
        config: params,
      });
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getPrivateDeleteCollectionFavorite(id: number) {
    try {
      const response = await axiosInterseptor.delete(`user/favorite?product_id=${id}`);
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getPrivateAddedPassportPhoto(photo: any) {
    const formData = new FormData();
    formData.append('photo', photo);

    try {
      const response = await axiosInterseptor.post('user/photo', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getPrivateAddedPassportAndUserFaceVideo(video: any) {
    const formData = new FormData();
    formData.append('photo', video);

    try {
      const response = await axiosInterseptor.post('user/video', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getPrivateUserAddetChats(id: number) {
    try {
      const response = await axiosInterseptor.post('user/chats', { product_id: id });
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getPrivateUserAddetReview(product_id: number, text: string, score: number) {
    try {
      const response = await axiosInterseptor.post('user/review', { product_id, text, score });
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getPrivateUserApartmentReservation(
    product_id: string,
    begin: string,
    end: string,
    adult_count: number,
    child_count: number,
  ) {
    try {
      const response = await axiosInterseptor.post('/user/deal', {
        product_id,
        begin,
        end,
        adult_count,
        child_count,
      });
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getPrivateUserDealStatusBooking(id: string) {
    try {
      const response = await axiosInterseptor.get(`user/product?product_id=${id}&mode=search`);
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  static async deleteUserDeals(id: string, reason: number) {
    try {
      const response = await axiosInterseptor.delete(`user/deal?deal_id=${id}&reason=${reason}`);
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getBonusInformation() {
    try {
      const response = await axiosInterseptor.get('info/bonus');
      const { data } = response;
      return data;
    } catch (error) {
      console.error('Ошибка при попытке получения данных с сервера:', error);
      throw error;
    }
  }
}
