import bemClassName from '../../utils/bem';
import Icon from '../Icon';

import './index.scss';

const loading = bemClassName('loading');

const Loading = ({ externalClassName = '' }) => {
  return (
    <div className={`${loading()} ${externalClassName}`}>
      <Icon externalClassName={loading('icon')} iconName="loading" />
    </div>
  );
};

export default Loading;
