import axiosInterseptor from '../../utils/axiosInterseptor';

export default class Validate {
  static async checkEmail(email: string) {
    try {
      const response = await axiosInterseptor.get(`validator/check?type=email&data=${email}`);
      const data = await response.data;
      return data;
    } catch (error: any) {
      const dataError = error.response.data.error.details;
      throw dataError;
    }
  }

  static async helperEmail(email: string) {
    try {
      const response = await axiosInterseptor.get(`validator/helper?type=email&data=${email}`);
      const data = await response.data;
      return data;
    } catch (error: any) {
      const dataError = error;
      throw dataError;
    }
  }

  static async normalizeEmail(email: string) {
    try {
      const response = await axiosInterseptor.get(`validator/helper?type=email&data=${email}`);
      const data = await response.data;
      return data;
    } catch (error: any) {
      const dataError = error;
      throw dataError;
    }
  }

  static async checkPhone(phone: string, signal: AbortSignal) {
    try {
      const response = await axiosInterseptor.get(`validator/check?type=phone&data=${phone}`, {
        signal,
      });
      const data = await response.data;
      return data;
    } catch (error: any) {
      const dataError = error;
      throw dataError;
    }
  }

  static async checkName(fio: string) {
    try {
      const response = await axiosInterseptor.get(`validator/check?type=name&data=${fio}`);
      const data = await response.data;
      return data;
    } catch (error: any) {
      const dataError = error;
      throw dataError;
    }
  }

  static async helperName({ name, type }: { name: string; type: string }) {
    try {
      const response = await axiosInterseptor.get(`validator/helper?type=${type}&data=${name}`);
      const data = await response.data;
      return data.help;
    } catch (error: any) {
      // const dataError = error;
      // throw dataError;
      return error;
    }
  }

  static async checkPassport(number: string, signal: AbortSignal) {
    try {
      const response = await axiosInterseptor.get(`validator/check?type=passport&data=${number}`, {
        signal,
      });
      const data = await response.data;
      return data;
    } catch (error: any) {
      const dataError = error.response.data.error.details;
      throw dataError;
      // return dataError;
    }
  }

  static async helperPassport({ value, type }: { value: string; type: string }) {
    try {
      const response = await axiosInterseptor.get(
        `validator/helper?type=passport&data=${value}&method=${type}`,
      );
      const data = await response.data;
      return data;
    } catch (error: any) {
      const dataError = error;
      throw dataError;
    }
  }
}
