import { Link } from 'react-router-dom';

import bemClassName from '../../utils/bem';

import phone from '../../assets/images/iPhone-14.png';
import appStore from '../../assets/images/app-store-button-main.png';
import googlePlay from '../../assets/images/google-play-button-main.png';
import qr from '../../assets/images/qr-code.png';

import './index.scss';

const mobileApp = bemClassName('mobile-app');

const MobileApp = () => {
  return (
    <div className={mobileApp()}>
      <h2 className={mobileApp('title')}>Мобильное приложение</h2>
      <p className={mobileApp('text')}>Скачивайте наши мобильные приложения</p>
      <div className={mobileApp('desktop-image')}>
        <Link className={mobileApp('link')} to="#t">
          <img src={googlePlay} className={mobileApp('image', { google: true })} alt="" />
        </Link>
        <Link className={mobileApp('link')} to="#t">
          <img src={appStore} className={mobileApp('image', { store: true })} alt="" />
        </Link>
      </div>
      <div className={mobileApp('image-wrapper')}>
        <picture className={mobileApp('phone-picture')}>
          <img src={phone} className={mobileApp('phone-image')} alt="" />
        </picture>
        <div className={mobileApp('mobile-image')}>
          <Link className={mobileApp('link')} to="#t">
            <img src={googlePlay} className={mobileApp('image', { google: true })} alt="" />
          </Link>
          <Link className={mobileApp('link')} to="#t">
            <img src={appStore} className={mobileApp('image', { store: true })} alt="" />
          </Link>
        </div>
        <div className={mobileApp('qr-wrapper')}>
          <h4 className={mobileApp('qr-title')}>Отсканируйте QR-код</h4>
          <img src={qr} className={mobileApp('qr')} alt="" />
        </div>
      </div>
    </div>
  );
};

export default MobileApp;
