const formatedDateOfStay = (beginDate: string, closeDate: string) => {
  const monthNames = new Intl.DateTimeFormat('ru', { month: 'long' });

  const beginDateWithoutTime = beginDate.slice(0, 10);
  const closeDateWithoutTime = closeDate.slice(0, 10);

  const [beginYear, , beginDay] = beginDateWithoutTime.split('-').map(Number);
  const [closeYear, closeMonth, closeDay] = closeDateWithoutTime.split('-').map(Number);

  const closeMonthName = monthNames.format(new Date(closeYear, closeMonth - 1, closeDay));

  return `${beginDay}–${closeDay} ${closeMonthName} ${beginYear} г.`;
};

export default formatedDateOfStay;
