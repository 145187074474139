import { useEffect, useState } from 'react';

import Icon from '../Icon';

import { IRaiting } from './interface';
import bemClassName from '../../utils/bem';

import './index.scss';

const raiting = bemClassName('raiting');

const Raiting: React.FC<IRaiting> = ({
  count,
  externalClassName = '',
  full,
  reviews_count,
  handleExternal,
}) => {
  const [countValue, setCountValue] = useState(0);

  const handleSet = (id: number) => {
    handleExternal(id + 1);
    setCountValue(id + 1);
  };

  useEffect(() => {
    if (count) {
      setCountValue(count);
    } else if (count === null) {
      setCountValue(0);
    }
  }, []);
  return (
    <div className={`${raiting()} ${externalClassName}`}>
      {count !== undefined ? (
        <>
          {full && countValue > 0 && (
            <p className={raiting('text')}>
              {Math.floor(countValue)},{Math.floor((countValue % 1) * 10 ** 1)}
            </p>
          )}
          <div className={raiting('stars')}>
            {[...Array(5)].map((item, id) => {
              return (
                <div key={id} className={raiting('item')}>
                  <Icon iconName="star" externalClassName={raiting('icon')} />
                  {id + 1 <= Math.trunc(countValue) && (
                    <div className={raiting('gold-icon-wrapper')}>
                      <Icon iconName="star" externalClassName={raiting('icon', { gold: true })} />
                    </div>
                  )}
                  {id + 1 === Math.trunc(countValue) + 1 &&
                    Math.floor((countValue % 1) * 100) > 0 && (
                      <div
                        className={raiting('gold-icon-wrapper')}
                        style={{ width: `${Math.floor((countValue % 1) * 100)}%` }}>
                        <Icon iconName="star" externalClassName={raiting('icon', { gold: true })} />
                      </div>
                    )}
                </div>
              );
            })}
          </div>
          {reviews_count !== undefined && reviews_count !== null && reviews_count > 0 && (
            <p className={raiting('reviews-count')}>{reviews_count} отзывов</p>
          )}
        </>
      ) : (
        <div className={raiting('stars')}>
          {[...Array(5)].map((item, id) => {
            return (
              <div
                key={id}
                className={raiting('item', { clickable: !count })}
                onClick={() => handleSet(id)}>
                <Icon iconName="star" externalClassName={raiting('icon')} />
                {id + 1 <= Math.trunc(countValue) && (
                  <div className={raiting('gold-icon-wrapper')}>
                    <Icon iconName="star" externalClassName={raiting('icon', { gold: true })} />
                  </div>
                )}
                {id + 1 === Math.trunc(countValue) + 1 &&
                  Math.floor((countValue % 1) * 100) > 0 && (
                    <div
                      className={raiting('gold-icon-wrapper')}
                      style={{
                        width: `${Math.floor((countValue % 1) * 100)}%`,
                      }}>
                      <Icon iconName="star" externalClassName={raiting('icon', { gold: true })} />
                    </div>
                  )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Raiting;
