import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import Search from '../Search';
import CatalogFilters from '../CatalogFilters';
import Icon from '../../UI/Icon';

import { toStringRooms } from '../../utils/searchUtils';
import bemClassName from '../../utils/bem';

import './index.scss';
import { formatedBookingDays } from '../../utils/calendarDayUtils';

const catalogSearch = bemClassName('catalog-search');

const CatalogSearch = () => {
  const windowWidth = useSelector((state: RootState) => state.window.windowWidth);
  const citySelected = useSelector((state: RootState) => state.searchData.citySelected);
  const guests = useSelector((state: RootState) => state.searchData.guests);
  const rooms = useSelector((state: RootState) => state.searchData.rooms);
  const calendarSelected = useSelector((state: RootState) => state.searchData.calendarSelected);

  const [openModal, setOpenModal] = useState(false);

  const getCorrectStr = (count: number, variant1: string, variant2: string, variant3: string) => {
    let value = count % 100;
    if (value > 10 && value < 20) {
      return `${count} ${variant3}`;
    }
    value = count % 10;
    if (value === 1) {
      return `${count} ${variant1}`;
    }
    if (value > 1 && value < 5) {
      return `${count} ${variant2}`;
    }
    return `${count} ${variant3}`;
  };

  const handleOpenModal = () => {
    setOpenModal(true);
    document.body.style.overflow = 'hidden';
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    document.body.removeAttribute('style');
  };
  return (
    <div className={catalogSearch()}>
      {windowWidth >= 960 ? (
        <Search />
      ) : (
        <>
          <div onClick={handleOpenModal} className={catalogSearch('open-modal-btn')}>
            <div className={catalogSearch('open-modal-info')}>
              {citySelected && citySelected.name && (
                <p className={catalogSearch('open-modal-city')}>{citySelected.name}</p>
              )}
              {(guests || rooms || calendarSelected) && (
                <p className={catalogSearch('open-modal-other')}>
                  {calendarSelected &&
                    calendarSelected.checkIn &&
                    calendarSelected.departure &&
                    formatedBookingDays(calendarSelected.checkIn, calendarSelected.departure, true)}
                  {guests &&
                    calendarSelected &&
                    calendarSelected.checkIn &&
                    calendarSelected.departure && (
                      <span className={catalogSearch('dot-separator')} />
                    )}
                  {guests !== null &&
                    getCorrectStr(guests[0] + guests[1], 'Гость', 'Гостя', 'Гостей')}
                  {/* {guests && rooms && <span className={catalogSearch('dot-separator')} />} */}
                  {rooms &&
                    (guests ||
                      (calendarSelected &&
                        calendarSelected.checkIn &&
                        calendarSelected.departure)) && (
                      <span className={catalogSearch('dot-separator')} />
                    )}
                  {rooms !== null && `${toStringRooms(rooms)} комнаты`}
                </p>
              )}
            </div>
            <Icon iconName="union" externalClassName={catalogSearch('icon')} />
          </div>
          <div className={catalogSearch('modal', { active: openModal })}>
            <Icon
              iconName="close"
              externalClassName={catalogSearch('modal-icon')}
              handleClick={handleCloseModal}
            />
            <Search modal handleExternal={() => setOpenModal(false)} />
          </div>
        </>
      )}
      <CatalogFilters />
    </div>
  );
};

export default CatalogSearch;
