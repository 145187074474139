import * as oauth from 'oauth4webapi';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import axios from 'axios';
import { REDIRECT_URI, AUTH_CONFIG, CLIENT } from '../../app_constants';
import {
  setAccessToken,
  setExpires,
  setLogin,
} from '../../store/reducers/profile/autorizationStatutsSlice';
import Loading from '../../UI/Loading';
import bemClassName from '../../utils/bem';
import './index.scss';

const auth = bemClassName('auth');

const Auth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getToken = async () => {
    const currentUrl = new URL(window.location.href);

    {
      const params = oauth.validateAuthResponse(
        AUTH_CONFIG,
        CLIENT,
        currentUrl,
        oauth.skipStateCheck,
      );
      if (oauth.isOAuth2Error(params)) {
        console.error('error', params);
        throw new Error('OAuth 2.0 error');
      }

      const additionalParams: URLSearchParams = new URLSearchParams();
      additionalParams.set('client secret', CLIENT.client_secret!);

      const options: oauth.TokenEndpointRequestOptions = {
        additionalParameters: additionalParams,
      };

      const response = await oauth.authorizationCodeGrantRequest(
        AUTH_CONFIG,
        CLIENT,
        params,
        REDIRECT_URI as string,
        'x',
        options,
      );

      try {
        const result = await oauth.processAuthorizationCodeOpenIDResponse(
          AUTH_CONFIG,
          CLIENT,
          response,
        );

        if (result.access_token && result.expires_in && result.id_token) {
          const expiresTime = Date.now();
          const tokenExpirationTime = expiresTime + +result.expires_in * 1000;

          localStorage.setItem('refresh_token', String(result.refresh_token));
          localStorage.setItem('tokenExpirationTime', String(tokenExpirationTime));
          localStorage.setItem('id_token', String(result.id_token));

          localStorage.setItem('access_token', String(result.access_token));
          dispatch(setAccessToken(result.access_token as string));

          dispatch(setExpires(tokenExpirationTime));
          dispatch(setLogin());

          navigate('/');
        } else {
          console.error('Не удалось получить: access_token');
        }
      } catch {
        console.log('Не удалось получить токен!');
      }
    }
  };
  let stop = false;

  useEffect(() => {
    if (!stop) {
      stop = true;
      getToken();
    }
  }, []);

  return <Loading externalClassName={auth()} />;
};

export default Auth;
