import { Swiper, SwiperSlide } from 'swiper/react';

import { Pagination, Grid } from 'swiper';
import AppLink from '../AppLink';
import Icon from '../../UI/Icon';

import bemClassName from '../../utils/bem';

import 'swiper/scss';
import 'swiper/scss/grid';
import 'swiper/scss/pagination';

import './index.scss';

const advantages = bemClassName('advantages');

const Advantages = () => {
  return (
    <div className={advantages()}>
      <h2 className={advantages('title')}>Почему выбирают нас?</h2>
      <p className={advantages('text')}>
        Узнайте о нашем <AppLink url="#" text="стандарте обслуживания" />
      </p>
      <Swiper
        className={advantages('carousel')}
        spaceBetween={30}
        slidesPerView={1}
        modules={[Pagination, Grid]}
        pagination={{ clickable: true }}
        loop
        breakpoints={{
          600: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1025: {
            slidesPerView: 3,
            pagination: false,
            loop: false,
            grid: {
              fill: 'row',
              rows: 2,
            },
            enabled: false,
          },
        }}>
        <SwiperSlide>
          <Icon externalClassName={advantages('icon')} iconName="success" />
          <h3 className={advantages('carousel-title')}>9 лет успешной работы</h3>
        </SwiperSlide>
        <SwiperSlide>
          <Icon externalClassName={advantages('icon')} iconName="safety" />
          <h3 className={advantages('carousel-title')}>Гарантия успешного заселения</h3>
        </SwiperSlide>
        <SwiperSlide>
          <Icon externalClassName={advantages('icon')} iconName="precent" />
          <h3 className={advantages('carousel-title')}>Без наценок и скрытых комиссий</h3>
        </SwiperSlide>
        <SwiperSlide>
          <Icon externalClassName={advantages('icon')} iconName="around-the-clock" />
          <h3>Круглосуточное заселение</h3>
        </SwiperSlide>
        <SwiperSlide>
          <Icon externalClassName={advantages('icon')} iconName="check-in" />
          <h3 className={advantages('carousel-title')}>Заезд через 5 минут после бронирования</h3>
        </SwiperSlide>
        <SwiperSlide>
          <Icon externalClassName={advantages('icon')} iconName="documentation" />
          <h3 className={advantages('carousel-title')}>Отчетные документы для бухгалтерии</h3>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Advantages;
