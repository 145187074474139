import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import Validate from '../../../API/Validate';

// export const fetchCheckPassportValidate = createAsyncThunk(
//   'data/fetchCheckPassportValidate',
//   Validate.checkPassport,
// );

export const fetchCheckPassportValidate = createAsyncThunk(
  'data/fetchCheckPassportValidate',
  (number: string, { signal }) => {
    try {
      const result = Validate.checkPassport(number, signal);
      return result;
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
);

export const fetchHelperPassportValidate = createAsyncThunk(
  'data/fetchHelperPassportValidate',
  Validate.helperPassport,
);

export interface DataState {
  passport: string;
  passportNumber: string | null;
  passportIsValid: boolean | null;
  passportErrorText: string | null;
  passportHelperArray: Array<string> | null;
}
// export interface IPassportErrorText {
//   code: number;
//   details: string;
//   message: string;
// }

export interface IPassportHelperItem {
  name: string;
}

export interface IPassportCheckData {
  series: string;
  number: string;
  region: {
    fullname: string;
    name: string;
    iso: string;
  };
  warnings: boolean;
  issued: string;
  valid: boolean;
  criminal: boolean;
  inbases: boolean;
}

const initialState: DataState = {
  passport: '',
  passportNumber: null,
  passportIsValid: null,
  passportErrorText: null,
  passportHelperArray: null,
};

export const passportDataSlice = createSlice({
  name: 'passportData',
  initialState,
  reducers: {
    setDefaultValuePassportValidate: (state) => {
      state.passportIsValid = null;
      state.passportErrorText = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchCheckPassportValidate.fulfilled,
      (state, action: PayloadAction<IPassportCheckData | string>) => {
        if (typeof action.payload === 'string') {
          state.passportErrorText = action.payload;
          state.passportIsValid = false;
        } else {
          state.passportIsValid = action.payload.valid;
          state.passportNumber = `${action.payload.series} ${action.payload.number}`;
        }
      },
    );
    builder.addCase(
      fetchHelperPassportValidate.fulfilled,
      (state, action: PayloadAction<Array<IPassportHelperItem> | string>) => {
        if (typeof action.payload === 'object') {
          state.passportHelperArray = action.payload.map((item) => item.name);
        }
      },
    );
  },
});

export const { setDefaultValuePassportValidate } = passportDataSlice.actions;

export default passportDataSlice.reducer;
