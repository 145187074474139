import { useDispatch, useSelector } from 'react-redux';

import Checkbox from '../../UI/Checkbox';
import RangeSlider from '../../UI/RangeSlider';
import OtherFilters from '../OtherFilters';
import bemClassName from '../../utils/bem';

import './index.scss';
import { RootState } from '../../store';
import {
  setSliderPriceValue,
  toggleIsMark,
  toggleisBonus,
} from '../../store/reducers/search/searchDataSlice';

const catalogFilters = bemClassName('catalog-filters');

const CatalogFilters = () => {
  const sliderStartPriceValue = useSelector(
    (state: RootState) => state.searchData.sliderStartPriceValue,
  );
  const sliderPriceValue = useSelector((state: RootState) => state.searchData.sliderPriceValue);
  const isContactless = useSelector((state: RootState) => state.searchData.isContactless);
  const isBonus = useSelector((state: RootState) => state.searchData.isBonus);
  const isModalMap = useSelector((state: RootState) => state.mapModal.isModalMap);

  const dispatch = useDispatch();

  return (
    <div className={catalogFilters('', { mapModal: isModalMap })}>
      <RangeSlider
        title="Цена за сутки"
        minValue={sliderStartPriceValue ? sliderStartPriceValue.minValue : 0}
        maxValue={sliderStartPriceValue ? sliderStartPriceValue.maxValue : 5000}
        action={setSliderPriceValue}
        fromParametr={sliderPriceValue ? sliderPriceValue[0] : undefined}
        toParametr={sliderPriceValue ? sliderPriceValue[1] : undefined}
        externalClassName={catalogFilters('price-slider')}
      />
      <div className={catalogFilters('checbox-wrap')}>
        <Checkbox
          checked={isContactless}
          text="Бесконтактное заселение"
          externalClassName={catalogFilters('checkbox')}
          handleExternal={() => dispatch(toggleIsMark())}
        />
        <Checkbox
          checked={isBonus}
          text="Бонусные баллы"
          externalClassName={catalogFilters('checkbox')}
          handleExternal={() => dispatch(toggleisBonus())}
        />
      </div>
      <OtherFilters />
    </div>
  );
};

export default CatalogFilters;
