import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import Calendar from './Calendar';
import bemClassName from '../../utils/bem';
import './index.scss';
import { RootState } from '../../store';
import WeekDays from './WeekTitle';
import { setCheckIn, setDeparture } from '../../store/reducers/search/searchDataSlice';
import Button from '../Button';
import { closeSearchModal } from '../../store/reducers/modal/modalSearchSlice';
import AddDays from './ButtonAddDays';
import { date } from '../../utils/date';
import { GetFormattedDate, getMobailCalendars } from '../../utils/calendarUtils';
import Icon from '../Icon';
import { setMaxDate, setMinDate } from '../../store/reducers/calendar/calendarDataSlice';

const calendarContainer = bemClassName('calendar-container');

interface ICalendar {
  miniCalendar?: boolean;
  externalClassName?: string;
  handleExternal?: any;
  bookingPanelCalendar?: boolean;
  passportDate?: boolean;
}

const CalendarContainer: React.FC<ICalendar> = ({
  miniCalendar,
  bookingPanelCalendar,
  externalClassName = '',
  handleExternal,
  passportDate,
}) => {
  const windowWidth = useSelector((state: RootState) => state.window.windowWidth);
  const calendarSelected = useSelector((state: RootState) => state.searchData.calendarSelected);
  const maxDate = new Date(
    useSelector((state: RootState) => state.calendarData.dateInYear.maxDate),
  );
  const minDate = new Date(
    useSelector((state: RootState) => state.calendarData.dateInYear.minDate),
  );

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const [firstCurrentMonth, setFirstCurrentMonth] = useState(new Date());
  const [nextCurrentMonth, setNextCurrentMonth] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState<string | null>(null);
  const [prevDate, setPrevDate] = useState(new Date());
  const [nextDate, setNextDate] = useState(new Date());
  const formattedDate = GetFormattedDate();

  const handlePrevClick = () => {
    if (prevDate !== null && minDate !== null) {
      if (prevDate <= minDate) {
        setFirstCurrentMonth(new Date(minDate));
      } else {
        setFirstCurrentMonth(new Date(prevDate));
      }
    }
  };

  const handleNextClick = () => {
    if (nextDate !== null && maxDate !== null) {
      if (nextDate >= maxDate) {
        setFirstCurrentMonth(new Date(maxDate));
      } else {
        setFirstCurrentMonth(new Date(nextDate));
      }
    }
  };

  const handleClickSetButton = () => {
    dispatch(closeSearchModal());
  };

  const handleResetClick = () => {
    dispatch(setCheckIn(null));
    dispatch(setDeparture(null));
  };

  useEffect(() => {
    if (handleExternal) {
      handleExternal(selectedDay);
    }
  }, [selectedDay]);

  useEffect(() => {
    setPrevDate(
      new Date(
        firstCurrentMonth.getFullYear(),
        firstCurrentMonth.getMonth() - 1,
        firstCurrentMonth.getDate(),
      ),
    );
    setNextDate(
      new Date(
        firstCurrentMonth.getFullYear(),
        firstCurrentMonth.getMonth() + 1,
        firstCurrentMonth.getDate(),
      ),
    );
  }, [firstCurrentMonth]);

  useEffect(() => {
    const calendarDate = new Date();
    if (miniCalendar) {
      dispatch(
        setMinDate(
          new Date(
            calendarDate.getFullYear() - 100,
            calendarDate.getMonth(),
            calendarDate.getDate(),
          ).toString(),
        ),
      );
      dispatch(
        setMaxDate(
          new Date(
            calendarDate.getFullYear() - 14,
            calendarDate.getMonth(),
            calendarDate.getDate(),
          ).toString(),
        ),
      );
      if (passportDate && miniCalendar) {
        dispatch(
          setMinDate(
            new Date(
              calendarDate.getFullYear() - 100,
              calendarDate.getMonth(),
              calendarDate.getDate(),
            ).toString(),
          ),
        );
        dispatch(
          setMaxDate(
            new Date(
              calendarDate.getFullYear(),
              calendarDate.getMonth(),
              calendarDate.getDate(),
            ).toString(),
          ),
        );
      }
    } else {
      dispatch(
        setMinDate(
          new Date(
            calendarDate.getFullYear(),
            calendarDate.getMonth(),
            calendarDate.getDate(),
          ).toString(),
        ),
      );
      dispatch(
        setMaxDate(
          new Date(
            calendarDate.getFullYear() + 1,
            calendarDate.getMonth(),
            calendarDate.getDate(),
          ).toString(),
        ),
      );
    }
  }, [miniCalendar]);

  useEffect(() => {
    if (calendarSelected) {
      if (calendarSelected.checkIn) {
        setCheckIn(calendarSelected.checkIn.toString() || null);
      }
      if (calendarSelected.departure) {
        setDeparture(calendarSelected.departure.toString() || null);
      }
    }
  }, []);

  useEffect(() => {
    setNextCurrentMonth(
      new Date(
        firstCurrentMonth.getFullYear(),
        firstCurrentMonth.getMonth() + 1,
        firstCurrentMonth.getDate(),
      ),
    );
  }, [firstCurrentMonth]);

  return (
    <div className={`${calendarContainer()} ${externalClassName}`}>
      {!miniCalendar &&
        !bookingPanelCalendar &&
        windowWidth < 960 &&
        calendarSelected?.checkIn &&
        calendarSelected?.departure && (
          <Button
            text="Выбрать"
            handleExternal={handleClickSetButton}
            externalClassName={calendarContainer('choose')}
          />
        )}
      {/* eslint-disable-next-line */}
      {miniCalendar ? (
        <div className={calendarContainer('calendar')}>
          <>
            <button
              type="button"
              className={calendarContainer('button-arrow-left', {
                disabled: prevDate && minDate && prevDate < minDate,
              })}
              onClick={handlePrevClick}>
              <Icon externalClassName={calendarContainer('icon')} iconName="arrow_left" />
            </button>
            <Calendar
              miniCalendar
              calendarDate={firstCurrentMonth}
              setCalendarDate={setFirstCurrentMonth}
              setSelectedDay={setSelectedDay}
              selectedDay={selectedDay}
              passportDate={passportDate}
            />
            <button
              type="button"
              className={calendarContainer('button-arrow-right', {
                disabled: maxDate && nextDate && maxDate < nextDate,
              })}
              onClick={handleNextClick}>
              <Icon externalClassName={calendarContainer('icon')} iconName="arrow_right" />
            </button>
          </>
        </div>
      ) : !bookingPanelCalendar ? (
        <div className={calendarContainer('calendar')}>
          {windowWidth && windowWidth < 601 ? (
            getMobailCalendars(date).map((dateMobail, id) => {
              return <Calendar calendarDate={dateMobail} key={id} />;
            })
          ) : (
            <>
              <button
                type="button"
                className={calendarContainer('button-arrow-left', {
                  disabled: prevDate && minDate && prevDate < minDate,
                  mobail: windowWidth < 600,
                })}
                onClick={handlePrevClick}>
                <Icon externalClassName={calendarContainer('icon')} iconName="arrow_left" />
              </button>
              <Calendar calendarDate={firstCurrentMonth} />
              <Calendar calendarDate={nextCurrentMonth} />
              <button
                type="button"
                className={calendarContainer('button-arrow-right', {
                  disabled: maxDate && nextDate && maxDate < nextDate,
                  mobail: windowWidth < 600,
                })}
                onClick={handleNextClick}>
                <Icon externalClassName={calendarContainer('icon')} iconName="arrow_right" />
              </button>
            </>
          )}
        </div>
      ) : (
        <div className={calendarContainer('calendar')}>
          <>
            <button
              type="button"
              className={calendarContainer('button-arrow-left', {
                disabled: prevDate && minDate && prevDate < minDate,
              })}
              onClick={handlePrevClick}>
              <Icon externalClassName={calendarContainer('icon')} iconName="arrow_left" />
            </button>
            <Calendar bookingPanelCalendar calendarDate={firstCurrentMonth} />
            <button
              type="button"
              className={calendarContainer('button-arrow-right', {
                disabled: maxDate && nextDate && maxDate < nextDate,
              })}
              onClick={handleNextClick}>
              <Icon externalClassName={calendarContainer('icon')} iconName="arrow_right" />
            </button>
          </>
        </div>
      )}
      {!miniCalendar && !bookingPanelCalendar && (
        <div className={calendarContainer('mobile-header')}>
          {windowWidth < 960 && (
            <div className={calendarContainer('selected-date')}>
              <div className={calendarContainer('choice')}>{formattedDate}</div>
              {windowWidth < 960 && (
                <button
                  type="button"
                  className={calendarContainer('button-reset', {
                    active: calendarSelected !== null,
                  })}
                  onClick={handleResetClick}>
                  Сбросить
                </button>
              )}
            </div>
          )}

          <div className={calendarContainer('button-group')}>
            <AddDays />
            {windowWidth >= 960 && (
              <button
                type="button"
                className={calendarContainer('button-reset')}
                onClick={handleResetClick}>
                Сбросить выбор
              </button>
            )}
          </div>

          {windowWidth < 600 && !bookingPanelCalendar && <WeekDays />}
        </div>
      )}
    </div>
  );
};

export default CalendarContainer;
