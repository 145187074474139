import { Link } from 'react-router-dom';

import bemClassName from '../../utils/bem';

import logoPNG from '../../assets/images/logo.png';
import logoFooterPNG from '../../assets/images/logo_footer.png';

import './index.scss';

const logo = bemClassName('logo');

interface ILogoProps {
  footer?: boolean;
}

const Logo: React.FC<ILogoProps> = ({ footer }) => {
  return (
    <Link className={logo()} to="/">
      {footer ? (
        <img className={logo('image', { footer })} src={logoFooterPNG} alt="Логотип" />
      ) : (
        <img className={logo('image')} src={logoPNG} alt="Логотип" />
      )}
    </Link>
  );
};

export default Logo;
