import { useEffect, useState } from 'react';

import { IRadioButtons } from './interface';
import bemClassName from '../../utils/bem';

import './index.scss';
import Icon from '../Icon';

const radioButtons = bemClassName('radio-buttons');

const RadioButtons: React.FC<IRadioButtons> = ({
  data,
  externalClassName = '',
  handleExternal,
  name,
  title,
  valueExternal,
  arrow,
  pay,
}) => {
  const [value, setValue] = useState<string | null>(valueExternal ?? null);

  const handleChange = (radioValue: string) => {
    setValue(radioValue);
    if (handleExternal) {
      handleExternal(radioValue);
    }
  };

  useEffect(() => {
    if (valueExternal) {
      setValue(valueExternal);
    }
  }, [valueExternal]);

  return (
    <div className={`${radioButtons()} ${externalClassName}`}>
      <p className={radioButtons('title')}>{title}</p>
      {data.map((item, id) => {
        const uniqueId = `${name}-${id}`;
        return (
          <label
            htmlFor={uniqueId}
            className={radioButtons('label', {
              select: pay && (value === item.value || valueExternal === item.value),
            })}
            key={id}>
            <input
              className={radioButtons('hiden')}
              type="radio"
              name={name}
              id={uniqueId}
              onChange={() => handleChange(item.value)}
              checked={value === item.value || valueExternal === item.value}
            />
            {!arrow && (
              <span
                className={radioButtons('custom', {
                  checked: value === item.value,
                })}
                aria-hidden="true"
              />
            )}
            {arrow && (
              <span
                className={radioButtons('custom-arrow', { checked: value === item.value })}
                aria-hidden="true">
                {value === item.value && (
                  <Icon iconName="check" externalClassName={radioButtons('icon')} />
                )}
              </span>
            )}
            <div className={radioButtons('box')}>
              {arrow && item.img ? (
                <img src={item.img} alt="transfer" className={radioButtons('icons')} />
              ) : (
                <p className={radioButtons('icons')} />
              )}
              <p>{item.text}</p>
            </div>
          </label>
        );
      })}
    </div>
  );
};

export default RadioButtons;
