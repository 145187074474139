import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import AppData from '../../../API/AppData';
import { CityDataItem } from '../../../app_interfaces';

export const fetchCItyData = createAsyncThunk('data/fetchCItyData', AppData.getCityData);

export const fetchRoomsData = createAsyncThunk('data/fetchRoomsData', AppData.getRoomsData);

export interface DataState {
  cityData: Array<CityDataItem>;
  roomsData: Array<string>;
}

const initialState: DataState = {
  cityData: [],
  roomsData: [],
};

export const appDataSlice = createSlice({
  name: 'appData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchCItyData.fulfilled,
      (state, action: PayloadAction<Array<CityDataItem>>) => {
        state.cityData = action.payload;
      },
    );
    builder.addCase(fetchRoomsData.fulfilled, (state, action: PayloadAction<Array<string>>) => {
      state.roomsData = action.payload;
    });
  },
});

// export const {} = appDataSlice.actions;

export default appDataSlice.reducer;
