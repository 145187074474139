import { AUTH_CONFIG, CLIENT, REDIRECT_URI } from '../../app_constants';

const getAuthUrl = () => {
  const authorizationUrl = new URL(AUTH_CONFIG.authorization_endpoint!);
  authorizationUrl.searchParams.set('client_id', CLIENT.client_id);
  authorizationUrl.searchParams.set('redirect_uri', REDIRECT_URI as string);
  authorizationUrl.searchParams.set('response_type', 'code');
  authorizationUrl.searchParams.set('scope', 'openid');

  return authorizationUrl.href;
};

export default getAuthUrl;
