import { configureStore } from '@reduxjs/toolkit';
import windowSlice from './reducers/window/windowSlice';
import footerLinksDataSlice from './reducers/data/footerLinksDataSlice';
import reviewDataSlice from './reducers/data/reviewDataSlice';
import modalMenuSlice from './reducers/modal/modalMenuSlice';
import autorizationStatusSlice from './reducers/profile/autorizationStatutsSlice';
import searchDataSlice from './reducers/search/searchDataSlice';
import modalSearchSlice from './reducers/modal/modalSearchSlice';
import appDataSlice from './reducers/data/appDataSlice';
import catalogDataSlice from './reducers/data/catalogDataSlice';
import favouritesSlice from './reducers/profile/favouritesSlice';
import emailValidateSlice from './reducers/validate/emailValidateSlice';
import phoneValidateSlice from './reducers/validate/phoneValidateSlice';
import personalDataSlice from './reducers/profile/personalDataSlice';
import passportDataSlice from './reducers/profile/passportDataSlice';
import calendarDataSlice from './reducers/calendar/calendarDataSlice';
import otherFiltersDataSlice from './reducers/search/otherFiltersDataSlice';
import modalMapSlice from './reducers/modal/modalMapSlice';
import basketDataSlice from './reducers/data/basketDataSlice';
import contactDataSlice from './reducers/data/contactDataSlice';

export const store = configureStore({
  reducer: {
    footerLinksData: footerLinksDataSlice,
    reviewData: reviewDataSlice,
    window: windowSlice,
    modalMenu: modalMenuSlice,
    autorizationStatus: autorizationStatusSlice,
    searchData: searchDataSlice,
    modalSearch: modalSearchSlice,
    appData: appDataSlice,
    catalogData: catalogDataSlice,
    favoritesData: favouritesSlice,
    emailValidate: emailValidateSlice,
    phoneValidate: phoneValidateSlice,
    personalData: personalDataSlice,
    passportData: passportDataSlice,
    calendarData: calendarDataSlice,
    otherFiltersData: otherFiltersDataSlice,
    mapModal: modalMapSlice,
    basketData: basketDataSlice,
    contactData: contactDataSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
