import React from 'react';

import HistoryBookingItem from '../HistoryBookingItem';

import { IHistoryBookingList } from './interface';
import bemClassName from '../../utils/bem';

import './index.scss';

const historyBookingList = bemClassName('history-booking-list');

const HistoryBookingList: React.FC<IHistoryBookingList> = ({ data }) => {
  return (
    <div className={historyBookingList()}>
      {data.map((item, id) => {
        return <HistoryBookingItem key={id} data={item} />;
      })}
    </div>
  );
};

export default HistoryBookingList;
