import { Link } from 'react-router-dom';
import { IAppLink } from './interface';
import bemClassName from '../../utils/bem';

import './index.scss';

const link = bemClassName('link');

const AppLink: React.FC<IAppLink> = ({ url, text, footer }) => {
  return (
    <Link className={link({ footer })} to={url}>
      {text}
    </Link>
  );
};

export default AppLink;
