import React, { useEffect, useState } from 'react';

import Container from '../../UI/Container';
import ReviewItem from '../../components/ReviewItem';
import ScrollingButtons from '../../UI/ScrollingButtons';
import Pagination from '../../UI/Pagination';

import bemClassName from '../../utils/bem';

import './index.scss';
import formatedDateCommit from '../../utils/reviewsUtils';
import Loading from '../../UI/Loading';
import ReviewData from '../../API/ReviewData';

const reviews = bemClassName('reviews');

const Reviews = () => {
  const [dataReviews, setDataReviews] = useState<any>();
  const [metaReviews, setMetaReviews] = useState<any>();
  const [filterSelectedValue, setFilterSelectedValue] = useState<string>();

  const [pagesReviews, setPagesReviews] = useState<number>(1);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const AbortControllerReviews = new AbortController();

  useEffect(() => {
    setIsLoading(true);
    if (filterSelectedValue && pagesReviews) {
      let filter;
      if (filterSelectedValue) {
        if (filterSelectedValue === 'Сначала новые') {
          filter = 'new';
        }
        if (filterSelectedValue === 'Сначала старые') {
          filter = 'old';
        }
      }
      if (filter !== undefined) {
        ReviewData.getCollectionReviewsDateFilter(
          filter,
          pagesReviews,
          AbortControllerReviews.signal,
        )
          .then((items) => {
            if (items.data) {
              setIsLoading(false);
            }
            setDataReviews(items.data);
            setMetaReviews(items.meta);
          })
          .catch((error) => {
            if (error) {
              setIsLoading(false);
            }
          });
      }
    }
    return () => {
      AbortControllerReviews.abort();
    };
  }, [filterSelectedValue, pagesReviews]);

  useEffect(() => {
    document.title = 'Отзывы';
  }, []);

  return (
    <Container>
      <div className={reviews('inner')}>
        <ScrollingButtons
          data={['Сначала новые', 'Сначала старые']}
          defaultSelected="Сначала новые"
          externalClassName={reviews('sort')}
          handleExternal={(value: string) => setFilterSelectedValue(value)}
        />
        {!isLoading ? (
          <div className={reviews('reviews-list')}>
            {dataReviews &&
              dataReviews.map((item: any, id: number) => {
                return (
                  <ReviewItem
                    key={id}
                    locationLink={`/catalog/${item.city}/${item.product_id}`}
                    photos={item.photos ? item.photos : ''}
                    photos_url={item.photos_url}
                    city={item.city}
                    address={item.title}
                    raiting={item.score}
                    link="/reviews/"
                    name={item.user_name}
                    date={formatedDateCommit(item.created_at)}
                    text={item.text}
                    full
                  />
                );
              })}
          </div>
        ) : (
          <Loading externalClassName={reviews('loading')} />
        )}
        {metaReviews && metaReviews.pages !== 1 && (
          <Pagination
            totalPageCount={metaReviews.pages}
            currentPage={pagesReviews}
            externalClassName={reviews('pagination')}
            handleExternal={(value: number) => setPagesReviews(value)}
          />
        )}
      </div>
    </Container>
  );
};

export default Reviews;
