import { useDispatch } from 'react-redux';
// import { RootState } from '../../store';
import { setCitySelected } from '../../store/reducers/search/searchDataSlice';
import { closeSearchModal } from '../../store/reducers/modal/modalSearchSlice';

import Icon from '../../UI/Icon';
import Guests from '../Guests';
import Rooms from '../Rooms';

import { CALENDAR, CITY, GUESTS, ROOMS } from '../../app_constants';
import { CityDataItem } from '../../app_interfaces';
import CalendarContainer from '../../UI/AppCalendar';
import { ISearchModal } from './interface';
import bemClassName from '../../utils/bem';

import './index.scss';

const searchModal = bemClassName('search-modal');

const SearchModal: React.FC<ISearchModal> = ({ data, type, calendarMini }) => {
  const dispatch = useDispatch();
  // const typeOpenSearchModal = useSelector(
  //   (state: RootState) => state.modalSearch.typeOpenSearchModal
  // );

  const handleClickCityItem = (event: React.MouseEvent, item: CityDataItem) => {
    event.stopPropagation();
    dispatch(setCitySelected(item));
    dispatch(closeSearchModal());
  };

  const hendleWrapperClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (type !== CITY) {
      dispatch(closeSearchModal());
    }
  };

  return (
    <>
      {type === CITY ? (
        data &&
        data.length > 0 && (
          <div
            className={searchModal({
              open: true,
              // open: type === typeOpenSearchModal,
              city: true,
            })}>
            <div className={searchModal('title')}>
              <Icon
                externalClassName={searchModal('arrow')}
                iconName="arrow"
                handleClick={() => dispatch(closeSearchModal())}
              />
              Город и район
            </div>
            <div className={searchModal('list')}>
              {data.map((item, id) => {
                return (
                  <p
                    className={searchModal('item')}
                    key={id}
                    onClick={(event) => handleClickCityItem(event, item)}>
                    {item.name}
                  </p>
                );
              })}
            </div>
          </div>
        )
      ) : (
        <div
          className={searchModal({
            open: true,
            // open: type === typeOpenSearchModal,
            calendar: type === CALENDAR && !calendarMini,
            calendarMini,
          })}>
          <div
            className={searchModal('inner', {
              calendar: !!(type === CALENDAR),
            })}>
            <div className={searchModal('swipe-button')} />
            {type === GUESTS && <Guests />}
            {type === ROOMS && <Rooms />}
            {calendarMini
              ? type === CALENDAR && <CalendarContainer bookingPanelCalendar />
              : type === CALENDAR && <CalendarContainer />}
          </div>
          <div className={searchModal('wrapper')} onClick={(event) => hendleWrapperClick(event)} />
        </div>
      )}
    </>
  );
};

export default SearchModal;
