import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store';
import { closeSearchModal } from '../../store/reducers/modal/modalSearchSlice';

import SearchField from '../SearchField';
import Button from '../../UI/Button';
import Checkbox from '../../UI/Checkbox';
import AppInput from '../../UI/AppInput';
import Modal from '../../UI/Modal';

import { CALENDAR, GUESTS, TEL } from '../../app_constants';
import bemClassName from '../../utils/bem';
import { IBookigPanel } from './interface';

import './index.scss';
import PrivateData from '../../API/PrivateData';
import SearchModal from '../SearchModal';
import {
  formatedBookingDays,
  formatedBookingPanelDateForReservation,
  formatedCalendarDays,
} from '../../utils/calendarDayUtils';
import Loading from '../../UI/Loading';
import {
  bookingStatusPayment,
  bookingStatusTextNotVerified,
  bookingStatusTextVerified,
} from '../../utils/bookingStatusUtils';
import CatalogData from '../../API/CatalodData';
import RadioButtons from '../../UI/RadiouButtons';
import MessageData from '../../API/MessageData';

const bookingPanel = bemClassName('booking-panel');

const BookingPanel: React.FC<IBookigPanel> = ({ side, id, price, cityName, address, load }) => {
  const [isHide, setHide] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [isOpenReservation, setIsOpenReservation] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disableApplyButton, setDisableApplyButton] = useState<boolean>(false);
  const [cashBack, setCashBack] = useState<boolean>(false);
  const [apartmentAvailable, setApartmentAvailable] = useState<boolean>(true);

  const [openModalCancellation, setOpenModalCancellation] = useState<boolean>(false);
  const [disabledButtonCancellation, setDisabledButtonCancellation] = useState<boolean>(false);

  const [causeCancellation, setCauseCancellation] = useState<string>();

  const [bookingPhoneNumber, setBookingPhoneNumber] = useState<string>();
  const [totalBasePrice, setTotalBasePrice] = useState<number | null>();
  const [allSelectedDays, setAllSelectedDays] = useState<number>();
  const [bonus, setBonus] = useState<number>();
  const [discount, setDiscount] = useState<number>();
  const [priceIncludingDiscount, setPriceIncludingDiscount] = useState<number>();
  const [discountSum, setDiscountSum] = useState<number>();
  const [statusBooking, setStatusBooking] = useState<any>();
  const [reservBeginDate, setReservBeginDate] = useState<string>();
  const [reservEndDate, setReservEndDate] = useState<string>();
  const [userDealsId, setUserDealsId] = useState<string>();

  const guests = useSelector((state: RootState) => state.searchData.guests);
  const isLogin = useSelector((state: RootState) => state.autorizationStatus.isLogin);
  const isPaswordVerified = useSelector(
    (state: RootState) => state.autorizationStatus.isPaswordVerified,
  );
  const calendarSelected = useSelector((state: RootState) => state.searchData.calendarSelected);

  const typeOpenSearchModal = useSelector(
    (state: RootState) => state.modalSearch.typeOpenSearchModal,
  );

  const bookingFieldsList = useRef<HTMLInputElement>(null);
  const searchFieldsListCalendar = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const handleCauseCancellationValue = (value: string) => {
    if (value) {
      setDisabledButtonCancellation(true);
    }
    setCauseCancellation(value);
  };

  const handleCloseModalCancellation = () => {
    setOpenModalCancellation(false);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModalReservation = () => {
    setIsOpenReservation(false);
  };

  const handleOpenModalReservation = () => {
    setIsOpenReservation(true);
  };

  const deletUserBookingDeals = () => {
    if (userDealsId && causeCancellation) {
      setDisabledButtonCancellation(false);
      PrivateData.deleteUserDeals(userDealsId, Number(causeCancellation)).then(() => {
        setDisabledButtonCancellation(true);
        setOpenModalCancellation(false);
      });
    }
  };
  const addingOrForwardingChat = () => {
    if (id) {
      load(true);
      MessageData.getCreatedСhat(id).then((item) => {
        if (item && item.chat_id) {
          load(false);
          window.location.href = `/account#messages_${item.chat_id}`;
        }
      });
    }
  };

  const handleApplyForReservation = () => {
    if (
      id &&
      calendarSelected &&
      calendarSelected.checkIn &&
      calendarSelected.departure &&
      guests
    ) {
      setDisableApplyButton(true);
      PrivateData.getPrivateUserApartmentReservation(
        id,
        formatedBookingPanelDateForReservation(calendarSelected.checkIn),
        formatedBookingPanelDateForReservation(calendarSelected.departure),
        guests[0],
        guests[1],
      ).then((item) => {
        handleCloseModalReservation();
        setDisableApplyButton(false);
        setStatusBooking(item.status);
        setUserDealsId(item.id);
        setReservBeginDate(item.reserve_begin);
        setReservEndDate(item.reserve_end);
      });
    }
  };

  useEffect(() => {
    if (isLogin !== null && isLogin) {
      if (id && calendarSelected && calendarSelected.checkIn && calendarSelected.departure) {
        setIsLoading(true);
        CatalogData.getApartament({
          apartamentId: id,
          begin: formatedCalendarDays(calendarSelected.checkIn),
          end: formatedCalendarDays(calendarSelected.departure),
          cashback: cashBack,
        }).then((item) => {
          setIsLoading(false);
          setTotalBasePrice(item.base_total);
          setBonus(item.bonus);
          setDiscount(item.discount);
          setPriceIncludingDiscount(item.total);
          setDiscountSum(item.discount_summ);
          setAllSelectedDays(item.duration);
          setApartmentAvailable(item.free);
        });
      }
    }
  }, [id, isLogin, cashBack, calendarSelected]);

  useEffect(() => {
    if (isLogin !== null && isLogin) {
      if (
        calendarSelected &&
        calendarSelected.checkIn &&
        calendarSelected.departure &&
        guests &&
        guests.length > 0
      ) {
        setDisabledButton(true);
      } else {
        setDisabledButton(false);
      }
    } else if (isLogin !== null && !isLogin) {
      if (
        calendarSelected &&
        calendarSelected.checkIn &&
        calendarSelected.departure &&
        guests &&
        guests.length > 0 &&
        bookingPhoneNumber &&
        bookingPhoneNumber.length > 16
      ) {
        setDisabledButton(true);
      } else {
        setDisabledButton(false);
      }
    }
  }, [calendarSelected, guests, isLogin, bookingPhoneNumber]);

  useEffect(() => {
    if (id && isLogin !== null && isLogin) {
      PrivateData.getPrivateUserDealStatusBooking(id)
        .then((item) => {
          if (item.data.length > 0) {
            setStatusBooking(item.data.find((items: any) => items.status).status);
            setReservBeginDate(item.data.find((items: any) => items.reserve_begin).reserve_begin);
            setReservEndDate(item.data.find((items: any) => items.reserve_end).reserve_end);
            setUserDealsId(item.data.find((items: any) => items.id).id);
          }
        })
        .catch(() => {
          return null;
        });
    }
  }, [isLogin, id]);

  useEffect(() => {
    if (isLogin !== null && isLogin) {
      PrivateData.getPrivateUser().then((item) => {
        if (item.phone.international && item.phone.international !== null) {
          setBookingPhoneNumber(item.phone.international);
        }
      });
    }
  }, [isLogin]);

  useEffect(() => {
    if (calendarSelected && calendarSelected.checkIn && calendarSelected.departure) {
      dispatch(closeSearchModal());
    }
  }, [calendarSelected]);

  useEffect(() => {
    const onClick = (event: any) =>
      bookingFieldsList.current?.contains(event.target) ||
      searchFieldsListCalendar.current?.contains(event.target) ||
      dispatch(closeSearchModal());
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  return !statusBooking ? (
    <>
      <div className={bookingPanel({ side, hide: !side && isHide })}>
        {side || !isHide ? (
          <>
            <div ref={bookingFieldsList} className={bookingPanel('fields-list')}>
              <SearchField
                title="Заезд"
                placeholder="Заезд"
                textValue={calendarSelected === null ? calendarSelected : calendarSelected.checkIn}
                type={CALENDAR}
                booking
                calendarMini
              />
              <SearchField
                title="Отъезд"
                placeholder="Отъезд"
                textValue={
                  calendarSelected === null ? calendarSelected : calendarSelected.departure
                }
                type={CALENDAR}
                booking
                calendarMini
              />
              <SearchField
                title="Гости"
                placeholder="Гости"
                textValue={guests}
                type={GUESTS}
                booking
              />
              {typeOpenSearchModal === CALENDAR && (
                <SearchModal calendarMini type={CALENDAR} data={[]} />
              )}
            </div>
            {isLoading && <Loading externalClassName={bookingPanel('loading')} />}
            {!isLoading && totalBasePrice
              ? apartmentAvailable && (
                  <>
                    <p className={bookingPanel('price', { bonus: true })}>
                      Бонусные баллы <span>+{bonus}</span>
                    </p>
                    <div className={bookingPanel('group')}>
                      {!cashBack && (
                        <p className={bookingPanel('price')}>
                          Скидка <span>{discountSum} ₽</span>
                        </p>
                      )}
                      <h4 className={bookingPanel('price')}>
                        Итого <span>{!cashBack ? priceIncludingDiscount : totalBasePrice} ₽</span>
                      </h4>
                      {/* {!cashBack && ( */}
                      <p
                        className={bookingPanel('text', { show_modal: true })}
                        onClick={handleOpenModal}>
                        Показать расчёт цены
                      </p>
                      {/* )} */}
                    </div>
                  </>
                )
              : !isLoading && (
                  <h4 className={bookingPanel('price')}>
                    Цена за сутки <span>{price} ₽</span>
                  </h4>
                )}
            {/* {!isLogin && ( */}
            <AppInput
              inputId="phoneNumberForReservation"
              type={TEL}
              text="Номер телефона для бронирования"
              validate="Такого номера не существует"
              externalClassName={bookingPanel('phone')}
              valueExternal={bookingPhoneNumber}
              handleExternal={(value: any) => setBookingPhoneNumber(value)}
            />
            {/* )} */}
            {apartmentAvailable && (
              <div className={bookingPanel('group')}>
                <Button
                  handleExternal={handleOpenModalReservation}
                  text="Забронировать"
                  externalClassName={bookingPanel('button')}
                  disable={!disabledButton}
                />
                <p className={bookingPanel('text', { center: true })}>
                  Подавая заявку на бронирование, Вы соглашаетесь{' '}
                  <Link to="#t">с правилами нашего сервиса</Link>
                </p>
              </div>
            )}
            {apartmentAvailable && (
              <Checkbox
                text="Получить скидку в виде кешбека"
                externalClassName={bookingPanel('checkbox')}
                handleExternal={(value: boolean) => setCashBack(value)}
                checked={cashBack}
              />
            )}
            {!apartmentAvailable && apartmentAvailable !== undefined && !isLoading && (
              <div className={bookingPanel('date-error-info')}>
                На указанные даты объект уже занят. Попробуйте выбрать другие даты.
              </div>
            )}
          </>
        ) : (
          <>
            <Button
              text="Бронирование"
              externalClassName={bookingPanel('button')}
              handleExternal={() => setHide(false)}
            />
          </>
        )}
      </div>
      <Modal open={isOpen} handleClose={handleCloseModal}>
        <h3>Расчёт цены</h3>
        <div className={bookingPanel('price-calculation')}>
          <h4 className={bookingPanel('price-calculation-text')}>
            <span className={bookingPanel('price-calculation-title')}>Цена квартиры</span>
            {price} ₽
          </h4>
          <h4 className={bookingPanel('price-calculation-text')}>x</h4>
          <h4 className={bookingPanel('price-calculation-text')}>
            <span className={bookingPanel('price-calculation-title')}>Количество дней</span>
            {allSelectedDays}
          </h4>
          <h4 className={bookingPanel('price-calculation-text')}>=</h4>
          <h4 className={bookingPanel('price-calculation-text')}>
            <span className={bookingPanel('price-calculation-title')}>Общая сумма</span>
            {totalBasePrice} ₽
          </h4>
        </div>
        {discountSum !== 0 && (
          <div className={bookingPanel('price-calculation')}>
            <h4 className={bookingPanel('price-calculation-text')}>
              <span className={bookingPanel('price-calculation-title')}>Общая сумма</span>
              {totalBasePrice} ₽
            </h4>
            {discount === 1 ? (
              <h4 className={bookingPanel('price-calculation-text')}>-</h4>
            ) : (
              <h4 className={bookingPanel('price-calculation-text')}>x</h4>
            )}
            {discount === 1 && discountSum ? (
              <h4 className={bookingPanel('price-calculation-text')}>
                <span className={bookingPanel('price-calculation-title')}>Скидка</span>
                {discountSum * -1}
              </h4>
            ) : (
              <h4 className={bookingPanel('price-calculation-text')}>
                <span className={bookingPanel('price-calculation-title')}>Скидка</span>
                {discount}
              </h4>
            )}
            <h4 className={bookingPanel('price-calculation-text')}>=</h4>

            <h4 className={bookingPanel('price-calculation-text')}>
              <span className={bookingPanel('price-calculation-title')}>Сумма со скидкой</span>
              {priceIncludingDiscount} ₽
            </h4>
          </div>
        )}
        <Button text="Ок" handleExternal={handleCloseModal} />
      </Modal>
      <Modal open={isOpenReservation} handleClose={handleCloseModalReservation}>
        <h3>Подача заявки на бронирование</h3>
        <div>
          {calendarSelected && calendarSelected.checkIn && calendarSelected.departure && (
            <p>{formatedBookingDays(calendarSelected.checkIn, calendarSelected.departure)}</p>
          )}
          <p>
            {address} в г. {cityName}
          </p>
        </div>
        <div className={bookingPanel('reservation-buttons')}>
          <Button
            // externalClassName={bookingPanel('cancel-button')}
            text="Отменить"
            handleExternal={handleCloseModalReservation}
            red
          />
          <Button
            // externalClassName={bookingPanel('submit-button')}
            text="Подать заявку"
            handleExternal={() => handleApplyForReservation()}
            disable={disableApplyButton}
            transparent={isPaswordVerified}
          />
          {isPaswordVerified && (
            <Button externalClassName={bookingPanel('payment')} text="Внести предоплату" />
          )}
        </div>
      </Modal>
    </>
  ) : (
    <div className={bookingPanel({ side, hide: !side && isHide })}>
      {/* eslint-disable-next-line */}
      {side || !isHide ? (
        !isPaswordVerified ? (
          <>
            {statusBooking && (
              <h4 className={bookingPanel('header-contacted')}>
                {bookingStatusTextNotVerified(statusBooking)}
              </h4>
            )}
            <p className={bookingPanel('info')}>
              Для того, чтобы моментально бронировать квартиру без подтверждения необходимо{' '}
              <Link to="/account">подтвердить свою личность в личном кабинете.</Link>
            </p>
            <p className={bookingPanel('password-info')}>
              Пароль от личного кабинета выслан Вам в СМС на указанный номер.
            </p>
            <p className={bookingPanel('refresh-code')}>Запросить код еще раз</p>
          </>
        ) : (
          <>
            {statusBooking && (
              <h4
                className={bookingPanel('header-contacted-status', {
                  status:
                    statusBooking === 'Внесена предоплата' || statusBooking === 'Полная оплата',
                })}>
                {bookingStatusTextVerified(statusBooking)}
              </h4>
            )}
            <div className={bookingPanel('container-info-verifed')}>
              <p className={bookingPanel('verifed-placholder')}>Дата бронирования</p>
              {reservBeginDate && reservEndDate && (
                <p className={bookingPanel('verifed-content')}>
                  {formatedBookingDays(reservBeginDate, reservEndDate, true)}
                </p>
              )}
            </div>
            <div className={bookingPanel('container-info-verifed')}>
              <p className={bookingPanel('verifed-placholder')}>Статус бронирования</p>
              {statusBooking && (
                <p className={bookingPanel('verifed-content')}>
                  {bookingStatusPayment(statusBooking)}
                </p>
              )}
            </div>
            <div className={bookingPanel('reservation-button-group')}>
              {(statusBooking === 'Предварительное бронирование' ||
                statusBooking === 'Бронирование') && (
                <Button
                  externalClassName={bookingPanel('payment', { payment_reservation: true })}
                  text="Внести предоплату"
                />
              )}
              <Button
                handleExternal={() => addingOrForwardingChat()}
                externalClassName={bookingPanel('message')}
                text="Сообщения"
              />
              {(statusBooking === 'Предварительное бронирование' ||
                statusBooking === 'Бронирование') && (
                <Button
                  externalClassName={bookingPanel('cancel-button', { cancle_reservation: true })}
                  text="Отменить бронирование"
                  // handleExternal={() => deletUserBookingDeals()}
                  handleExternal={() => setOpenModalCancellation(true)}
                  red
                />
              )}
              <Modal open={openModalCancellation} handleClose={handleCloseModalCancellation}>
                <h3 className={bookingPanel('modal-header')}>Выберите причину отмены брони</h3>
                {id && reservBeginDate && reservEndDate && (
                  <RadioButtons
                    data={[
                      { text: 'Изменились обстоятельства', value: 2 },
                      { text: 'Нашел дешевле', value: 6 },
                      { text: 'Отменилась командировка', value: 1 },
                      { text: 'Другое', value: 11 },
                    ]}
                    name={`causeCancellation_${id}_${reservBeginDate}_${reservEndDate}`}
                    externalClassName={bookingPanel('field')}
                    handleExternal={handleCauseCancellationValue}
                    valueExternal={causeCancellation}
                  />
                )}
                <div className={bookingPanel('modal-button')}>
                  <Button
                    text="Закрыть"
                    handleExternal={() => handleCloseModalCancellation()}
                    red
                  />
                  <Button
                    text="Отменить бронирование"
                    handleExternal={() => deletUserBookingDeals()}
                    disable={!disabledButtonCancellation}
                    typeButton="submit"
                  />
                </div>
              </Modal>
            </div>
          </>
        )
      ) : (
        <>
          <Button
            text="Бронирование"
            externalClassName={bookingPanel('button')}
            handleExternal={() => setHide(false)}
          />
        </>
      )}
    </div>
  );
};

export default BookingPanel;
