import { useState, useEffect } from 'react';

import Icon from '../Icon';

import { ICounter } from './interface';
import bemClassName from '../../utils/bem';

import './index.scss';

const counter = bemClassName('counter');

const Counter: React.FC<ICounter> = ({
  value,
  handleExternal,
  minValue = 0,
  maxValue,
  text,
  note,
}) => {
  const [count, setCount] = useState<number | null>(null);

  const handleClickIncrement = () => {
    if (maxValue) {
      if (count !== null && count < maxValue) {
        setCount((prevState) => (prevState !== null ? prevState + 1 : 0));
      }
    } else {
      setCount((prevState) => (prevState !== null ? prevState + 1 : 0));
    }
  };
  const handleClickDecrement = () => {
    if (count !== null && count > minValue) {
      setCount((prevState) => (prevState !== null ? prevState - 1 : 0));
    }
  };

  useEffect(() => {
    if (handleExternal && count !== null) {
      handleExternal(count);
    }
  }, [count]);

  useEffect(() => {
    setCount(value);
  }, [value]);

  return (
    <div className={counter()}>
      <p className={counter('text')}>
        {text}
        {note && <span className={counter('note')}>{note}</span>}
      </p>
      <div className={counter('field')}>
        <span
          onClick={handleClickDecrement}
          className={counter('button', { disable: count === minValue })}>
          <Icon iconName="minus" externalClassName={counter('icon')} />
        </span>
        {count}
        <span
          onClick={handleClickIncrement}
          className={counter('button', { disable: !!(count === maxValue) })}>
          <Icon iconName="plus" externalClassName={counter('icon')} />
        </span>
      </div>
    </div>
  );
};

export default Counter;
