import { ReactNode, useEffect, useState } from 'react';
import parse from 'html-react-parser';

import bemClassName from '../../utils/bem';

import './index.scss';
import PrivateData from '../../API/PrivateData';
import Loading from '../../UI/Loading';

const bonusPoints = bemClassName('bonus-points');

const BonusPoints = () => {
  const [bonusValue, setBonusValue] = useState<number | null>();

  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  let parsedHTML;
  if (data) {
    parsedHTML = parse(data.content);
  }

  useEffect(() => {
    document.title = 'Личные данные';
    setIsLoading(true);

    PrivateData.getPrivateUser().then((item) => {
      if (item.bonus !== null) {
        setBonusValue(item.bonus);
      } else {
        setBonusValue(0);
      }
    });

    PrivateData.getBonusInformation()
      .then((items) => {
        setData(items);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, []);

  return isLoading ? (
    <Loading externalClassName={bonusPoints('loading')} />
  ) : (
    <>
      <div className={bonusPoints('title')}>
        {bonusValue !== undefined && (
          <div className={bonusPoints('info')}>
            <p className={bonusPoints('info-text')}>Доступно бонусных баллов</p>
            <p className={bonusPoints('info-count')}>{bonusValue} ₽</p>
          </div>
        )}
        {data && <h2>{data.title}</h2>}
      </div>
      <div className={bonusPoints('text')}>
        {parsedHTML as ReactNode}
        {/* <h4 className={bonusPoints('text-title')}>Программа “какая-то”</h4>
        <p>
          Однозначно, предприниматели в сети интернет освещают чрезвычайно интересные особенности
          картины в целом, однако конкретные выводы, разумеется, объединены в целые кластеры себе
          подобных. Как уже неоднократно упомянуто, стремящиеся вытеснить традиционное производство,
          нанотехнологии, инициированные исключительно синтетически, обнародованы. А также
          стремящиеся вытеснить традиционное производство, нанотехнологии своевременно
          верифицированы. И нет сомнений, что акционеры крупнейших компаний могут быть ассоциативно
          распределены по отраслям. Однозначно, акционеры крупнейших компаний представляют собой не
          что иное, как квинтэссенцию победы маркетинга над разумом и должны быть ограничены
          исключительно образом мышления. Не следует, однако, забывать, что экономическая повестка
          сегодняшнего дня позволяет выполнить важные задания по разработке новых предложений. В
          своём стремлении повысить качество жизни, они забывают, что высокотехнологичная концепция
          общественного уклада создаёт предпосылки для переосмысления внешнеэкономических политик.
        </p>
        <a href="#t">Перейти на сайт</a>
      </div>
      <div className={bonusPoints('text')}>
        <h4 className={bonusPoints('text-title')}>Другие программа</h4>
        <p>
          Картельные сговоры не допускают ситуации, при которой непосредственные участники
          технического прогресса, инициированные исключительно синтетически, описаны максимально
          подробно. Прежде всего, разбавленное изрядной долей эмпатии, рациональное мышление
          представляет собой интересный эксперимент проверки соответствующих условий активизации.
          Кстати, активно развивающиеся страны третьего мира формируют глобальную экономическую сеть
          и при этом — своевременно верифицированы.{' '}
        </p>
        <a href="#t">Перейти на сайт</a>
      </div>
      <div className={bonusPoints('text')}>
        <h4 className={bonusPoints('text-title')}>Программа “Хочу сейчас”</h4>
        <p>
          В рамках спецификации современных стандартов, ключевые особенности структуры проекта,
          инициированные исключительно синтетически, объединены в целые кластеры себе подобных.
          Современные технологии достигли такого уровня, что сложившаяся структура организации
          влечет за собой процесс внедрения и модернизации вывода текущих активов. Являясь всего
          лишь частью общей картины, базовые сценарии поведения пользователей формируют глобальную
          экономическую сеть и при этом — превращены в посмешище, хотя само их существование
          приносит несомненную пользу обществу. В частности, укрепление и развитие внутренней
          структуры является качественно новой ступенью переосмысления внешнеэкономических политик.
          В своём стремлении улучшить пользовательский опыт мы упускаем, что многие известные
          личности и по сей день остаются уделом либералов, которые жаждут быть описаны максимально
          подробно.
        </p>
        <a href="#t">Перейти на сайт</a> */}
      </div>
    </>
  );
};

export default BonusPoints;
