import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import bemClassName from '../../../utils/bem';
import './index.scss';
import { CalendarProps } from './interface';
import WeekDays from '../WeekTitle';
import Week from '../Week';
import Month from '../Month';

const calendar = bemClassName('calendar');

const Calendar: React.FC<CalendarProps> = ({
  calendarDate,
  miniCalendar,
  setCalendarDate,
  setSelectedDay,
  selectedDay,
  bookingPanelCalendar,
  passportDate,
}) => {
  const windowWidth = useSelector((state: RootState) => state.window.windowWidth);

  return (
    <div className={calendar()}>
      {miniCalendar ? (
        <Month
          miniCalendar
          setCalendarDate={setCalendarDate}
          calendarDate={calendarDate}
          passportDate={passportDate}
        />
      ) : (
        <Month setCalendarDate={setCalendarDate} calendarDate={calendarDate} />
      )}

      <div className={calendar('box')}>
        {miniCalendar ? <WeekDays /> : windowWidth >= 600 && !bookingPanelCalendar && <WeekDays />}
        {bookingPanelCalendar && <WeekDays />}
        <div className={calendar('week')}>
          {[0, 1, 2, 3, 4, 5].map((weekNumber) => {
            return miniCalendar ? (
              <Week
                miniCalendar
                key={weekNumber}
                calendarDate={calendarDate}
                weekNumber={weekNumber}
                setSelectedDay={setSelectedDay}
                selectedDay={selectedDay}
              />
            ) : (
              <Week key={weekNumber} calendarDate={calendarDate} weekNumber={weekNumber} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
