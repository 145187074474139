import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams, Link } from 'react-router-dom';
import { RootState } from '../../store';
import {
  setCitySelected,
  setClearAllFilter,
  setClearCrumbsFilter,
  setParams,
  setSliderPriceStartValue,
  setSortSelected,
} from '../../store/reducers/search/searchDataSlice';

import Container from '../../UI/Container';
import CatalogList from '../../components/CatalogList';
import ScrollingButtons from '../../UI/ScrollingButtons';
// import Pagination from "../../UI/Pagination";
import CatalogSearch from '../../components/CatalogSearch';
import MapComponent from '../../components/Map';
import { ICatalogItemData } from '../../components/CatalogItem/interface';
import bemClassName from '../../utils/bem';

import './index.scss';
import CatalogData from '../../API/CatalodData';
import { getSortSelected, toStringFilter } from '../../utils/catalogUtils';
import {
  setClearAllAdditionalFilters,
  setMetaCount,
  setStartSliderAreaValue,
  setStartSliderFloorValue,
} from '../../store/reducers/search/otherFiltersDataSlice';
import Loading from '../../UI/Loading';
import Icon from '../../UI/Icon';

const catalog = bemClassName('catalog');

const Catalog = () => {
  const [items, setItems] = useState<Array<ICatalogItemData>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState<number | null>(null);
  const [metaPage, setMetaPage] = useState<any>(null);
  const [requestFiltresString, setRequestFiltresString] = useState<string | undefined>(undefined);
  const [mapData, setMapData] = useState<Array<ICatalogItemData>>([]);
  const [firstRender, setFirstRender] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const { cityName } = useParams();

  const [clickBackButton, setClickBackButton] = useState<boolean>(false);

  const controller = new AbortController();

  const windowWidth = useSelector((state: RootState) => state.window.windowWidth);
  const citySelected = useSelector((state: RootState) => state.searchData.citySelected);
  const cityData = useSelector((state: RootState) => state.appData.cityData);
  const rooms = useSelector((state: RootState) => state.searchData.rooms);
  const guests = useSelector((state: RootState) => state.searchData.guests);
  const calendarSelected = useSelector((state: RootState) => state.searchData.calendarSelected);
  const priceValue = useSelector((state: RootState) => state.searchData.sliderPriceValue);
  const isContactless = useSelector((state: RootState) => state.searchData.isContactless);
  const isBonus = useSelector((state: RootState) => state.searchData.isBonus);
  const sliderAreaValue = useSelector((state: RootState) => state.otherFiltersData.sliderAreaValue);
  const sliderFloorValue = useSelector(
    (state: RootState) => state.otherFiltersData.sliderFloorValue,
  );
  const bedsValue = useSelector((state: RootState) => state.otherFiltersData.bedsValue);
  const doubleBedsValue = useSelector((state: RootState) => state.otherFiltersData.doubleBedsValue);
  const sortSelected = useSelector((state: RootState) => state.searchData.sortSelected);
  const features = useSelector((state: RootState) => state.otherFiltersData.features);
  const selectedAreas = useSelector((state: RootState) => state.otherFiltersData.selectedAreas);
  const cityCoords = useSelector((state: RootState) => state.searchData.cityCoords);
  const isModalMap = useSelector((state: RootState) => state.mapModal.isModalMap);
  const listWrapper = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const handleSetCurentPage = (value: number) => {
  //   dispatch(setCurrentPage(value));
  // };

  const handleSetSortSelected = (value: string) => dispatch(setSortSelected(value));

  const handleScroll = useCallback(() => {
    if (
      (listWrapper.current &&
        window.innerHeight + window.scrollY <=
          listWrapper.current.offsetHeight - window.innerHeight) ||
      isLoading
    ) {
      return;
    }

    if (page && citySelected) {
      if (page < metaPage.pages) {
        setPage(page + 1);
      }
    }
    // eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    if (citySelected === null && cityName !== undefined && cityData.length > 0) {
      const cityNameSelected = cityData.find((item) => item.name === cityName);
      if (cityNameSelected !== undefined) {
        dispatch(setCitySelected(cityNameSelected));
        setPage((prev) => (prev === null ? 1 : prev));
      }
    }
    // eslint-disable-next-line
  }, [cityData]);

  useEffect(() => {
    if (firstRender) {
      if (!document.body.hasAttribute('style')) {
        document.body.style.overflow = 'hidden';
      }
    } else if (!firstRender) {
      if (document.body.hasAttribute('style')) {
        document.body.removeAttribute('style');
      }
    }
  }, [firstRender]);

  useEffect(() => {
    if (isModalMap) {
      if (!document.body.hasAttribute('style')) {
        document.body.style.overflow = 'hidden';
      }
    } else if (!isModalMap) {
      if (document.body.hasAttribute('style')) {
        document.body.removeAttribute('style');
      }
    }
  }, [isModalMap]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line
  }, [isLoading, page]);

  useEffect(() => {
    let booking: Array<string> = [];
    const allBeds: Array<number> = [];

    let checkInFormated = null;
    let departureFormated = null;

    const params = new URLSearchParams();

    if (rooms) {
      params.set('rooms', rooms.toString());
    }
    if (guests) {
      params.set('guests', guests.toString());
    }

    if (calendarSelected && calendarSelected.checkIn) {
      const checkIn = new Date(calendarSelected.checkIn);
      checkInFormated = `${checkIn.getFullYear()}-${checkIn.getMonth() + 1}-${checkIn.getDate()}`;
      booking.push(checkInFormated);
    }
    if (calendarSelected && calendarSelected.departure) {
      const departure = new Date(calendarSelected.departure);
      departureFormated = `${departure.getFullYear()}-${
        departure.getMonth() + 1
      }-${departure.getDate()}`;
      booking.push(departureFormated);
    }
    if (!calendarSelected && booking.length > 0) {
      booking = [];
    }

    if (checkInFormated && departureFormated) {
      params.set('checkIn', checkInFormated);
      params.set('departure', departureFormated);
    }

    if (priceValue) {
      params.set('price', priceValue.toString());
    }

    if (isContactless) {
      params.set('сontactless', isContactless.toString());
    }

    if (isBonus) {
      params.set('highbonus', isBonus.toString());
    }

    if (doubleBedsValue && doubleBedsValue > 0) {
      allBeds.push(doubleBedsValue);
    }
    if (bedsValue && bedsValue > 0) {
      allBeds.push(bedsValue);
    }
  }, []);

  useEffect(() => {
    let booking: Array<string> = [];
    const allBeds: Array<number> = [];

    let checkInFormated = null;
    let departureFormated = null;

    const params = new URLSearchParams();

    if (rooms) {
      params.set('rooms', rooms.toString());
    }
    if (guests) {
      params.set('guests', guests.toString());
    }

    if (calendarSelected && calendarSelected.checkIn) {
      const checkIn = new Date(calendarSelected.checkIn);
      checkInFormated = `${checkIn.getFullYear()}-${checkIn.getMonth() + 1}-${checkIn.getDate()}`;
      booking.push(checkInFormated);
    }
    if (calendarSelected && calendarSelected.departure) {
      const departure = new Date(calendarSelected.departure);
      departureFormated = `${departure.getFullYear()}-${
        departure.getMonth() + 1
      }-${departure.getDate()}`;
      booking.push(departureFormated);
    }
    if (!calendarSelected && booking.length > 0) {
      booking = [];
    }

    if (checkInFormated && departureFormated) {
      params.set('checkIn', checkInFormated);
      params.set('departure', departureFormated);
    }

    if (priceValue) {
      params.set('price', priceValue.toString());
    }

    if (isContactless) {
      params.set('сontactless', isContactless.toString());
    }

    if (isBonus) {
      params.set('highbonus', isBonus.toString());
    }

    if (doubleBedsValue && doubleBedsValue > 0) {
      allBeds.push(doubleBedsValue);
    }
    if (bedsValue && bedsValue > 0) {
      allBeds.push(bedsValue);
    }

    const filterUrlParams = {
      rooms: rooms ?? undefined,
      guests: guests ?? undefined,
      price: priceValue ?? undefined,
      booking: booking && booking?.length > 1 ? booking : undefined,
      Contactless: isContactless || undefined,
      highbonus: isBonus || undefined,
      floor: sliderFloorValue ?? undefined,
      square: sliderAreaValue ?? undefined,
      beds: allBeds && allBeds.length >= 1 ? allBeds : undefined,
      features:
        features && features.some((item) => item.apiKey && item.apiKey !== '')
          ? features.map((item) => item.apiKey).filter((feature) => feature && feature !== '')
          : undefined,
      area: selectedAreas && selectedAreas.length > 0 ? selectedAreas : undefined,
    };

    if (window.location.search !== params.toString()) {
      setSearchParams(params, { replace: true });
    }

    if (booking.length > 1 || booking.length === 0) {
      setRequestFiltresString(toStringFilter(filterUrlParams));
    }

    if (
      (page !== null || (citySelected && cityName !== citySelected.name)) &&
      (booking.length > 1 || booking.length === 0) &&
      sortSelected
    ) {
      // if (citySelected && cityName !== citySelected.name) {
      //     setFirstRender(true);
      //     setRequestFiltresString(undefined);
      //     dispatch(setClearAllFilter());
      //     dispatch(setClearAllAdditionalFilters());
      //     // if (!clickBackButton) {
      //     //   navigate(`/catalog/${citySelected.name}`);
      //     // } else {
      //     //   const cityNameSelected = cityData.find((item) => item.name === cityName);
      //     //   if (cityNameSelected !== undefined) {
      //     //     dispatch(setCitySelected(cityNameSelected));
      //     //   }
      //     // }
      // }

      if (citySelected !== null) {
        setPage(null);
        setItems([]);
      }
    }
    // eslint-disable-next-line
  }, [
    citySelected,
    cityName,
    rooms,
    calendarSelected,
    guests,
    priceValue,
    isContactless,
    isBonus,
    sliderAreaValue,
    sliderFloorValue,
    doubleBedsValue,
    bedsValue,
    sortSelected,
    features,
    selectedAreas,
  ]);

  useEffect(() => {
    if (!clickBackButton) {
      if (citySelected) {
        // if (citySelected.name) {
        //   navigate(`/catalog/${citySelected.name}`);
        // }
        setFirstRender(true);
        setRequestFiltresString(undefined);
        dispatch(setClearAllFilter());
        dispatch(setClearAllAdditionalFilters());
      }
    } else {
      const cityNameSelected = cityData.find((item) => item.name === cityName);
      if (cityNameSelected !== undefined) {
        dispatch(setCitySelected(cityNameSelected));
      }
    }
  }, [citySelected, clickBackButton, cityName]);

  useEffect(() => {
    if (page !== null && citySelected !== null) {
      setIsLoading(true);
      if (isError) {
        setIsError(false);
      }
      CatalogData.getPage({
        page,
        cityId: citySelected.id,
        order: toStringFilter(getSortSelected(sortSelected)),
        filters: requestFiltresString,
        signal: controller.signal,
      })
        .then((resp) => {
          setMetaPage(resp.meta);
          if (page === 1) {
            setItems(resp.data);
            if (firstRender) {
              setFirstRender(false);
            }
          } else if (page > 1) {
            setItems((prevItems) => [...prevItems, ...resp.data]);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          // console.log("cancel", error.code)
          if (!(error.code === 'ERR_CANCELED')) {
            console.log(error.message);
            setIsLoading(false);
            setIsError(true);
          }
        });
    }
    if (citySelected && citySelected.name !== cityName) {
      navigate(`/catalog/${citySelected.name}`);
    }
    if (page === null) {
      setPage(() => 1);
    }

    return () => controller.abort();
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (metaPage) {
      if (metaPage.stat) {
        if (metaPage.stat.price && metaPage.stat.price.length === 2) {
          dispatch(setSliderPriceStartValue(metaPage.stat.price));
        }
        if (metaPage.stat.floor && metaPage.stat.floor.length === 2) {
          dispatch(setStartSliderFloorValue(metaPage.stat.floor));
        }
        if (metaPage.stat.area && metaPage.stat.area.length === 2) {
          dispatch(setStartSliderAreaValue(metaPage.stat.area));
        }
        if (metaPage && metaPage.count) {
          dispatch(setMetaCount(metaPage.count));
        }
      }
    }
    // eslint-disable-next-line
  }, [metaPage]);

  useEffect(() => {
    const controllerAll = new AbortController();

    if (citySelected !== null) {
      CatalogData.getAll({
        cityId: citySelected.id,
        filters: requestFiltresString,
        signal: controllerAll.signal,
      })
        .then((resp) => setMapData(resp.data))
        .catch((error) => {
          // console.log("cancel", error.code)
          if (!(error.code === 'ERR_CANCELED')) {
            console.log(error.message);
          }
        });
    }
    return () => (citySelected !== null ? controllerAll.abort() : undefined);
  }, [citySelected, requestFiltresString]);

  useEffect(() => {
    document.title = 'Каталог';

    setPage(() => 1);

    const roomsParams = searchParams.get('rooms');
    const guestsParams = searchParams.get('guests');
    const checkInParams = searchParams.get('checkIn');
    const departureParams = searchParams.get('departure');
    const priceValueParams = searchParams.get('price');
    const isContactlessParams = searchParams.get('сontactless');
    const isBonusParams = searchParams.get('highbonus');
    const checkInToDate = checkInParams
      ? new Date(new Date(checkInParams).setHours(0)).toString()
      : null;
    const departureToDate = departureParams
      ? new Date(new Date(departureParams).setHours(0)).toString()
      : null;

    const filterIntoArrayNumber = {
      rooms: roomsParams ? roomsParams.split(',').map((room) => +room) : null,
      guests: guestsParams ? guestsParams.split(',').map((guest) => +guest) : null,
      calendarSelected:
        checkInToDate || departureToDate
          ? { checkIn: checkInToDate, departure: departureToDate }
          : null,
      sliderPriceValue: priceValueParams
        ? priceValueParams.split(',').map((price) => +price)
        : null,
      isContactless: isContactlessParams === 'true',
      isBonus: isBonusParams === 'true',
    };
    if (
      rooms !== roomsParams ||
      guests !== guestsParams ||
      priceValue !== priceValueParams ||
      !(
        calendarSelected &&
        (checkInParams === calendarSelected.checkIn ||
          departureParams === calendarSelected.departure)
      )
    ) {
      dispatch(setParams(filterIntoArrayNumber));
    }

    setFirstRender(true);

    const handleBackButton = () => {
      setClickBackButton(true);
    };

    const resetClickBackButton = () => {
      setClickBackButton(false);
    };

    window.addEventListener('popstate', handleBackButton);
    document.addEventListener('click', resetClickBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
      document.removeEventListener('click', resetClickBackButton);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container>
        <CatalogSearch />
      </Container>
      <Container catalog>
        {windowWidth > 959 && (
          <div className={catalog('bread-crumbs')}>
            <span className={catalog('crumbs-text')}>Каталог</span>
            {cityName && <Icon externalClassName={catalog('crumb-icon')} iconName="crumb_arrow" />}
            {cityName && (
              <Link
                onClick={() => {
                  dispatch(setClearAllFilter());
                  dispatch(setClearAllAdditionalFilters());
                }}
                to={`/catalog/${cityName}`}>
                {cityName}
              </Link>
            )}
            {rooms && <Icon externalClassName={catalog('crumb-icon')} iconName="crumb_arrow" />}
            {rooms && (
              <Link
                onClick={() => {
                  dispatch(setClearCrumbsFilter());
                  dispatch(setClearAllAdditionalFilters());
                }}
                to={`/catalog/${cityName}?rooms=${encodeURIComponent(
                  rooms ? rooms.toString() : '',
                )}`}>{`${rooms}-комнатные квартиры`}</Link>
            )}
          </div>
        )}
        <div className={catalog('wrapper')}>
          <div ref={listWrapper} className={catalog('list-wrapper')}>
            <div className={catalog('list-header')}>
              {metaPage !== null && metaPage.count && (
                <p className={catalog('found-text')}>Найдено {metaPage.count} подходящих квартир</p>
              )}
              {!firstRender &&
                cityCoords &&
                cityCoords.lat &&
                cityCoords.lon &&
                windowWidth <= 800 && (
                  <MapComponent
                    externalClassName={catalog('full-mark')}
                    zoomMap={!isModalMap ? 15 : 12}
                    CenterCoordsValue={[cityCoords.lon, cityCoords.lat]}
                    mobileCatalog
                    data={mapData}
                  />
                )}
              <ScrollingButtons
                data={['Дешевле', 'Дороже', 'Популярные', 'Ближе к центру']}
                defaultSelected="Ближе к центру"
                handleExternal={handleSetSortSelected}
              />
            </div>
            {items && items.length > 0 && <CatalogList data={items} />}
            {/* {items && items} */}
            {/* <Pagination
              totalPageCount={25}
              currentPage={currentPage}
              handleExternal={handleSetCurentPage}
              externalClassName={catalog("pagination")}
            /> */}
            {isLoading &&
              (firstRender ? (
                <div className={catalog('carousel-box')}>
                  <div className={catalog('carousel')}>
                    <Loading externalClassName={catalog('loading')} />
                    <p className={catalog('loading-text')}>Идёт загрузка</p>
                  </div>
                </div>
              ) : (
                <Loading externalClassName={catalog('loading')} />
              ))}
            {isError && (
              <div className={catalog('error')}>
                <h2>Ошибка сервера</h2>
                <p className={catalog('error-text')}>
                  Приносим извинения за временные неудобства. Возникла ошибка сервера при поиске
                  квартир.
                </p>
              </div>
            )}
          </div>
          {!firstRender && cityCoords && cityCoords.lat && cityCoords.lon && windowWidth > 800 && (
            <MapComponent
              externalClassName={catalog('full-mark')}
              data={mapData}
              CenterCoordsValue={[cityCoords.lon, cityCoords.lat]}
              zoomMap={12}
            />
          )}
        </div>
        {/* <Loading /> */}
      </Container>
    </>
  );
};

export default Catalog;
