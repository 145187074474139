import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../store';

import SideMenu from '../../UI/SideMenu';
import MobileScrollMenu from '../../UI/MobileScrollMenu';
import Container from '../../UI/Container';
import ServicesRules from '../ServiceRules';
import Contacts from '../Contacts';

import bemClassName from '../../utils/bem';
import './index.scss';
import Requisites from '../Requisites';
import Communication from '../Communication';
import AboutCompany from '../AboutCompany';

const services = bemClassName('services');

const Services = () => {
  const windowWidth = useSelector((state: RootState) => state.window.windowWidth);

  const location = useLocation();

  useEffect(() => {
    document.title = 'Сервисные страницы';
  }, []);

  return (
    <Container>
      <div className={services('inner')}>
        {windowWidth >= 1024 && (
          <div className={services('right-side')}>
            <SideMenu
              data={[
                { text: 'Вопросы и ответы' },
                { text: 'Правила сервиса', hash: 'service-rules' },
                { text: 'Реквизиты', hash: 'requisites' },
                { text: 'О компании', hash: 'about' },
                { text: 'Контакты', hash: 'contacts' },
              ]}
            />
          </div>
        )}
        <div
          className={services('content', {
            text: location.hash === '#service-rules' || location.hash === '#contacts',
          })}>
          {windowWidth < 1024 && (
            <MobileScrollMenu
              data={[
                { text: 'Вопросы и ответы' },
                { text: 'Правила сервиса', hash: 'service-rules' },
                { text: 'Реквизиты', hash: 'requisites' },
                { text: 'О компании', hash: 'about' },
                { text: 'Контакты', hash: 'contacts' },
              ]}
            />
          )}
          {/* { (!location.hash
              || !( location.hash === '#service-rules'
              || location.hash === '#requisites'
              || location.hash === '#about'
              || location.hash === '#contacts'
              )
            )
              && <ServicesRules />} */}
          {!location.hash && <Communication />}
          {location.hash === '#service-rules' && <ServicesRules />}
          {location.hash === '#requisites' && <Requisites />}
          {location.hash === '#about' && <AboutCompany />}
          {location.hash === '#contacts' && <Contacts />}
        </div>
      </div>
    </Container>
  );
};

export default Services;
