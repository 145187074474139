import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store';
import { setRooms } from '../../store/reducers/search/searchDataSlice';

import Checkbox from '../../UI/Checkbox';

import bemClassName from '../../utils/bem';

import './index.scss';

const rooms = bemClassName('rooms');

const Rooms = () => {
  // const [isChecked, setIsChecked] = useState();
  const roomsData = useSelector((state: RootState) => state.appData.roomsData);
  const roomsSearchData = useSelector((state: RootState) => state.searchData.rooms);

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const handleSetRooms = (id: number) => {
    dispatch(setRooms(id));
  };

  // useEffect(() => {
  //   if ((roomsSearchData !== null && roomsSearchData.includes(id + 1))) {
  //     setIsChecked(true)
  //   }
  // }, [roomsSearchData])

  return (
    <div className={rooms()}>
      {roomsData.map((item, id) => {
        return (
          <Checkbox
            text={item}
            key={id}
            checked={roomsSearchData !== null && roomsSearchData.includes(id + 1)}
            externalClassName={rooms('item')}
            handleExternal={() => handleSetRooms(id + 1)}
          />
        );
      })}
    </div>
  );
};

export default Rooms;
