import bemClassName from '../../../utils/bem';
import Days from '../Days';
import './index.scss';
import { WeekProps } from './interface';

const week = bemClassName('week');

const Week: React.FC<WeekProps> = ({
  calendarDate,
  weekNumber,
  miniCalendar,
  setSelectedDay,
  selectedDay,
}) => {
  return (
    <div className={week({ active: weekNumber === 0 })} key={weekNumber}>
      {[0, 1, 2, 3, 4, 5, 6].map((dayOfWeek) => {
        return miniCalendar ? (
          <Days
            key={dayOfWeek}
            calendarDate={calendarDate}
            weekNumber={weekNumber}
            dayOfWeek={dayOfWeek}
            miniCalendar
            setSelectedDay={setSelectedDay}
            selectedDay={selectedDay}
          />
        ) : (
          <Days
            key={dayOfWeek}
            calendarDate={calendarDate}
            weekNumber={weekNumber}
            dayOfWeek={dayOfWeek}
          />
        );
      })}
    </div>
  );
};

export default Week;
