import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import ReviewModal from '../ReviewModal';
import Button from '../../UI/Button';

import { IHistoryBookingItem } from './interface';
import bemClassName from '../../utils/bem';

import './index.scss';

const historyBookingItem = bemClassName('history-booking-item');

const HistoryBookingItem: React.FC<IHistoryBookingItem> = ({ data }) => {
  const [isOpenModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    if (!data.review) {
      setOpenModal(true);
    }
  };

  const handleCloseModal = (event: MouseEvent) => {
    event?.stopPropagation();
    event?.preventDefault();
    setOpenModal(false);
  };
  return (
    <div
      className={historyBookingItem({
        cancelled: data.statusBooking === 'Отменено',
      })}>
      <Link to={`/catalog/${data.city}/${data.id}/`} className={historyBookingItem('link')}>
        {data && data.image && data.photos_url && (
          <picture className={historyBookingItem('image')}>
            <source
              type="image/webp"
              srcSet={`${data.photos_url}/${data.image}-600x348.webp, ${data.photos_url}/${data.image}-1200x696.webp 2x, ${data.photos_url}/${data.image}-1800x1044.webp 3x`}
            />
            <img
              className={historyBookingItem('image')}
              src={`${data.photos_url}/${data.image}-298x298.jpg`}
              srcSet={`${data.photos_url}/${data.image}-596x596.jpg 2x, ${data.photos_url}/${data.image}-894x894.jpg 3x`}
              alt=""
            />
          </picture>
        )}
        <div className={historyBookingItem('title')}>
          <h4 className={historyBookingItem('city')}>{data.city}</h4>
          <p className={historyBookingItem('address')}>{data.address}</p>
        </div>
      </Link>
      <div className={historyBookingItem('info-wrap')}>
        <div className={historyBookingItem('info')}>
          <p className={historyBookingItem('description')}>Дата бронирования</p>
          <p className={historyBookingItem('text')}>{data.dateBooking}</p>
        </div>
        <div className={historyBookingItem('info')}>
          <p className={historyBookingItem('description')}>Статус бронирования</p>
          <p className={historyBookingItem('text')}>{data.statusBooking}</p>
        </div>
      </div>
      <div className={historyBookingItem('review')}>
        <Button
          text="Оставить отзыв"
          disable={data.review}
          transparent
          handleExternal={handleOpenModal}
        />
        {data.review && <p className={historyBookingItem('review-text')}>Вы уже оставили отзыв</p>}
        <ReviewModal
          id={data.id ?? 0}
          locationLink="#"
          image={data.image ?? ''}
          image_url={data.photos_url ?? ''}
          city={data.city ?? ''}
          address={data.address ?? ''}
          handleClose={handleCloseModal}
          open={isOpenModal}
        />
      </div>
    </div>
  );
};

export default HistoryBookingItem;
