import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import bemClassName from '../../utils/bem';
import Checkbox from '../../UI/Checkbox';

import './index.scss';
import Container from '../../UI/Container';
import AdvantagesSticker from '../../UI/AdvantagesSticker';
import CatalogData from '../../API/CatalodData';
import ApartamentPayment from '../../components/ApartamentPayment';
import MapComponent from '../../components/Map';
import RadioButtons from '../../UI/RadiouButtons';
import transfer from '../../assets/images/transfer.png';
import bankTransfer from '../../assets/images/bank-transfer.png';
import paymentBonus from '../../assets/images/payment-bonus.png';
import sbpTransfer from '../../assets/images/sbp-transfer.png';
import Button from '../../UI/Button';

const payment = bemClassName('payment');

const Payment = () => {
  const [data, setData] = useState<any>();

  const checkBoxData = [
    {
      text: 'Оплатить банковским переводом',
      img: transfer,
    },
    {
      text: 'Оплатить банковской картой',
      img: bankTransfer,
    },
    {
      text: 'Оплатить бонусами',
      img: paymentBonus,
    },
    {
      text: 'Перевести по номеру карты или СБП',
      img: sbpTransfer,
    },
    {
      text: 'Оплатить наличными при заезде',
      img: '',
    },
  ];

  useEffect(() => {
    document.title = 'Оплата';
    CatalogData.getApartament({ apartamentId: '388' }).then((items) => {
      setData(items);
    });
  }, []);

  return (
    <Container>
      <div className={payment('')}>
        <h2 className={payment('title')}>Внесение предоплаты за проживание</h2>
        <>
          <div className={payment('buttons-wrap')}>
            <AdvantagesSticker data={['Бесконтактное заселение']} />
            <AdvantagesSticker data={['Обычное заселение']} crossedOut />
          </div>
          <div className={payment('content')}>
            <div className={payment('info-box')}>
              <ApartamentPayment data={data} />
              {data &&
                data.address &&
                data.address.coords &&
                data.address.coords.lon &&
                data.address.coords.lat && (
                  <MapComponent
                    externalClassName={payment('room-mark')}
                    zoomMap={17}
                    dataRoom={data}
                    CenterCoordsValue={[data.address.coords.lon, data.address.coords.lat]}
                  />
                )}
            </div>
            <div className={payment('pay-way')}>
              <div className={payment('panel')}>
                <h3 className={payment('pay-header')}>Выберите способ оплаты</h3>
                <RadioButtons
                  externalClassName={payment('field')}
                  name="Peyment"
                  data={checkBoxData.map((item) => ({
                    text: item.text,
                    value: item.text,
                    img: item.img,
                  }))}
                  arrow
                  pay
                />
                <div className={payment('button-purchase')}>
                  <Button externalClassName={payment('button')} text="Оплатить" />
                  <div className={payment('purchase-info')}>
                    <span className={payment('description')}>Если </span>
                    <Link to="/">пройти верификацию документов</Link>,
                    <p className={payment('description')}>
                      {' '}
                      будет начислено бонусных баллов +850 P
                    </p>
                  </div>
                  <Checkbox
                    text="Получить скидку в виде кешбека"
                    externalClassName={payment('checkbox')}
                    // handleExternal={(value: boolean) => setCashBack(value)}
                    // checked={cashBack}
                  />
                  <p className={payment('description')}>
                    * - при себе необходимо иметь документы, удостоверяющие личность
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </Container>
  );
};

export default Payment;
