import { useState } from 'react';

import Modal from '../Modal';
import Button from '../Button';

import { IAdvantages } from './interface';
import bemClassName from '../../utils/bem';

import './index.scss';

const advantagesSticker = bemClassName('advantages-sticker');

const AdvantagesSticker: React.FC<IAdvantages> = ({ data, crossedOut }) => {
  const [isOpen, setOpen] = useState(false);

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleOpenModal = () => {
    setOpen(true);
  };
  return (
    <div className={advantagesSticker()}>
      {data.map((item, id) => {
        return item === 'Бесконтактное заселение' ? (
          <p
            className={advantagesSticker('item', { clickable: true })}
            key={id}
            onClick={handleOpenModal}>
            {item}
          </p>
        ) : (
          <p className={advantagesSticker('item', { crossed: crossedOut })} key={id}>
            {item}
          </p>
        );
      })}
      <Modal open={isOpen} handleClose={handleCloseModal}>
        <h3>Что такое бесконтактное заселение?</h3>
        <p>
          Квартиры с бесконтактным заселением оборудованы кодовым замком. После бронирования и
          оплаты в СМС или в нашем мобильном приложении Вы получите код доступа в квартиру, который
          будет действовать весь срок Вашего пребывания.
        </p>
        <p>
          Опция доступна для гостей, подтвердивших свою личность и согласившихся с правилами
          проживания. Способы подтверждения и также правила проживания доступны зарегистрированным
          пользователям в личном кабинете
        </p>
        <Button text="Ок" handleExternal={handleCloseModal} />
      </Modal>
    </div>
  );
};

export default AdvantagesSticker;
