import { DAYS } from '../../app_constants';

export const date = new Date();

export function getWeekDay(value: Date) {
  return DAYS[value.getDay()];
}

const localeButtonWeatherDate = new Date().toLocaleString('ru', {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
});

export const getButtonWeatherDate = () => `${localeButtonWeatherDate}`;

export const formatedDateDayBirdh = (value: string) => {
  return new Date(value).toLocaleDateString('ru');
};
