export const bookingStatusTextNotVerified = (status: any) => {
  switch (status) {
    case 'Предварительное бронирование':
      return 'Бронирование принято, в ближайшее время с Вами свяжется менеджер для подтверждения брони.';
    case 'Предварительное бронирование ночное':
      return 'Бронирование принято, менеджер свяжется с Вами утром для подтверждения брони.';
    default:
      return 'Бронирование принято, в ближайшее время с Вами свяжется менеджер для подтверждения брони.';
  }
};

export const bookingStatusTextVerified = (status: any) => {
  switch (status) {
    case 'Бронирование':
    case 'Предварительное бронирование':
      return 'Заявка подана';
    case 'Внесена предоплата':
    case 'Полная оплата':
      return 'Забронировано';
    default:
      return 'Заявка подана';
  }
};

export const bookingStatusPayment = (status: any) => {
  switch (status) {
    case 'Бронирование':
    case 'Предварительное бронирование':
      return 'заезд';
    case 'Внесена предоплата':
      return 'внесена предоплата';
    case 'Полная оплата':
      return 'полная оплата';
    default:
      return 'заезд';
  }
};
