import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IContactPhones {
  comment: string | null;
  data: [
    {
      name: string | null;
      phone: {
        E164: string | null;
        national: string | null;
        international: string | null;
        frame: string | null;
        valid: boolean;
      };
    },
  ];
}

interface IContactEMail {
  data: string | null;
  comment: string | null;
}

export interface DataState {
  contactPhones: IContactPhones | null;
  contactEMail: IContactEMail | null;
  contacntHelperPhones: IContactPhones | null;
}

const initialState: DataState = {
  contactPhones: null,
  contactEMail: null,
  contacntHelperPhones: null,
};

export const ContactDataSlice = createSlice({
  name: 'contactData',
  initialState,
  reducers: {
    setContactPhones: (state, action: PayloadAction<IContactPhones | null>) => {
      state.contactPhones = action.payload;
    },
    setContactEMail: (state, action: PayloadAction<IContactEMail | null>) => {
      state.contactEMail = action.payload;
    },
    setContacntHelperPhones: (state, action: PayloadAction<IContactPhones | null>) => {
      state.contacntHelperPhones = action.payload;
    },
  },
});

export const { setContactPhones, setContactEMail, setContacntHelperPhones } =
  ContactDataSlice.actions;

export default ContactDataSlice.reducer;
