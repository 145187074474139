import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import Raiting from '../../UI/Raiting';

import { IReviewItem } from './interface';
import bemClassName from '../../utils/bem';

import './index.scss';

const reviewItem = bemClassName('review-item');

const ReviewItem: React.FC<IReviewItem> = ({
  locationLink,
  photos_url,
  photos,
  city,
  address,
  raiting,
  // link,
  name,
  date,
  text,
  full,
}) => {
  const windowWidth = useSelector((state: RootState) => state.window.windowWidth);

  function kitcut(slicingText: string) {
    const limit = windowWidth >= 448 ? 127 : 180;
    let returnedText = slicingText.trim().slice(0, limit);
    const lastSpace = returnedText.lastIndexOf(' ');
    if (lastSpace > 0) {
      returnedText = returnedText.substring(0, lastSpace);
    }
    return `${returnedText}...`;
  }

  return (
    <Link to={locationLink} className={reviewItem({ full })}>
      <div className={reviewItem('apartament-link')}>
        <picture className={reviewItem('apartament-image')}>
          <img
            className={reviewItem('apartament-photos')}
            src={`${photos_url}${photos[0]}-298x298.jpg`}
            srcSet={`${photos_url}${photos[0]}-596x596.jpg 2x, ${photos_url}${photos[0]}-894x894.jpg 3x`}
            alt=""
          />
        </picture>
        <div className={reviewItem('apartament-location')}>
          <h4 className={reviewItem('city')}>{city}</h4>
          <p className={reviewItem('apartament-address')}>{address}</p>
        </div>
      </div>
      <Raiting externalClassName={reviewItem('raiting')} count={raiting} />
      <div className={reviewItem('link')}>
        <h5 className={reviewItem('title')}>
          {name}
          <span className={reviewItem('date')}>{date}</span>
        </h5>
        <p className={reviewItem('text')}>
          {full ||
          (windowWidth >= 448 && text && text.length < 172) ||
          (windowWidth < 448 && text && text.length < 216) ? (
            text
          ) : (
            <>
              {text && kitcut(text)}
              <br />
              <span className={reviewItem('more')}>ещё</span>
            </>
          )}
        </p>
      </div>
    </Link>
  );
};

export default ReviewItem;
