import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store';
import { fetchFooterLinksData } from '../../store/reducers/data/footerLinksDataSlice';

import Container from '../../UI/Container';
import Logo from '../Logo';
import AppLink from '../AppLink';
import FooterLinks from '../FooterLinks';

import bemClassName from '../../utils/bem';

import './index.scss';

const footer = bemClassName('footer');

const Footer = () => {
  const dataFooterLinks = useSelector((state: RootState) => state.footerLinksData.data);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  useEffect(() => {
    dispatch(fetchFooterLinksData());
    // eslint-disable-next-line
  }, []);

  return (
    <footer className={footer()}>
      <Container>
        <div className={footer('inner')}>
          <div className={footer('row', { logo: true })}>
            <div className={footer('logo-box')}>
              <Logo footer />
              <h4 className={footer('logo-box-text')}>
                Услуги
                <br />
                от одной компании
              </h4>
            </div>
            <FooterLinks special="payment" title="Платежные системы" />
          </div>
          <div className={footer('row')}>
            {dataFooterLinks.map((item, id) => {
              return (
                <React.Fragment key={id}>
                  <FooterLinks key={id} title={item.title} dataLinks={item.data} />
                  {id === 0 && (
                    <FooterLinks key="special" special="phones" title="Служба поддержки" />
                  )}
                </React.Fragment>
              );
            })}
            <FooterLinks special="apps" title="Приложение" />
          </div>
          <div className={footer('row')}>
            <AppLink footer url="#" text="Ознакомьтесь с нашими правилами размещения" />
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
