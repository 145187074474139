export const formatedBookingPanelDateForReservation = (dateString: string) => {
  const time = new Date().toLocaleTimeString();
  return `${new Date(dateString).toISOString().slice(0, 10)} ${time}`;
};

export const formatedBookingDays = (
  startdate: string,
  enddate: string,
  reserv?: boolean,
  currentBooking?: boolean,
) => {
  const dates = (date: string) =>
    new Date(date).toLocaleDateString('ru-RU', { day: 'numeric', month: 'long' });

  const startMonth = new Date(startdate).toLocaleString('ru-RU', { month: 'long' });
  const endMonth = new Date(enddate).toLocaleString('ru-RU', { month: 'long' });

  if (!reserv && !currentBooking) {
    const result =
      startMonth === endMonth
        ? `Вы бронируете с ${new Date(startdate).getDate()} по ${new Date(
            enddate,
          ).getDate()} ${startMonth} ${new Date(startdate).getFullYear()} г.`
        : `Вы бронируете с ${dates(startdate)} по ${dates(enddate)}`;
    return result;
  }
  if (reserv) {
    const result =
      startMonth === endMonth
        ? `${new Date(startdate).getDate()}-${new Date(enddate).getDate()} ${startMonth}`
        : `${new Date(startdate).getDate()} ${startMonth} - ${new Date(
            enddate,
          ).getDate()} ${endMonth}`;

    return result;
  }
  if (currentBooking) {
    const result =
      startMonth === endMonth
        ? `${new Date(startdate).getDate()}-${new Date(enddate).getDate()} ${startMonth} ${new Date(
            enddate,
          ).getFullYear()} г.`
        : `${new Date(startdate).getDate()} ${startMonth} ${new Date(
            startdate,
          ).getFullYear()} г. - ${new Date(enddate).getDate()} ${endMonth} ${new Date(
            startdate,
          ).getFullYear()} г.`;

    return result;
  }
  return undefined;
};

export const formatedCalendarDays = (date: string) => {
  const calendarDate = new Date(date).toISOString().split('T')[0];
  return calendarDate;
};
