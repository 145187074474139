import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../store';

import Container from '../../UI/Container';
import Favourites from '../Favourites';
import CurrentBookings from '../CurrentBookings';
import HistoryBookings from '../HistoryBookings';
import MobileScrollMenu from '../../UI/MobileScrollMenu';
import SideMenu from '../../UI/SideMenu';
import PersonalData from '../PersonalData';
import Security from '../Security';
import PassportData from '../PassportData';
import BonusPoints from '../BonusPoints';
import ServiceAgreement from '../ServiceAgreement';
import ProofIdentity from '../ProofIdentity';
import Messages from '../Messages';

import bemClassName from '../../utils/bem';

import './index.scss';
import { CHANGE_PASSWORD } from '../../app_constants';
import Chat from '../Chat/inted';

const account = bemClassName('account');

const Account = () => {
  const windowWidth = useSelector((state: RootState) => state.window.windowWidth);
  const isLogin = useSelector((state: RootState) => state.autorizationStatus.isLogin);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Кабинет';
  }, []);

  const resumeLink = () => {
    if (isLogin !== null && !isLogin) {
      if (location.hash === '') {
        navigate('#favourites');
      }
    }
  };

  useEffect(() => {
    if (isLogin !== null && !isLogin) {
      resumeLink();
    }
  }, [isLogin]);

  return (
    <Container>
      <div className={account('inner')}>
        <div
          className={account('content', {
            big_gap: location.hash === '#bonus-points',
          })}>
          {windowWidth < 1024 &&
            (isLogin !== null && isLogin ? (
              <MobileScrollMenu
                data={[
                  { text: 'Личные данные' },
                  { text: 'Пароль и безопасность', hash: CHANGE_PASSWORD },
                  { text: 'Паспортные данные', hash: 'passport-data' },
                  { text: 'Договор обслуживания', hash: 'service-agreement' },
                  { text: 'Подтверждение личности', hash: 'proof-of-identity' },
                  { text: 'Избранное', hash: 'favourites' },
                  { text: 'Текущие бронирования', hash: 'current-bookings' },
                  { text: 'История бронирований', hash: 'history-bookings' },
                  { text: 'Бонусные баллы', hash: 'bonus-points' },
                  { text: 'Сообщения', hash: 'messages' },
                ]}
              />
            ) : (
              <MobileScrollMenu
                data={[
                  { text: 'Избранное', hash: 'favourites' },
                  { text: 'Текущие бронирования', hash: 'current-bookings' },
                ]}
              />
            ))}
          {(!location.hash ||
            !(
              location.hash === CHANGE_PASSWORD ||
              location.hash === '#passport-data' ||
              location.hash === '#service-agreement' ||
              location.hash === '#proof-of-identity' ||
              location.hash === '#favourites' ||
              location.hash === '#current-bookings' ||
              location.hash === '#history-bookings' ||
              location.hash === '#bonus-points' ||
              location.hash.startsWith('#messages')
            )) && <PersonalData />}
          {isLogin !== null && isLogin ? (
            <>
              {location.hash === '#change-password' && <Security />}
              {location.hash === '#passport-data' && <PassportData />}
              {location.hash === '#service-agreement' && <ServiceAgreement />}
              {location.hash === '#proof-of-identity' && <ProofIdentity />}
              {location.hash === '#favourites' && <Favourites />}
              {location.hash === '#current-bookings' && <CurrentBookings />}
              {location.hash === '#history-bookings' && <HistoryBookings />}
              {location.hash === '#bonus-points' && <BonusPoints />}
              {location.hash === '#messages' && <Messages />}
              {location.hash.startsWith('#messages_') && (
                <Chat chat_id={location.hash.split('_')[1]} />
              )}
            </>
          ) : (
            <>
              {location.hash === '#favourites' && <Favourites />}
              {location.hash === '#current-bookings' && <CurrentBookings />}
            </>
          )}
        </div>
        {windowWidth >= 1024 && (
          <div className={account('right-side')}>
            {isLogin !== null && isLogin ? (
              <SideMenu
                data={[
                  { text: 'Личные данные' },
                  { text: 'Пароль и безопасность', hash: CHANGE_PASSWORD },
                  { text: 'Паспортные данные', hash: 'passport-data' },
                  { text: 'Договор обслуживания', hash: 'service-agreement' },
                  { text: 'Подтверждение личности', hash: 'proof-of-identity' },
                  { text: 'Избранное', hash: 'favourites' },
                  { text: 'Текущие бронирования', hash: 'current-bookings' },
                  { text: 'История бронирований', hash: 'history-bookings' },
                  { text: 'Бонусные баллы', hash: 'bonus-points' },
                  { text: 'Сообщения', hash: 'messages' },
                ]}
              />
            ) : (
              <SideMenu
                data={[
                  { text: 'Избранное', hash: 'favourites' },
                  { text: 'Текущие бронирования', hash: 'current-bookings' },
                ]}
              />
            )}
          </div>
        )}
      </div>
    </Container>
  );
};

export default Account;
