import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../store';

import Container from '../../UI/Container';
import Raiting from '../../UI/Raiting';
import AdvantagesSticker from '../../UI/AdvantagesSticker';
import UnfoldingBox from '../../UI/UnfoldingBox';
import Icon from '../../UI/Icon';
import CarouselDetail from '../../UI/CarouselDetail';
import Like from '../../UI/Like';
import BookingPanel from '../../components/BookingPanel';

import bemClassName from '../../utils/bem';

// import image from '../../assets/images/patrick-perkins-iRiVzALa4pI-unsplash.jpg';
import './index.scss';
import { IDetailCatalog } from './interface';
// import { ThunkDispatch } from 'redux-thunk';
import CatalogData from '../../API/CatalodData';
// import iconsList from '../../featuresData.json';
import { getFlagsIcons, getSquare } from '../../utils/catalogUtils';
import MapComponent from '../../components/Map';
import Loading from '../../UI/Loading';
import Modal from '../../UI/Modal';
import Button from '../../UI/Button';

const catalogDetail = bemClassName('catalog-detail');
interface IDataItem {
  icon?: string;
  text: string;
}
const CatalogDetail = () => {
  const windowWidth = useSelector((state: RootState) => state.window.windowWidth);

  const [detailState, setDetailState] = useState<IDetailCatalog>();
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [viewShareModal, setViewShareModal] = useState<boolean>(false);

  const shareModalRef = useRef<HTMLDivElement | null>(null);
  const linkRef = useRef<HTMLParagraphElement>(null);

  const { id } = useParams();

  const openShareModal = () => {
    setViewShareModal(!viewShareModal);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  const copyToClipboard = () => {
    if (linkRef && linkRef.current) {
      const text = linkRef.current.innerText;
      navigator.clipboard.writeText(text);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      shareModalRef.current &&
      shareModalRef.current instanceof HTMLElement &&
      !shareModalRef.current.contains(event.target as Node)
    ) {
      setViewShareModal(false);
    }
  };

  useEffect(() => {
    document.title = 'Каталог Детальная';
    setIsLoadingDetail(true);

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (id !== undefined) {
      CatalogData.getApartament({ apartamentId: id }).then((resp) => setDetailState(resp));
    }
  }, [id]);

  useEffect(() => {
    if (isLoadingDetail) {
      if (!document.body.hasAttribute('style')) {
        document.body.style.overflow = 'hidden';
      }
    } else if (!isLoadingDetail) {
      if (document.body.hasAttribute('style')) {
        document.body.removeAttribute('style');
      }
    }
  }, [isLoadingDetail]);

  useEffect(() => {
    if (detailState) {
      setIsLoadingDetail(false);
    } else {
      setIsLoadingDetail(true);
    }
  }, [detailState]);
  return (
    <Container>
      <h1>{detailState && detailState.address.address.name}</h1>
      {isLoadingDetail && (
        <div className={catalogDetail('carousel-box')}>
          <div className={catalogDetail('carousel')}>
            <Loading externalClassName={catalogDetail('loading')} />
            <p className={catalogDetail('loading-text')}>Идёт загрузка</p>
          </div>
        </div>
      )}
      <div className={catalogDetail('inner')}>
        <div className={catalogDetail('content')}>
          <div className={catalogDetail('interactive')}>
            {detailState && detailState.reviews && (
              <Raiting
                count={detailState.reviews.avr}
                full
                reviews_count={detailState.reviews.scores}
              />
            )}
            <div className={catalogDetail('buttons-wrap')}>
              {detailState && detailState.bonus !== undefined && (
                <AdvantagesSticker
                  data={[`+${detailState.bonus}% к бонусным баллам`]}
                  // , "Бесконтактное заселение"
                />
              )}
              <div className={catalogDetail('buttons')}>
                <div
                  onClick={openShareModal}
                  ref={shareModalRef}
                  className={catalogDetail('share')}>
                  <Icon iconName="share" externalClassName={catalogDetail('share-icon')} />
                  Поделиться
                  {viewShareModal && (
                    <div className={catalogDetail('share-modal')}>
                      <p ref={linkRef} className={catalogDetail('link')}>
                        {window.location.href}
                      </p>
                      <Button
                        handleExternal={() => copyToClipboard()}
                        text="Скопировать ссылку"
                        externalClassName={catalogDetail('copy')}
                      />
                    </div>
                  )}
                </div>
                {detailState && (
                  <Like id={id ? +id : 0} full externalClassName={catalogDetail('like')} />
                )}
              </div>
            </div>
            {detailState && detailState.photos && detailState.photos.length > 0 && (
              <CarouselDetail
                data={detailState.photos}
                photos_url={detailState.photos_url}
                onClick={() => handleOpenModal()}
              />
            )}
            {detailState && detailState.photos && detailState.photos.length > 0 && isOpen && (
              <Modal open={isOpen} handleClose={handleCloseModal} photosMoodal>
                <CarouselDetail
                  photos_url={detailState.photos_url}
                  data={detailState.photos}
                  modalOpen
                />
              </Modal>
            )}
          </div>
          {windowWidth < 1024 && detailState && detailState.price && detailState.id && (
            <BookingPanel
              id={detailState.id}
              cityName={detailState.address.city.name}
              address={detailState.address.address.name}
              price={detailState.price}
              load={(value: boolean) => setIsLoadingDetail(value)}
            />
          )}
          <div className={catalogDetail('text-content')}>
            <div className={catalogDetail('unfolding-list')}>
              <h4 className={catalogDetail('unfolding-text')}>Удобства</h4>
              <div className={catalogDetail('unfolding-column')}>
                {detailState &&
                  detailState.flags &&
                  getFlagsIcons(detailState.flags, 'equipment').length > 0 && (
                    <UnfoldingBox
                      title="Оснащение"
                      data={getFlagsIcons(detailState.flags, 'equipment') as Array<IDataItem>}
                    />
                  )}
                {/* {detailState && detailState.features && (
                  <UnfoldingBox
                    title="Оснащение"
                    data={getIconsType(detailState.features, "equipment")}
                  />
                )} */}
                {/* {detailState && detailState.features && (
                  <UnfoldingBox
                    title="Досуг и инфраструктура в пределах 10 минут ходьбы"
                    data={getIconsType(detailState.features, "accessibility")}
                  />
                )} */}
                {detailState &&
                  detailState.flags &&
                  getFlagsIcons(detailState.flags, 'accessibility').length > 0 && (
                    <UnfoldingBox
                      title="Досуг и инфраструктура в пределах 10 минут ходьбы"
                      data={getFlagsIcons(detailState.flags, 'accessibility') as Array<IDataItem>}
                    />
                  )}
              </div>
              <div className={catalogDetail('unfolding-column')}>
                {/* {detailState && detailState.features && (
                  <UnfoldingBox
                    title="Кухня"
                    data={getIconsType(detailState.features, "kitchen")}
                  />
                )} */}
                {detailState &&
                  detailState.flags &&
                  getFlagsIcons(detailState.flags, 'kitchen').length > 0 && (
                    <UnfoldingBox
                      title="Кухня"
                      data={getFlagsIcons(detailState.flags, 'kitchen') as Array<IDataItem>}
                    />
                  )}
                {/* {detailState && detailState.features && (
                  <UnfoldingBox
                    title="Для отдыха"
                    data={getIconsType(detailState.features, "rest")}
                  />
                )} */}
                {detailState &&
                  detailState.flags &&
                  getFlagsIcons(detailState.flags, 'rest').length > 0 && (
                    <UnfoldingBox
                      title="Для отдыха"
                      data={getFlagsIcons(detailState.flags, 'rest') as Array<IDataItem>}
                    />
                  )}
              </div>
              <div className={catalogDetail('unfolding-column')}>
                {/* {detailState && detailState.features && (
                  <UnfoldingBox
                    title="Ванная комната"
                    data={getIconsType(detailState.features, "bathroom")}
                  />
                )} */}
                {detailState &&
                  detailState.flags &&
                  getFlagsIcons(detailState.flags, 'bathroom').length > 0 && (
                    <UnfoldingBox
                      title="Ванная комната"
                      data={getFlagsIcons(detailState.flags, 'bathroom') as Array<IDataItem>}
                    />
                  )}
                {/* {detailState && detailState.features && (
                  <UnfoldingBox
                    title="Для детей"
                    data={getIconsType(detailState.features, "forKids")}
                  />
                )} */}
                {detailState &&
                  detailState.flags &&
                  getFlagsIcons(detailState.flags, 'forKids').length > 0 && (
                    <UnfoldingBox
                      title="Для детей"
                      data={getFlagsIcons(detailState.flags, 'forKids') as Array<IDataItem>}
                    />
                  )}
              </div>
            </div>
            <div className={catalogDetail('unfolding-list')}>
              <h4 className={catalogDetail('unfolding-text')}>Основная информация</h4>
              <div className={catalogDetail('unfolding-column')}>
                {detailState &&
                  detailState.features.FloorNumber &&
                  detailState.features.FloorsCount &&
                  detailState.features.FloorNumber !== 0 &&
                  detailState.features.FloorsCount !== 0 && (
                    <UnfoldingBox
                      title="Этаж"
                      data={[
                        {
                          text: `${detailState.features.FloorNumber} этаж из ${
                            detailState && detailState.features.FloorsCount
                          }`,
                        },
                      ]}
                    />
                  )}
                {detailState && detailState.features.WindowsViewType !== null && (
                  <UnfoldingBox
                    title="Вид из окна"
                    data={[
                      {
                        text: `${
                          detailState &&
                          detailState.features &&
                          detailState.features.WindowsViewType
                        }`,
                      },
                    ]}
                  />
                )}
                {detailState && detailState.features.ParkingType !== null && (
                  <UnfoldingBox
                    title="Парковка"
                    data={[
                      {
                        text: `${detailState.features && detailState.features.ParkingType} (${
                          detailState && detailState.address.building['parking-places']
                        } места)`,
                      },
                    ]}
                  />
                )}
              </div>
              <div className={catalogDetail('unfolding-column')}>
                {detailState &&
                  detailState.features.LivingArea &&
                  detailState.features.LivingArea !== 0 &&
                  detailState.features.LivingArea !== null && (
                    <UnfoldingBox
                      title="Площадь комнат"
                      data={[
                        // { text: "24 м2 площадь 1 спальни" },
                        // { text: "16 м2 площадь 2 спальни" },
                        // { text: "15 м2 площадь зала" },
                        { text: `${detailState.features.LivingArea} м2 площадь кухни` },
                      ]}
                    />
                  )}
                {detailState &&
                  detailState.features.CeilingHeight &&
                  detailState.features.CeilingHeight !== 0 &&
                  detailState.features.CeilingHeight !== null && (
                    <UnfoldingBox
                      title="Высота потолков"
                      data={[
                        {
                          text: `${
                            detailState.features && detailState.features.CeilingHeight
                          } метра`,
                        },
                      ]}
                    />
                  )}
              </div>
              {detailState &&
                getSquare(detailState.beds).length > 0 &&
                detailState &&
                detailState.features &&
                detailState.features.bedrooms !== null &&
                detailState.features.bedrooms !== 0 && (
                  <div className={catalogDetail('unfolding-column')}>
                    {detailState && getSquare(detailState.beds).length > 0 && (
                      <UnfoldingBox title="Спальные места" data={getSquare(detailState.beds)} />
                    )}
                    {detailState &&
                      detailState.features &&
                      detailState.features.bedrooms !== null &&
                      detailState.features.bedrooms !== 0 && (
                        <UnfoldingBox
                          title="Изолированные спальни"
                          data={[{ text: `${detailState.features.bedrooms} штуки` }]}
                        />
                      )}
                  </div>
                )}
            </div>
            {detailState &&
              detailState.features.description &&
              detailState.features.description !== '' && (
                <div className={catalogDetail('unfolding-list')}>
                  <h4 className={catalogDetail('unfolding-text')}>Описание</h4>
                  <p className={catalogDetail('unfolding-text')}>
                    {detailState.features.description}
                    {/* Апартаменты расположены в шаговой доступности от станции метро
                  Алексеевская. Оснащены всем необходимым для комфортного
                  проживания. Апартаменты расположены в шаговой доступности от
                  станции метро Алексеевская. Оснащены всем необходимым для
                  комфортного проживания. Апартаменты расположены в шаговой
                  доступности от станции метро Алексеевская. Оснащены всем
                  необходимым для комфортного проживания. */}
                  </p>
                </div>
              )}
            <div className={catalogDetail('unfolding-list')}>
              <h4 className={catalogDetail('unfolding-text')}>Правила проживания и размещения</h4>
              {detailState && detailState.rules && detailState.rules.checkIn !== undefined && (
                <div className={catalogDetail('unfolding-column')}>
                  <UnfoldingBox
                    title="Заезд"
                    data={[{ text: `после ${detailState.rules.checkIn}` }]}
                  />
                </div>
              )}
              {detailState && detailState.rules && detailState.rules.checkOut !== undefined && (
                <div className={catalogDetail('unfolding-column')}>
                  <UnfoldingBox
                    title="Отъезд"
                    data={[{ text: `до ${detailState.rules.checkOut}` }]}
                  />
                </div>
              )}
              {detailState && detailState.rules && detailState.rules.duration !== undefined && (
                <div className={catalogDetail('unfolding-column')}>
                  <UnfoldingBox
                    title="Минимальный срок проживания"
                    data={[{ text: `от ${detailState.rules.duration} суток` }]}
                  />
                </div>
              )}
              <ul className={catalogDetail('unfolding-text')}>
                <li>можно с детьми любого возраста</li>
                <li>курение запрещено</li>
                <li>нельзя с питомцами</li>
                <li>без вечеринок и мероприятий</li>
                <li>владелец предоставляет отчетные документы о проживании</li>
              </ul>
            </div>
            {detailState &&
              detailState.address &&
              detailState.address.coords &&
              detailState.address.coords.lon &&
              detailState.address.coords.lat && (
                <MapComponent
                  externalClassName={catalogDetail('room-mark')}
                  zoomMap={17}
                  dataRoom={detailState}
                  CenterCoordsValue={[
                    detailState.address.coords.lon,
                    detailState.address.coords.lat,
                  ]}
                />
              )}
          </div>
        </div>
        {windowWidth >= 1024 && detailState && (
          <div className={catalogDetail('right-side')}>
            <BookingPanel
              id={detailState.id}
              cityName={detailState.address.city.name}
              address={detailState.address.address.name}
              side
              price={detailState.price}
              load={(value: boolean) => setIsLoadingDetail(value)}
            />
          </div>
        )}
      </div>
    </Container>
  );
};

export default CatalogDetail;
