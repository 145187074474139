import { useState } from 'react';
import Icon from '../Icon';
import { IUnfoldingBox } from './interface';
import bemClassName from '../../utils/bem';
import './index.scss';

const unfoldingBox = bemClassName('unfolding-box');

const UnfoldingBox: React.FC<IUnfoldingBox> = ({ data, title }) => {
  const [isHidden, setIsHidden] = useState(true);

  return (
    <div className={unfoldingBox()}>
      <p className={unfoldingBox('title')}>{title}</p>
      {data.map((item, id) => {
        return isHidden ? (
          id <= 5 && (
            <p key={id} className={unfoldingBox('text')}>
              {item.icon && <Icon iconName={item.icon} externalClassName={unfoldingBox('icon')} />}
              {item.text}
            </p>
          )
        ) : (
          <p key={id} className={unfoldingBox('text')}>
            {item.icon && <Icon iconName={item.icon} externalClassName={unfoldingBox('icon')} />}
            {item.text}
          </p>
        );
      })}
      {data.length > 6 && (
        <button
          type="button"
          className={unfoldingBox('button')}
          onClick={() => setIsHidden(!isHidden)}>
          {isHidden ? 'показать' : 'скрыть'}
        </button>
      )}
    </div>
  );
};

export default UnfoldingBox;
