import * as oauth from 'oauth4webapi';

export const CITY = 'city';
export const ROOMS = 'rooms';
export const CALENDAR = 'calendar';
export const GUESTS = 'guests';

export const TEL = 'tel';
export const TEXT = 'text';
export const EMAIL = 'email';
export const PASSWORD = 'password';

export const PASSPORT_NUMBER = 'passportNumber';
export const DIVISION_CODE = 'divisionsCode';

export const LINE = 'line';
export const BAR = 'bar';

export const WEEK = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
export const DAYS = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];

// export const API_URL = 'https://hoho24.online/api/service';
export const API_URL_VALIDATRO = process.env.REACT_APP_API_URL_VALIDATRO;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

export const AUTH_CONFIG: oauth.AuthorizationServer = {
  issuer: process.env.REACT_APP_ISSUER as string,
  authorization_endpoint: process.env.REACT_APP_AUTHORIZATION_ENDPOINT,
  token_endpoint: process.env.REACT_APP_TOKEN_ENDPOINT,
  scope: process.env.REACT_APP_SCOPE,
};

export const CLIENT: oauth.Client = {
  client_id: process.env.REACT_APP_CLIENT_ID as string,
  client_secret: CLIENT_SECRET,
  token_endpoint_auth_method: process.env
    .REACT_APP_TOKEN_ENDPOINT_AUTH_METHOD as oauth.ClientAuthenticationMethod,
};

export const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;
// export const redirectUri = 'https://hh24.tauruna.ru/auth';
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export const BASE_API_URL_SECRET = process.env.REACT_APP_BASE_API_URL_SECRET;

export const CHANGE_PASSWORD = process.env.REACT_APP_CHANGE_PASSWORD;

export const ADD_DAYS = [1, 2, 3, 7];
