import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import bemClassName from '../../../utils/bem';
import './index.scss';
import { DaysProps } from './interface';
import { RootState } from '../../../store';
import { setCheckIn, setDeparture } from '../../../store/reducers/search/searchDataSlice';
import { getCurrentDayOfMonth } from '../../../utils/calendarUtils';

const day = bemClassName('day');

const Days: React.FC<DaysProps> = ({
  calendarDate,
  dayOfWeek,
  weekNumber,
  miniCalendar,
  setSelectedDay,
  selectedDay,
}) => {
  const calendarSelected = useSelector((state: RootState) => state.searchData.calendarSelected);
  const maxDate = new Date(
    useSelector((state: RootState) => state.calendarData.dateInYear.maxDate),
  );
  const minDate = new Date(
    useSelector((state: RootState) => state.calendarData.dateInYear.minDate),
  );

  const dispatch = useDispatch();

  const isSelected = (data: Date) => {
    let result = false;
    if (calendarSelected) {
      if (!calendarSelected.checkIn) {
        return false;
      }
      if (
        data.getFullYear() === new Date(calendarSelected.checkIn).getFullYear() &&
        data.getMonth() === new Date(calendarSelected.checkIn).getMonth() &&
        data.getDate() === new Date(calendarSelected.checkIn).getDate()
      ) {
        result = true;
      } else if (
        calendarSelected.departure &&
        data.getFullYear() === new Date(calendarSelected.departure).getFullYear() &&
        data.getMonth() === new Date(calendarSelected.departure).getMonth() &&
        data.getDate() === new Date(calendarSelected.departure).getDate()
      ) {
        result = true;
      } else if (
        calendarSelected.departure &&
        data.getTime() > new Date(calendarSelected.checkIn).getTime() &&
        data.getTime() < new Date(calendarSelected.departure).getTime()
      ) {
        result = true;
      }
      return result;
    }
    return result;
  };

  const isDayDisabled = (date: Date) => {
    const beginningOfToday = minDate;
    const fullYearFromNow = maxDate;
    return (
      date.getTime() < beginningOfToday.getTime() || date.getTime() > fullYearFromNow.getTime()
    );
  };

  const [currentYear, setCurrentYear] = useState(calendarDate.getFullYear());
  const [currentMonth, setCurrentMonth] = useState(calendarDate.getMonth());
  const currentDayOfMonth = getCurrentDayOfMonth(currentYear, currentMonth, dayOfWeek, weekNumber);

  const currentDay = new Date(currentYear, currentMonth, currentDayOfMonth);

  const isSelectedStartDate =
    calendarSelected && calendarSelected.checkIn
      ? currentDay.getTime() === new Date(calendarSelected.checkIn).getTime()
      : false;

  const isSelectedEndDate =
    calendarSelected && calendarSelected.departure
      ? currentDay.getTime() === new Date(calendarSelected.departure).getTime()
      : false;

  const handleClickDay = () => {
    if (isDayDisabled(currentDay)) {
      return;
    }
    if (!calendarSelected) {
      dispatch(setCheckIn(currentDay.toString()));
    } else if (calendarSelected.checkIn && !calendarSelected.departure) {
      if (new Date(calendarSelected.checkIn).getTime() === currentDay.getTime()) {
        dispatch(setCheckIn(null));
      } else if (currentDay.getTime() < new Date(calendarSelected.checkIn).getTime()) {
        dispatch(setCheckIn(currentDay.toString()));
        dispatch(setDeparture(null));
      } else if (currentDay.getTime() > new Date(calendarSelected.checkIn).getTime()) {
        dispatch(setDeparture(currentDay.toString()));
      }
    } else if (calendarSelected.checkIn && calendarSelected.departure) {
      if (new Date(calendarSelected.departure).getTime() === currentDay.getTime()) {
        dispatch(setDeparture(null));
      } else if (new Date(calendarSelected.checkIn).getTime() === currentDay.getTime()) {
        dispatch(setCheckIn(null));
        dispatch(setDeparture(null));
      } else if (currentDay.getTime() < new Date(calendarSelected.checkIn).getTime()) {
        dispatch(setCheckIn(currentDay.toString()));
        dispatch(setDeparture(null));
      } else if (currentDay.getTime() > new Date(calendarSelected.checkIn).getTime()) {
        dispatch(setCheckIn(currentDay.toString()));
        dispatch(setDeparture(null));
      }
    }
  };

  const handleClickOneDay = () => {
    if (isDayDisabled(currentDay)) {
      return;
    }
    if (setSelectedDay) {
      setSelectedDay((prev: any) => {
        return prev && new Date(prev).getTime() === new Date(currentDay).getTime()
          ? null
          : String(currentDay);
      });
    }
  };

  useEffect(() => {
    setCurrentYear(calendarDate.getFullYear());
    setCurrentMonth(calendarDate.getMonth());
  }, [calendarDate]);

  return (
    <>
      {!miniCalendar
        ? currentDayOfMonth > 0 && (
            <span
              className={day({
                active: isSelected(currentDay),
                disable: isDayDisabled(currentDay),
                selected_date: isSelectedStartDate || isSelectedEndDate,
              })}
              key={dayOfWeek}
              onClick={handleClickDay}>
              {currentDayOfMonth}
            </span>
          )
        : currentDayOfMonth > 0 && (
            <span
              className={day({
                active: isSelected(currentDay),
                selected_date:
                  selectedDay && currentDay.getTime() === new Date(selectedDay).getTime(),
                disable: isDayDisabled(currentDay),
              })}
              key={dayOfWeek}
              onClick={handleClickOneDay}>
              {currentDayOfMonth}
            </span>
          )}
    </>
  );
};

export default Days;
