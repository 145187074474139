import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import CatalogData from '../../../API/CatalodData';
import { ICatalogItemData } from '../../../components/CatalogItem/interface';

export const fetchCatalogItemsData = createAsyncThunk(
  'data/fetchCatalogItemsData',
  CatalogData.getPage,
);

export const resetCatalogData = createAction('data/resetCatalogData');

export interface DataState {
  data: Array<ICatalogItemData>;
  totalCount: number | null;
}

export interface ICatalogItemsResponseData {
  data: Array<ICatalogItemData>;
  meta: any;
}

const initialState: DataState = {
  data: [],
  totalCount: null,
};

export const reviewDataSlice = createSlice({
  name: 'reviewData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchCatalogItemsData.fulfilled,
        (state, action: PayloadAction<ICatalogItemsResponseData>) => {
          state.data = [...state.data, ...action.payload.data];
          state.totalCount = action.payload.meta.totalCount;
        },
      )
      .addCase(resetCatalogData, (state) => {
        state.data = [];
        state.totalCount = null;
      });
  },
});

// export const {} = reviewDataSlice.actions;

export default reviewDataSlice.reducer;
