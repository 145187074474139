import { IButton } from './Inteface';
import bemClassName from '../../utils/bem';

import './index.scss';

const button = bemClassName('button');

const Button: React.FC<IButton> = ({
  text,
  handleExternal,
  externalClassName = '',
  transparent,
  red,
  disable,
  search,
  typeButton = 'button',
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (handleExternal) {
      handleExternal(event);
    }
  };

  return (
    <button
      disabled={disable}
      className={`${button({
        transparent,
        red,
        disable,
        search,
      })} ${externalClassName}`}
      // eslint-disable-next-line
      type={typeButton}
      onClick={handleClick}>
      {text}
    </button>
  );
};

export default Button;
