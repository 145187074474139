import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface DateLimitPerYear {
  minDate: string;
  maxDate: string;
}

export interface DataState {
  currentDate: string | null;
  dateInYear: DateLimitPerYear;
}

const initialState: DataState = {
  currentDate: null,
  dateInYear: {
    maxDate: '',
    minDate: '',
  },
};

export const CalendarDataSlice = createSlice({
  name: 'calendarData',
  initialState,
  reducers: {
    setCurrentDate: (state, action: PayloadAction<string>) => {
      state.currentDate = action.payload;
    },
    setMinDate: (state, action: PayloadAction<string>) => {
      state.dateInYear.minDate = action.payload;
    },
    setMaxDate: (state, action: PayloadAction<string>) => {
      state.dateInYear.maxDate = action.payload;
    },
  },
});

export const { setCurrentDate, setMinDate, setMaxDate } = CalendarDataSlice.actions;

export default CalendarDataSlice.reducer;
