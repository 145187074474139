import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface DataState {
  isLogin: boolean | null;
  isPaswordVerified: boolean;
  accessToken: string;
  expires: number;
}

const initialState: DataState = {
  isLogin: null,
  isPaswordVerified: false,
  accessToken: '',
  expires: 0,
};

export const autorizationStatusSlice = createSlice({
  name: 'autorizationStatus',
  initialState,
  reducers: {
    logout: (state) => {
      state.isLogin = false;
    },
    setLogin: (state) => {
      state.isLogin = true;
    },
    setVerified: (state) => {
      state.isPaswordVerified = true;
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    setExpires: (state, action: PayloadAction<number>) => {
      state.expires = action.payload;
    },
  },
});

export const { logout, setAccessToken, setLogin, setExpires, setVerified } =
  autorizationStatusSlice.actions;

export default autorizationStatusSlice.reducer;
