import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import AppInput from '../../UI/AppInput';
import Button from '../../UI/Button';

import { CALENDAR, DIVISION_CODE, PASSPORT_NUMBER, TEXT } from '../../app_constants';
import bemClassName from '../../utils/bem';

import './index.scss';
import { RootState } from '../../store';

import PrivateData from '../../API/PrivateData';
import Validate from '../../API/Validate';
import { formatedDateDayBirdh } from '../../utils/date';

const passportData = bemClassName('passport-data');

const PassportData = () => {
  const [dataList, setDataList] = useState<Array<string>>([]);

  const [userpPassportData, setUserPassportData] = useState({});

  const [passportCode, setPassportCode] = useState<string>('');
  const [passportDivision, setPassportDivision] = useState<string>('');
  const [passportDate, setPassportDate] = useState<string | null>(null);
  const [passportHelperArray, setPassportHelperArray] = useState<Array<string>>([]);
  const [passportNumber, setPassportNumber] = useState<string>('');
  const [passportErrorText, setPassportErrorText] = useState<string>();

  const [errorBoolean, setErrorBoolean] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);

  const [userPassportDataEdit, setUserPassportDataEdit] = useState<boolean>(false);

  // const [passportIsValid, setpassportisValid] = useState<boolean>(false);

  const [passportIsValidNumber, setpassportisValidNumber] = useState<boolean>();

  const isLogin = useSelector((state: RootState) => state.autorizationStatus.isLogin);

  const handleSavePassportData = () => {
    if (userpPassportData) {
      setDisabledButton(false);
      PrivateData.getPrivateEditUserDate(userpPassportData).then(() => {
        setDisabledButton(true);
        setUserPassportDataEdit(true);
      });
    }
  };

  const handleSetDivisionsCode = (value: string) => {
    if (value !== null && value !== '') {
      Validate.helperPassport({ value, type: 'code' }).then((response) => {
        setPassportHelperArray(response.map((item: any) => item.name));
      });
    } else {
      setDataList([]);
    }
  };

  const handleSetDivisionsName = (value: string) => {
    if (value) {
      Validate.helperPassport({ value, type: 'name' });
    }
    if (passportHelperArray === null || passportHelperArray.length === 0) {
      setPassportDivision(value);
    } else {
      setDataList([]);
    }
  };

  const handleSetPassportNumber = (number: string) => {
    setPassportNumber(number);

    if (number === '') {
      // setpassportisValid(false);
    }
  };

  const handleSetPassportCode = (number: string) => {
    setPassportCode(number);
  };

  const handleSetPassportDate = (value: string) => {
    if (value) {
      if (value.length > 10) {
        setPassportDate(formatedDateDayBirdh(value));
      } else {
        const day = value.slice(0, 2);
        const month = value.slice(3, 5);
        const year = value.slice(6, 10);
        setPassportDate(formatedDateDayBirdh(`${month}.${day}.${year}`));
      }
    }
  };

  useEffect(() => {
    if (passportHelperArray && passportHelperArray.length !== 0) {
      setDataList(passportHelperArray);
    }
  }, [passportHelperArray]);

  useEffect(() => {
    setErrorBoolean(Boolean(passportNumber || passportCode || passportDivision || passportDate));

    // setDisabledButton(!!(passportNumber && passportCode && passportDate && passportDivision));

    setDisabledButton(!!(passportNumber && passportCode && passportDate));

    if (passportNumber && passportCode && passportDivision && passportDate) {
      setUserPassportData({
        passport: {
          number: passportNumber ?? undefined,
          code: passportCode ?? undefined,
          issued: passportDivision ?? undefined,
          date: passportDate ?? undefined,
        },
      });
    }
  }, [passportNumber, passportCode, passportDivision, passportDate]);

  useEffect(() => {
    if (isLogin !== null && isLogin) {
      PrivateData.getPrivateUser().then((item) => {
        if (
          item.passport.code !== null ||
          item.passport.issued !== null ||
          item.passport.date !== null ||
          item.passport.number !== null
        ) {
          setPassportCode(item.passport.code);
          setPassportDivision(item.passport.issued);
          if (item.passport.date) {
            setPassportDate(item.passport.date);
          }
          setPassportNumber(item.passport.number);
          if (
            item.passport.code !== null &&
            item.passport.issued !== null &&
            item.passport.date &&
            item.passport.number
          ) {
            setUserPassportDataEdit(true);
          }
        }
      });
    }
  }, [isLogin]);

  useEffect(() => {
    const abortControllerPassportNumber = new AbortController();
    if (passportNumber) {
      setpassportisValidNumber(true);
      try {
        Validate.checkPassport(passportNumber, abortControllerPassportNumber.signal)
          .then((resp) => {
            if (resp.series && resp.number) {
              setPassportNumber(`${resp.series} ${resp.number}`);
              // setpassportisValid(true);
            }
          })
          .catch(() => {
            setPassportErrorText('Слишком короткий номер');
            if (passportNumber) {
              setpassportisValidNumber(false);
            }
          });
      } catch {
        //
      }
    }
    return () => {
      abortControllerPassportNumber.abort();
    };
  }, [passportNumber]);

  useEffect(() => {
    document.title = 'Паспортные данные';
  }, []);

  return !userPassportDataEdit ? (
    <>
      <AppInput
        inputId="passportNumber"
        text="Номер и серия паспорта "
        type={TEXT}
        required
        externalClassName={passportData('field')}
        placeholder="Введите номер и серию паспорта"
        handleExternal={handleSetPassportNumber}
        validate={passportErrorText ?? ''}
        isValidExternal={passportIsValidNumber}
        dataType={PASSPORT_NUMBER}
        valueExternal={passportNumber ?? undefined}
        valueBoolean={errorBoolean}
      />
      <AppInput
        inputId="codeDivision"
        text="Код подразделения"
        type={TEXT}
        required
        externalClassName={passportData('field')}
        handleExternal={handleSetPassportCode}
        placeholder="Введите код подразделения"
        handleBlurExternal={handleSetDivisionsCode}
        dataType={DIVISION_CODE}
        valueExternal={passportCode ?? undefined}
        valueBoolean={errorBoolean}
      />
      <AppInput
        inputId="issuedBy"
        text="Кем выдан"
        type={TEXT}
        required
        externalClassName={passportData('field')}
        placeholder="Введите кем выдан паспорт"
        handleExternal={handleSetDivisionsName}
        dataListExternal={dataList}
        valueExternal={passportDivision}
        valueBoolean={errorBoolean}
      />
      <AppInput
        inputId="dateOfIssue"
        text="Когда выдан"
        type={CALENDAR}
        required
        externalClassName={passportData('field')}
        placeholder="Введите дату выдачи"
        handleExternal={handleSetPassportDate}
        valueExternal={passportDate ?? undefined}
        valueBoolean={errorBoolean}
        passportCalendar
      />
      <Button
        handleExternal={handleSavePassportData}
        text="Сохранить"
        disable={!disabledButton}
        externalClassName={passportData('button')}
      />
    </>
  ) : (
    <div className={passportData('user-data')}>
      <div className={passportData('user')}>
        <div className={passportData('passport-info')}>
          <p className={passportData('placeholder-text')}>Номер и серия паспорта</p>
          <p className={passportData('passport-data')}>{passportNumber}</p>
        </div>
        <div className={passportData('passport-info')}>
          <p className={passportData('placeholder-text')}>Код подразделения</p>
          <p className={passportData('passport-data')}>{passportCode}</p>
        </div>
        <div className={passportData('passport-info')}>
          <p className={passportData('placeholder-text')}>Кем выдан</p>
          <p className={passportData('passport-data')}>{passportDivision}</p>
        </div>
        <div className={passportData('passport-info')}>
          <p className={passportData('placeholder-text')}>Когда выдан</p>
          <p className={passportData('passport-data')}>{passportDate}</p>
        </div>
        <button
          className={passportData('edit-button')}
          onClick={() => setUserPassportDataEdit(false)}
          type="button">
          Редактировать
        </button>
      </div>
    </div>
  );
};

export default PassportData;
