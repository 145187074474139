import axiosInterseptor from '../../utils/axiosInterseptor';

export default class MessageData {
  static async getMessageDataСhat({
    id,
    offset,
    signal,
  }: {
    id: string;
    offset?: number;
    signal?: AbortSignal;
  }) {
    try {
      let params = '';
      if (offset) {
        params = `&offset=${offset}`;
      }
      const response = await axiosInterseptor.get(`user/chat?chat_id=${id}${params}`, { signal });
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getCreatedСhat(product_id: string) {
    try {
      const response = await axiosInterseptor.get(`user/chat?product_id=${product_id}`);
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getMessageDataAllChats(page: number, signal: AbortSignal) {
    try {
      const response = await axiosInterseptor.get(`user/chats?mode=filter&page=${page}`, {
        signal,
      });
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  static async postAddedMessage(chat_id: string, text: string, media?: any) {
    try {
      const response = await axiosInterseptor.post(
        'user/message',
        {
          chat_id,
          text,
          media,
        },
        {
          headers: {
            'Cache-Control': 'no-cache',
          },
        },
      );
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  static async postAddedMedia(file: File) {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await axiosInterseptor.post('/user/media', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Cache-Control': 'no-cache',
        },
      });

      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getNewMessages(id: string, last_id: number) {
    try {
      const response = await axiosInterseptor.get(`user/messages?chat_id=${id}&last_id=${last_id}`);
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }
}
