import { ReactNode, useEffect, useState } from 'react';
import parse from 'html-react-parser';

import bemClassName from '../../utils/bem';

import './index.scss';
import Public from '../../API/Public';
import Loading from '../../UI/Loading';

const communication = bemClassName('communication');

const Communication = () => {
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  let parsedHTML;
  if (data) {
    parsedHTML = parse(data.content);
  }

  useEffect(() => {
    document.title = 'Вопросы и ответы';
    setIsLoading(true);
    Public.getQuestionAnswer()
      .then((items) => {
        setData(items);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading externalClassName={communication('loading')} />
      ) : (
        data && (
          <>
            <h2 className={communication('title')}>{data.title}</h2>
            <div className={communication('inner')}>{parsedHTML as ReactNode}</div>
          </>
        )
      )}
    </>
  );
};

export default Communication;
