import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store';
import {
  fetchCheckEmailValidate,
  fetchHelperEmailValidate,
  setDefaultValueEmailValidate,
} from '../../store/reducers/validate/emailValidateSlice';
import {} from // fetchCheckPhoneValidate,
// setDefaultValuePhoneValidate,
'../../store/reducers/validate/phoneValidateSlice';

import { CALENDAR, DIVISION_CODE, EMAIL, PASSPORT_NUMBER, TEL, TEXT } from '../../app_constants';
import { IAppInput } from './interface';
import bemClassName from '../../utils/bem';

import './index.scss';
import CalendarContainer from '../AppCalendar';
import Icon from '../Icon';

const appInput = bemClassName('app-input');

const AppInput: React.FC<IAppInput> = ({
  inputId,
  type,
  text,
  externalClassName = '',
  placeholder = '',
  handleExternal,
  handleBlurExternal,
  required,
  validate,
  dataType,
  dataListExternal,
  isValidExternal,
  valueExternal,
  // requiredCallback,
  valueBoolean,
  additionalTelNumber,
  handleAddetPhone,
  personal,
  passportCalendar,
  additionalInputPhoneNumber,
}) => {
  const [value, setValue] = useState<string | null>(null);
  const [valid, setValid] = useState(true);
  const [dataList, setDataList] = useState<Array<string>>([]);
  const [validateText, setValidateText] = useState<string | null>(null);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);

  const calendarRef = useRef<HTMLDivElement>(null);

  const input = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();
  const dispatchAsync = useDispatch<ThunkDispatch<any, any, any>>();

  const emailIsValid = useSelector((state: RootState) => state.emailValidate.emailIsValid);
  const emailErrorText = useSelector((state: RootState) => state.emailValidate.emailErrorText);
  const emailDomainArray = useSelector((state: RootState) => state.emailValidate.emailDomainArray);

  const phoneIsValid = useSelector((state: RootState) => state.phoneValidate.phoneIsValid);
  const phoneErrorText = useSelector((state: RootState) => state.phoneValidate.phoneErrorText);
  const phoneInternational = useSelector((state: RootState) => state.phoneValidate.international);

  const windowWidth = useSelector((state: RootState) => state.window.windowWidth);

  const handleChange = () => {
    if (type === TEL) {
      // if (input.current !== null) {
      //   const currentValue = input.current.value.replace(/[^0-9+\s/-]/, '').substring(0, 16);
      //   setValue(currentValue);
      // }
      if (input.current !== null) {
        let currentValue = input.current.value.replace(/[^\d]/g, '').substring(0, 11);
        if (currentValue.length > 0) {
          const currentPhoneValue =
            currentValue[0] === '7' || currentValue[0] === '8' ? '' : currentValue[0];
          currentValue = `+ 7 ${currentPhoneValue}${currentValue
            .slice(1)
            .replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2-$3-$4')}`;
        }
        setValue(currentValue);
      }
    } else if (dataType === PASSPORT_NUMBER) {
      if (input.current !== null && input.current.value.replace(/\s/g, '').length <= 10) {
        const currentValue = input.current.value.replace(/[^0-9\s]/, '');
        setValue(currentValue);
      }
    } else if (dataType === DIVISION_CODE) {
      if (input.current !== null && input.current.value.length <= 7) {
        const currentValue = input.current.value
          .replace(/[^0-9/-]/g, '')
          .replace(/^(\d{3})(\d)$/, '$1-$2');
        setValue(currentValue);
      }
    } else if (input.current) {
      setValue(input.current.value);
    }
    if (type === EMAIL) {
      if (input.current !== null) {
        const currentValue = input.current.value;
        if (currentValue.includes('@')) {
          dispatchAsync(
            fetchHelperEmailValidate(currentValue.slice(currentValue.indexOf('@') + 1)),
          );
        }
      }
    }
    if (inputId === 'cashbackCard' && input && input.current) {
      const currentValue = input.current.value.replace(/\D/g, '');
      const formattedValue = currentValue.replace(/(\d{4})(?=\d)/g, '$1 ').trim();
      setValue(formattedValue.slice(0, 19));
    }
    // if(inputId === 'passportNumber' && input && input.current) {

    // }
  };

  const handleFocus = () => {
    if (validate) {
      if (type === EMAIL) {
        dispatch(setDefaultValueEmailValidate());
        setValid(true);
      }
      if (type === TEL && additionalInputPhoneNumber) {
        setValid(true);
      }
      if (type === TEXT) {
        setValid(true);
      }
      if (type === CALENDAR) {
        setValid(true);
      }
    }
  };

  const handleBlur = () => {
    if (validate) {
      if (value) {
        // if (type === TEL) {
        //   dispatchAsync(fetchCheckPhoneValidate(value));
        // }
        if (type === EMAIL) {
          dispatchAsync(fetchCheckEmailValidate(value));
        }
      }
    }
    if (required) {
      if (type === TEXT) {
        if (value?.length === 0 || value === null) {
          setValid(false);
        } else {
          setValid(true);
        }
      }
      if (type === CALENDAR) {
        if (value?.length === 0 || value === null) {
          setValid(false);
        } else {
          setValid(true);
        }
      }
      if (type === TEL) {
        if (value && value.replace(/\s/g, '').length !== 0) {
          // dispatchAsync(fetchCheckPhoneValidate(value));
          // setValid(true);
        } else {
          setValid(false);
        }
      }
    }
    if (handleBlurExternal) {
      handleBlurExternal(value);
    }
  };

  const handleOpenCalendar = () => {
    if (!openCalendar) {
      setOpenCalendar(true);
    } else {
      setOpenCalendar(false);
    }
  };

  useEffect(() => {
    if (required) {
      // if (valueBoolean) {
      handleBlur();
      // } else {
      // handleBlur();
      // }
    }
  }, [valueBoolean]);

  useEffect(() => {
    if (dataListExternal) {
      setDataList(dataListExternal);
    }
  }, [dataListExternal]);

  useEffect(() => {
    if (isValidExternal !== undefined) {
      setValid(isValidExternal);
    }
  }, [isValidExternal]);

  useEffect(() => {
    if (validate) {
      setValidateText(validate);
    }
  }, [validate]);

  useEffect(() => {
    if (valueExternal) {
      setValue(valueExternal);
    }
  }, [valueExternal]);

  useEffect(() => {
    if (type === CALENDAR) {
      if (value) {
        setOpenCalendar(false);
      }
    }
    if (handleExternal) {
      handleExternal(value);
    }
    if (required) {
      if (type === TEXT) {
        if (value?.length === 0) {
          setValid(false);
        } else {
          setValid(true);
        }
      }
      if (type === CALENDAR) {
        if (value?.length === 0) {
          setValid(false);
        } else {
          setValid(true);
        }
      }
      if (type === TEL) {
        if (value && value.replace(/\s/g, '').length !== 0) {
          // dispatchAsync(fetchCheckPhoneValidate(value));
          // setValid(false);
        } else {
          setValid(true);
        }
      }
    }
  }, [value]);

  useEffect(() => {
    if (type === EMAIL && value?.length !== 0 && value) {
      if (emailErrorText && emailErrorText.length !== 0) {
        setValidateText(emailErrorText);
      } else if (emailErrorText && emailErrorText.length === 0 && validate) {
        setValidateText(validate);
      }
      if (emailIsValid !== null) {
        setValid(emailIsValid);
      }
    }
  }, [emailIsValid, emailErrorText]);

  useEffect(() => {
    if (type === TEL && value?.length !== 0 && value) {
      if (phoneErrorText && phoneErrorText.length !== 0) {
        setValidateText(phoneErrorText);
      } else if (phoneErrorText && phoneErrorText.length === 0 && validate) {
        setValidateText(validate);
      }
      if (phoneIsValid !== null) {
        setValid(phoneIsValid);
      }
      setValue(phoneInternational);
    }
  }, [phoneIsValid, phoneErrorText]);

  useEffect(() => {
    if (emailDomainArray && emailDomainArray.length !== 0) {
      setDataList(emailDomainArray);
    }
  }, [emailDomainArray]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
        setOpenCalendar(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [calendarRef]);

  useEffect(() => {
    if (openCalendar && windowWidth < 960) {
      if (!document.body.hasAttribute('style')) {
        document.body.style.overflow = 'hidden';
      }
    } else if (!openCalendar && windowWidth < 960) {
      if (document.body.hasAttribute('style')) {
        document.body.removeAttribute('style');
      }
    }
  }, [openCalendar]);

  return (
    <label
      className={`${appInput('', { modal: type === 'calendar' })} ${externalClassName}`}
      htmlFor={inputId}>
      <p className={appInput('text')}>{text}</p>
      <input
        id={inputId}
        ref={input}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        className={appInput('field', { error: !valid })}
        type={type}
        placeholder={placeholder}
        value={value ?? ''}
        required={required}
        list={text}
        readOnly={type === CALENDAR}
      />
      {openCalendar && windowWidth < 960 && <div className={appInput('mini-calendar-modal')} />}
      {(type === 'calendar' || passportCalendar) && (
        <div
          onClick={handleOpenCalendar}
          ref={calendarRef}
          className={appInput('calendar-birthday')}>
          {!openCalendar && <Icon externalClassName={appInput('icon')} iconName="calendar" />}
          {openCalendar && (
            <div
              onClick={(event) => event.stopPropagation()}
              className={appInput('calendar-box', { passport: text === 'Когда выдан' })}>
              {windowWidth < 960 && <div className={appInput('swipe-button')} />}
              {passportCalendar ? (
                <CalendarContainer
                  externalClassName={appInput('calendar')}
                  miniCalendar
                  handleExternal={handleExternal}
                  passportDate
                />
              ) : (
                <CalendarContainer
                  externalClassName={appInput('calendar')}
                  miniCalendar
                  handleExternal={handleExternal}
                />
              )}
            </div>
          )}
        </div>
      )}
      {dataList && dataList.length > 0 && (
        <datalist id={text}>
          {dataList.map((item, id) => {
            if (value?.includes('@')) {
              return (
                <option value={value.slice(0, value.indexOf('@') + 1) + item} key={id}>
                  {value.slice(0, value.indexOf('@') + 1) + item}
                </option>
              );
            }
            return (
              <option value={item} key={id}>
                {item}
              </option>
            );
          })}
        </datalist>
      )}

      {!valid && validate && value && value.length > 0 && (
        <p className={appInput('error')}>{validateText}</p>
      )}
      {!additionalTelNumber && additionalInputPhoneNumber && (
        <p className={appInput('error')} onClick={() => handleAddetPhone(additionalTelNumber)}>
          Удалить дополнительный номер
        </p>
      )}
      {required && !valid && (value === null || value === '') && (
        <p className={appInput('error')}>Обязательное поле</p>
      )}
      {!additionalTelNumber && type === 'tel' && !additionalInputPhoneNumber && personal && (
        <p className={appInput('additional')} onClick={() => handleAddetPhone(additionalTelNumber)}>
          Добавить дополнительный номер
        </p>
      )}
      {text === 'Номер карты для получения кэшбека' && (
        <a className={appInput('additional')} href="/">
          Возникли вопросы ?
        </a>
      )}
    </label>
  );
};

export default AppInput;
