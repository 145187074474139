import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store';
import { fetchCItyData } from '../../store/reducers/data/appDataSlice';

import ScrollingButtons from '../../UI/ScrollingButtons';
import Container from '../../UI/Container';
import Button from '../../UI/Button';
import AppChart from '../../components/AppChart';

import { precipitation, tempAll, tempDay, tempNight } from '../../chartColorConst';
import { BAR, LINE } from '../../app_constants';
import { getButtonWeatherDate } from '../../utils/date';
import bemClassName from '../../utils/bem';

import './index.scss';

const weather = bemClassName('weather');

const Weather = () => {
  const dispatchAsync = useDispatch<ThunkDispatch<any, any, any>>();
  const cityData = useSelector((state: RootState) => state.appData.cityData);

  const handleSetCity = () => false;

  useEffect(() => {
    document.title = 'Погода';

    dispatchAsync(fetchCItyData());
  }, []);

  return (
    <Container>
      <div className={weather('inner')}>
        <div className={weather('head-buttons')}>
          <ScrollingButtons
            data={cityData.map((item) => item.name)}
            defaultSelected="Оренбург"
            handleExternal={handleSetCity}
            externalClassName={weather('city')}
          />
          <Button
            text={getButtonWeatherDate()}
            transparent
            externalClassName={weather('date-button')}
          />
        </div>
        <div className={weather('list')}>
          <AppChart
            labels={['1', '2', '3', '4', '5', '6', '7']}
            title="Температура воздуха на неделю (°С)"
            dataset={[
              {
                label: 'Температура ночью',
                data: [-20, -16, -13, -17, -15, 0, -1],
                chartColorType: tempNight,
              },
              {
                label: 'Температура днем',
                data: [2, 6, 3, 7, 5, 0, 1],
                chartColorType: tempDay,
              },
            ]}
            type={LINE}
          />
          <AppChart
            labels={['00:00', '04:00', '08:00', '12:00', '16:00', '20:00', '23:00']}
            title="Температура на день (°С)"
            dataset={[
              {
                label: 'Температура',
                data: [2, 6, 3, 7, 5, 0, 1],
                chartColorType: tempAll,
              },
            ]}
            type={LINE}
          />
          <AppChart
            labels={['1', '2', '3', '4', '5', '6', '7']}
            title="Вероятность осадков (%)"
            dataset={[
              {
                label: 'Вероятность осадков',
                data: [2, 6, 3, 7, 5, 0, 1],
                chartColorType: precipitation,
              },
            ]}
            type={BAR}
          />
        </div>
      </div>
    </Container>
  );
};

export default Weather;
