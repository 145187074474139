import Container from '../../UI/Container';

import bemClassName from '../../utils/bem';

import leftImage from '../../assets/images/left_119x454.webp';
import rightImage from '../../assets/images/right_264x454.webp';
import leftImageM from '../../assets/images/left_286x430.webp';
import rightImageM from '../../assets/images/right_635x430.webp';
import leftImageL from '../../assets/images/left_715x651.webp';
import rightImageL from '../../assets/images/right_1589x651.webp';

import './index.scss';

const mainPageBanner = bemClassName('main-page-banner');

const MainPageBanner = ({ children }: React.PropsWithChildren) => {
  return (
    <div className={mainPageBanner()}>
      <div className={mainPageBanner('images')}>
        <picture className={mainPageBanner('picture', { left: true })}>
          <source srcSet={leftImageL} type="image/webp" media="(min-width: 1025px)" />
          <source srcSet={leftImageM} type="image/webp" media="(min-width: 426px)" />
          <img className={mainPageBanner('image')} src={leftImage} alt="" />
        </picture>
        <picture className={mainPageBanner('picture', { right: true })}>
          <source srcSet={rightImageL} type="image/webp" media="(min-width: 1025px)" />
          <source srcSet={rightImageM} type="image/webp" media="(min-width: 426px)" />
          <img className={mainPageBanner('image')} src={rightImage} alt="" />
        </picture>
      </div>
      <Container>{children}</Container>
    </div>
  );
};

export default MainPageBanner;
