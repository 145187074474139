import React from 'react';
import { useLocation, Link } from 'react-router-dom';

import { IMobileScrollMenu } from './interface';
import bemClassName from '../../utils/bem';

import './index.scss';
import { CHANGE_PASSWORD } from '../../app_constants';

const mobileScrollMenu = bemClassName('mobile-scroll-menu');

const MobileScrollMenu: React.FC<IMobileScrollMenu> = ({ data, externalClassName = '' }) => {
  const location = useLocation();

  return (
    <div className={`${mobileScrollMenu()} ${externalClassName}`}>
      <div className={mobileScrollMenu('inner')}>
        {data.map((item, id) => {
          let hash;
          if (item.hash) {
            hash = `#${item.hash}`;
          }
          if (item.hash === CHANGE_PASSWORD) {
            hash = item.hash;
          }
          // const hash = item.hash ? `#${item.hash}` : '';
          return (
            <Link
              className={mobileScrollMenu('button', {
                active:
                  (!hash && !location.hash) ||
                  location.hash === hash ||
                  (location.hash.startsWith(hash) && hash === '#messages'),
              })}
              key={id}
              to={hash}>
              {item.text}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default MobileScrollMenu;
