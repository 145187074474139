import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface DataState {
  favorites: Array<number> | null;
}

// interface IFavorites {
//   cityId?: number;
//   id: number[];
// }

// export interface DataState {
//   favorites: IFavorites[];
// }

const initialState: DataState = {
  favorites: null,
};

export const favouritesSlice = createSlice({
  name: 'favourites',
  initialState,
  reducers: {
    setFavorite: (state, action: PayloadAction<number>) => {
      if (action.payload !== null) {
        if (state.favorites === null) {
          state.favorites = [];
          state.favorites.push(action.payload);
        } else if (state.favorites.includes(action.payload)) {
          state.favorites = [...state.favorites.filter((item) => item !== action.payload)];
        } else {
          state.favorites.push(action.payload);
        }
      } else {
        state.favorites = action.payload;
      }
      if (localStorage.favourites) {
        localStorage.favourites = JSON.stringify(state.favorites);
      }
    },
    // setFavorite: (state, action: PayloadAction<{ cityId: number; id: number }>) => {
    //   if (state.favorites.findIndex((item) => item.cityId === action.payload.cityId) !== -1) {
    //     if (
    //       state.favorites[
    //         state.favorites.findIndex((item) => item.cityId === action.payload.cityId)
    //       ].id.indexOf(action.payload.id) !== -1
    //     ) {
    //       state.favorites[
    //         state.favorites.findIndex((item) => item.cityId === action.payload.cityId)
    //       ].id.splice(
    //         state.favorites[
    //           state.favorites.findIndex((item) => item.cityId === action.payload.cityId)
    //         ].id.indexOf(action.payload.id),
    //         1,
    //       );
    //     } else {
    //       state.favorites[
    //         state.favorites.findIndex((item) => item.cityId === action.payload.cityId)
    //       ].id.push(action.payload.id);
    //     }
    //   } else {
    //     state.favorites.push({ cityId: action.payload.cityId, id: [action.payload.id] });
    //   }
    //   if (localStorage.favourites) {
    //     localStorage.favourites = JSON.stringify(state.favorites);
    //   }
    // },
    setFavorites: (state, action: PayloadAction<Array<number>>) => {
      state.favorites = action.payload;
    },
  },
});

export const { setFavorite, setFavorites } = favouritesSlice.actions;

export default favouritesSlice.reducer;
