import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../UI/Button';
import Checkbox from '../../UI/Checkbox';
import Counter from '../../UI/Counter/Index';
import RangeSlider from '../../UI/RangeSlider';
import Icon from '../../UI/Icon';

import bemClassName from '../../utils/bem';

import './index.scss';
import { RootState } from '../../store';
import {
  setBudsValue,
  setClearAllAdditionalFilters,
  setSelectedAreas,
  setDoubleBudsValue,
  setFeatures,
  setSliderAreaValue,
  setSliderFloorValue,
} from '../../store/reducers/search/otherFiltersDataSlice';
import CatalogData from '../../API/CatalodData';
import iconsList from '../../featuresData.json';

const otherFilters = bemClassName('other-filters');
interface Area {
  id: number;
  name: string;
  nid: number;
  count: number;
}
const OtherFilters = () => {
  const [isOpen, setOpenModal] = useState(false);
  const [areas, setAreas] = useState<Array<Area>>([]);
  const otherFiltersModal = useRef<HTMLDivElement>(null);

  const sliderStartFloorValue = useSelector(
    (state: RootState) => state.otherFiltersData.sliderStartFloorValue,
  );
  const sliderStartAreaValue = useSelector(
    (state: RootState) => state.otherFiltersData.sliderStartAreaValue,
  );
  const sliderFloorValue = useSelector(
    (state: RootState) => state.otherFiltersData.sliderFloorValue,
  );
  const sliderAreaValue = useSelector((state: RootState) => state.otherFiltersData.sliderAreaValue);
  const citySelected = useSelector((state: RootState) => state.searchData.citySelected);
  const metaCount = useSelector((state: RootState) => state.otherFiltersData.metaCount);
  const selectedAreas = useSelector((state: RootState) => state.otherFiltersData.selectedAreas);
  const features = useSelector((state: RootState) => state.otherFiltersData.features);
  const doubleBedsValue = useSelector((state: RootState) => state.otherFiltersData.doubleBedsValue);
  const bedsValue = useSelector((state: RootState) => state.otherFiltersData.bedsValue);
  const isModalMap = useSelector((state: RootState) => state.mapModal.isModalMap);

  const dispatch = useDispatch();

  const handleOpenModal = () => {
    document.body.style.overflow = 'hidden';
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    if (isOpen) {
      document.body.removeAttribute('style');
      setOpenModal(false);
    }
  };

  useEffect(() => {
    const onClick = (event: any) =>
      otherFiltersModal.current?.contains(event.target) || handleCloseModal();
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, [isOpen]);

  useEffect(() => {
    if (citySelected) {
      CatalogData.getAreas({ cityId: citySelected.id }).then((resp) =>
        setAreas(Object.values(resp)),
      );
    }
  }, [citySelected]);

  return (
    <div ref={otherFiltersModal} className={otherFilters()}>
      <button type="button" className={otherFilters('button')} onClick={handleOpenModal}>
        <Icon iconName="filters" externalClassName={otherFilters('button-icon')} />
        Другие фильтры
      </button>
      <div className={otherFilters('modal', { open: isOpen, mapModalOpen: isModalMap })}>
        <div className={otherFilters('title')}>
          <h2 className={otherFilters('text-title')}>Другие фильтры</h2>
          <Icon
            iconName="close"
            externalClassName={otherFilters('close')}
            handleClick={handleCloseModal}
          />
        </div>
        <div className={otherFilters('content')}>
          <div className={otherFilters('column')}>
            <div className={otherFilters('section')}>
              <h4 className={otherFilters('section-title')}>Этаж</h4>
              <RangeSlider
                from="c"
                to="по"
                minValue={sliderStartFloorValue ? sliderStartFloorValue.minValue : 0}
                maxValue={sliderStartFloorValue ? sliderStartFloorValue.maxValue : 200}
                action={setSliderFloorValue}
                fromParametr={sliderFloorValue ? sliderFloorValue[0] : undefined}
                toParametr={sliderFloorValue ? sliderFloorValue[1] : undefined}
              />
            </div>
            <div className={otherFilters('section')}>
              <h4 className={otherFilters('section-title')}>
                Общая площадь, м<sup>2</sup>
              </h4>
              <RangeSlider
                from="от"
                to="до"
                minValue={sliderStartAreaValue ? sliderStartAreaValue.minValue : 0}
                maxValue={sliderStartAreaValue ? sliderStartAreaValue.maxValue : 200}
                action={setSliderAreaValue}
                fromParametr={sliderAreaValue ? sliderAreaValue[0] : undefined}
                toParametr={sliderAreaValue ? sliderAreaValue[1] : undefined}
              />
            </div>
          </div>
          <div className={otherFilters('column')}>
            <div className={otherFilters('section')}>
              <h4 className={otherFilters('section-title')}>Спальные места</h4>
              <Counter
                maxValue={10}
                handleExternal={(count: number) => dispatch(setDoubleBudsValue(count))}
                value={doubleBedsValue !== null ? doubleBedsValue : 0}
                text="Двуспальное"
                note="Минимально"
              />
              <Counter
                maxValue={10}
                handleExternal={(count: number) => dispatch(setBudsValue(count))}
                value={bedsValue !== null ? bedsValue : 0}
                text="Односпальное"
                note="Минимально"
              />
            </div>
            {areas && (
              <div className={otherFilters('section')}>
                <h4 className={otherFilters('section-title')}>Район</h4>
                {areas.map((area) => (
                  <Checkbox
                    checked={selectedAreas ? selectedAreas.includes(area.id) : false}
                    handleExternal={area.id ? () => dispatch(setSelectedAreas(area.id)) : undefined}
                    key={area.id}
                    text={area.name}
                  />
                ))}
              </div>
            )}
          </div>
          <div className={otherFilters('column')}>
            <div className={otherFilters('section')}>
              <h4 className={otherFilters('section-title')}>Документы</h4>
              {iconsList.map(
                (item) =>
                  item.typeFilters === 'Documents' && (
                    <Checkbox
                      checked={
                        features
                          ? features.some((featuresDocuments) => featuresDocuments.id === item.id)
                          : false
                      }
                      key={item.title}
                      externalClassName={otherFilters('filters')}
                      handleExternal={() =>
                        dispatch(setFeatures({ apiKey: item.apiKey, id: item.id }))
                      }
                      text={item.title}
                      iconName={item.icon}
                    />
                  ),
              )}
            </div>
            <div className={otherFilters('section')}>
              <h4 className={otherFilters('section-title')}>На территории</h4>
              {iconsList.map(
                (item) =>
                  item.typeFilters === 'TheTerritory' && (
                    <Checkbox
                      checked={
                        features
                          ? features.some((featuresTerritory) => featuresTerritory.id === item.id)
                          : false
                      }
                      key={item.title}
                      externalClassName={otherFilters('filters')}
                      handleExternal={() =>
                        dispatch(setFeatures({ apiKey: item.apiKey, id: item.id }))
                      }
                      text={item.title}
                      iconName={item.icon}
                    />
                  ),
              )}
            </div>
          </div>
          <div className={otherFilters('column')}>
            <div className={otherFilters('section')}>
              <h4 className={otherFilters('section-title')}>В квартире</h4>
              {iconsList.map(
                (item) =>
                  item.typeFilters === 'theApartment' && (
                    <Checkbox
                      checked={
                        features
                          ? features.some((featuresApartment) => featuresApartment.id === item.id)
                          : false
                      }
                      key={item.title}
                      externalClassName={otherFilters('filters')}
                      handleExternal={() =>
                        dispatch(setFeatures({ apiKey: item.apiKey, id: item.id }))
                      }
                      text={item.title}
                      iconName={item.icon}
                    />
                  ),
              )}
            </div>
          </div>
        </div>
        <div className={otherFilters('buttons')}>
          <Button
            text={`Показать ${metaCount ? `${metaCount} вариантов` : ''}`}
            externalClassName={otherFilters('modal-button')}
            handleExternal={handleCloseModal}
          />
          <Button
            text="Сбросить фильтр"
            externalClassName={otherFilters('modal-button')}
            transparent
            handleExternal={() => dispatch(setClearAllAdditionalFilters())}
          />
        </div>
      </div>
    </div>
  );
};

export default OtherFilters;
