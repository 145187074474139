import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setTypeOpenSearchModal } from '../../store/reducers/modal/modalSearchSlice';

import SearchModal from '../SearchModal';
import Icon from '../../UI/Icon';

import { toStringGuests, toStringRooms, toStringCalendar } from '../../utils/searchUtils';
import { CALENDAR, GUESTS, ROOMS, CITY } from '../../app_constants';
import { CityDataItem } from '../../app_interfaces';
import { ISerchField } from './interface';
import bemClassName from '../../utils/bem';

import './index.scss';
import CatalogData from '../../API/CatalodData';
import { setCityCoords } from '../../store/reducers/search/searchDataSlice';
// import { setCitySelected } from '../../store/reducers/search/searchDataSlice';

const searchField = bemClassName('search-field');

const SearchField: React.FC<ISerchField> = ({
  title,
  placeholder,
  // inputValue,
  textValue,
  type,
  action,
  data,
  booking,
}) => {
  const [newData, setNewData] = useState<CityDataItem[]>([]);
  const [inputValue, setInputValue] = useState<string | null>(null);
  const searchFieldInput = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const windowWidth = useSelector((state: RootState) => state.window.windowWidth);
  const cityData = useSelector((state: RootState) => state.appData.cityData);
  const citySelected = useSelector((state: RootState) => state.searchData.citySelected);

  const typeOpenSearchModal = useSelector(
    (state: RootState) => state.modalSearch.typeOpenSearchModal,
  );

  const isEmpty =
    (type === CITY && inputValue === null) || (textValue !== undefined && textValue === null);

  function handleClickSearchField() {
    if (type === CITY) {
      if (inputValue === null || inputValue === '') {
        // dispatch(action(null));
        setInputValue('');
      }
      if (windowWidth >= 1024) {
        searchFieldInput.current?.focus();
      }
    }
    dispatch(setTypeOpenSearchModal(type));
  }

  function handleBlurkSearchField(event: React.FocusEvent) {
    event.stopPropagation();
    if (inputValue === '') {
      dispatch(action(null));
      setInputValue(null);
    }
  }

  function handleChangeSearchFieldInput() {
    // dispatch(action(searchFieldInput.current?.value));
    if (data !== undefined && searchFieldInput.current !== null) {
      setInputValue(searchFieldInput.current?.value);
      const filteredDeta = data.filter((item) =>
        item.name.toLowerCase().startsWith(searchFieldInput.current!.value.toLowerCase()),
      );
      setNewData([...filteredDeta]);
    }
  }

  function handleClearSearchField() {
    dispatch(action(null));
    setInputValue(null);
    if (data !== undefined) {
      setNewData(data);
    }
  }

  useEffect(() => {
    if (typeOpenSearchModal !== CITY && inputValue === '') {
      dispatch(action(null));
      setInputValue(null);
    }
  }, [typeOpenSearchModal]);

  useEffect(() => {
    if (data !== undefined) {
      setNewData(data);
    }
  }, [data]);

  useEffect(() => {
    if (type === CITY) {
      if (inputValue === null || inputValue === '') {
        // dispatch(action(null))
      } else if (
        type === CITY &&
        cityData.find((item) => inputValue?.toLowerCase() === item.name.toLowerCase())
      ) {
        dispatch(
          action(cityData.find((item) => inputValue?.toLowerCase() === item.name.toLowerCase())),
        );
      }
    }
  }, [inputValue]);

  useEffect(() => {
    if (citySelected && type === CITY) {
      setInputValue(citySelected.name);
      CatalogData.getCityCoords({ cityId: citySelected.id }).then((resp) =>
        dispatch(setCityCoords(resp)),
      );
    }
  }, [citySelected]);

  return (
    <div
      className={searchField({ calendar: !!(type === CALENDAR), booking })}
      onBlur={(event) => handleBlurkSearchField(event)}>
      <div className={searchField('inner')} onClick={() => handleClickSearchField()}>
        <p className={searchField('title', { empty: isEmpty })}>{isEmpty ? title : placeholder}</p>
        {type === CITY && (
          <input
            ref={searchFieldInput}
            onChange={handleChangeSearchFieldInput}
            className={searchField('input', { hide: inputValue === null, booking })}
            type="text"
            value={inputValue ?? ''}
          />
        )}
        {textValue !== undefined && (
          <p className={searchField('input', { hide: textValue === null, booking })}>
            {type === GUESTS && textValue !== null && Array.isArray(textValue) && (
              <>
                {toStringGuests(textValue[0], true)}
                <span className={searchField('dot-separator')} />
                {toStringGuests(textValue[1], false)}
              </>
            )}
            {type === ROOMS &&
              textValue !== null &&
              Array.isArray(textValue) &&
              toStringRooms(textValue)}

            {type === CALENDAR &&
              textValue !== null &&
              typeof textValue === 'string' &&
              toStringCalendar(textValue)}
          </p>
        )}
        {type === CITY && inputValue !== null && inputValue !== '' && (
          <Icon
            iconName="close"
            externalClassName={searchField('clear')}
            handleClick={handleClearSearchField}
          />
        )}
      </div>
      {type !== CALENDAR && typeOpenSearchModal === type && (
        <SearchModal type={type} data={newData} />
      )}
    </div>
  );
};

export default SearchField;
