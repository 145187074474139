import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store';
import { setCitySelected, setClearAllFilter } from '../../store/reducers/search/searchDataSlice';
import { fetchCItyData, fetchRoomsData } from '../../store/reducers/data/appDataSlice';
import {
  closeSearchModal,
  // setTypeOpenSearchModal,
} from '../../store/reducers/modal/modalSearchSlice';
// import { setTypeOpenSearchModal } from '../../store/reducers/modal/modalSearchSlice';
import SearchField from '../SearchField';
import Button from '../../UI/Button';

import { CALENDAR, CITY, GUESTS, ROOMS } from '../../app_constants';
import bemClassName from '../../utils/bem';

import './index.scss';
import SearchModal from '../SearchModal';
import { setClearAllAdditionalFilters } from '../../store/reducers/search/otherFiltersDataSlice';

const search = bemClassName('search');

const Search = ({ modal, handleExternal }: { modal?: boolean; handleExternal?: any }) => {
  const citySelected = useSelector((state: RootState) => state.searchData.citySelected);
  const guests = useSelector((state: RootState) => state.searchData.guests);
  const rooms = useSelector((state: RootState) => state.searchData.rooms);
  const cityData = useSelector((state: RootState) => state.appData.cityData);
  const calendarSelected = useSelector((state: RootState) => state.searchData.calendarSelected);
  const windowWidth = useSelector((state: RootState) => state.window.windowWidth);

  const searchFieldsList = useRef<HTMLInputElement>(null);
  const searchFieldsListCalendar = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const navigate = useNavigate();
  const location = useLocation();

  const typeOpenSearchModal = useSelector(
    (state: RootState) => state.modalSearch.typeOpenSearchModal,
  );
  const handleSearchClick = () => {
    const params = new URLSearchParams();
    if (rooms) {
      params.set('rooms', rooms.toString());
    }
    if (guests) {
      params.set('guests', guests.toString());
    }
    if (calendarSelected && calendarSelected.checkIn) {
      const checkIn = new Date(calendarSelected.checkIn);
      const checkInFormated = `${checkIn.getFullYear()}-${
        checkIn.getMonth() + 1
      }-${checkIn.getDate()}`;
      params.set('checkIn', checkInFormated.toString());
    }
    if (calendarSelected && calendarSelected.departure) {
      const departure = new Date(calendarSelected.departure);
      const departureFormated = `${departure.getFullYear()}-${
        departure.getMonth() + 1
      }-${departure.getDate()}`;
      params.set('departure', departureFormated.toString());
    }
    if (location.pathname === '/' && citySelected !== null) {
      navigate(`/catalog/${citySelected.name}${params ? `?${params}` : ''}`);
    }
  };

  const handleCloseSearchModal = () => {
    if (location.pathname.startsWith('/catalog/')) {
      handleExternal();
    }
  };

  useEffect(() => {
    dispatch(fetchCItyData());
    dispatch(fetchRoomsData());
    const onClick = (event: any) =>
      searchFieldsList.current?.contains(event.target) ||
      searchFieldsListCalendar.current?.contains(event.target) ||
      dispatch(closeSearchModal());
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  useEffect(() => {
    if (location.pathname === '/') {
      dispatch(setClearAllFilter());
      dispatch(setClearAllAdditionalFilters());
    }
    // if (location.pathname === '/') {
    //   console.log('ddddd');
    //   if (windowWidth <= 959) {
    //     dispatch(closeSearchModal());
    //   } else if (typeOpenSearchModal !== CALENDAR) {
    //     dispatch(setTypeOpenSearchModal(CALENDAR));
    //   }
    // } else if (typeOpenSearchModal === CALENDAR) {
    //   console.log('wwwww');
    //   dispatch(closeSearchModal());
    // }
  }, [windowWidth, location.pathname]);

  return (
    <div className={search({ modal })} ref={searchFieldsListCalendar}>
      <div ref={searchFieldsList} className={search('fields-list', { modal })}>
        <SearchField
          title="Город"
          placeholder="Город"
          inputValue={citySelected ? citySelected.name : null}
          action={setCitySelected}
          type={CITY}
          data={cityData}
        />
        <SearchField title="Комнаты" placeholder="Комнаты" textValue={rooms} type={ROOMS} />
        <SearchField
          title="Заезд"
          placeholder="Заезд"
          textValue={calendarSelected === null ? calendarSelected : calendarSelected.checkIn}
          type={CALENDAR}
        />
        <SearchField
          title="Отъезд"
          placeholder="Отъезд"
          textValue={calendarSelected === null ? calendarSelected : calendarSelected.departure}
          type={CALENDAR}
        />
        <SearchField title="Гости" placeholder="Гости" textValue={guests} type={GUESTS} />
        {!modal && (
          <Button
            text="Найти"
            externalClassName={search('button')}
            handleExternal={handleSearchClick}
            search
          />
        )}
      </div>
      {modal && (
        <Button
          text="Найти"
          externalClassName={search('button', { modal })}
          handleExternal={handleCloseSearchModal}
          search
        />
      )}
      {typeOpenSearchModal === CALENDAR && <SearchModal type={CALENDAR} data={[]} />}
    </div>
  );
};

export default Search;
