import { AxiosError } from 'axios';
import axiosInterseptor from '../../utils/axiosInterseptor';

export default class CatalogData {
  static async getPage({
    page,
    cityId,
    order,
    filters,
    signal,
  }: {
    page: number;
    cityId: number;
    order?: string;
    filters?: string;
    signal?: AbortSignal;
  }) {
    const params = {
      id: cityId,
      order,
      filters,
      mode: 'search',
      page,
    };

    try {
      const response = await axiosInterseptor.get('/products/city', {
        params,
        signal,
      });

      const { data } = response;

      return data;
    } catch (error: any) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.details
      ) {
        throw new URIError(error.response.data.error.details);
      } else if (error.code === 'ERR_CANCELED') {
        throw new AxiosError('cancel', error.code);
      } else {
        throw new URIError('failed to load');
      }
    }
  }

  static async getAll({
    cityId,
    order,
    filters,
    signal,
  }: {
    cityId: number;
    order?: string;
    filters?: string;
    signal?: AbortSignal;
  }) {
    const params = {
      id: cityId,
      order,
      filters,
      mode: 'search',
    };

    try {
      const response = await axiosInterseptor.get('/products/city', {
        params,
        signal,
      });

      const { data } = response;

      return data;
    } catch (error: any) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.details
      ) {
        throw new URIError(error.response.data.error.details);
      } else if (error.code === 'ERR_CANCELED') {
        throw new AxiosError('cancel', error.code);
      } else {
        throw new URIError('failed to load');
      }
    }
  }

  static async getApartament({
    apartamentId,
    begin,
    end,
    cashback,
  }: {
    apartamentId: string;
    begin?: string;
    end?: string;
    cashback?: boolean;
  }) {
    const params = {
      id: apartamentId,
      begin,
      end,
      cashback,
    };

    const response = await axiosInterseptor.get(`/products/product/${apartamentId}/data`, {
      params,
    });

    const { data } = response;
    return data;
  }

  static async getAreas({ cityId }: { cityId: number }) {
    try {
      const params = {
        id: cityId,
      };
      const response = await axiosInterseptor.get(`/addreses/city/${cityId}/areas`, {
        params,
      });
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getCityCoords({ cityId }: { cityId: number }) {
    try {
      const params = {
        id: cityId,
      };

      const response = await axiosInterseptor.get(`/addreses/city/${cityId}/coords`, {
        params,
      });
      const { data } = response;
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getPublicFavorite(
    id: Array<number>,
    page: number,
    signal: AbortSignal,
    cityId?: number,
  ) {
    if (axiosInterseptor) {
      let url = `/products/select?mode=filter&products=${JSON.stringify(id)}&page=${page}`;
      if (cityId !== undefined) {
        url += `&filters={"city":${cityId}}`;
      }
      try {
        const response = await axiosInterseptor.get(url, { signal });
        const { data } = response;
        return data;
      } catch (error) {
        return error;
      }
    }
    return null;
  }
}
