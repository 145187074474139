import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setWindowWidth } from '../../store/reducers/window/windowSlice';
import { setFavorites } from '../../store/reducers/profile/favouritesSlice';
import { closeMenuModal } from '../../store/reducers/modal/modalMenuSlice';

import Header from '../../components/Header';
import Main from '../../components/Main';
import Footer from '../../components/Footer';
import ModalMenu from '../../components/ModalMenu';

import Home from '../../pages/Home';
import Catalog from '../../pages/Catalog';
import CatalogDetail from '../../pages/CatalogDetail';
import Account from '../../pages/Account';
import Reviews from '../../pages/Reviews';
import Services from '../../pages/Services';
import Weather from '../../pages/Weather';
import Auth from '../../pages/Auth';

import './index.scss';
import {
  logout,
  setAccessToken,
  setExpires,
  setLogin,
  setVerified,
} from '../../store/reducers/profile/autorizationStatutsSlice';
import { RootState } from '../../store';
import axiosInterseptor from '../../utils/axiosInterseptor';
import { BASE_API_URL_SECRET, CALENDAR } from '../../app_constants';
import PrivateData from '../../API/PrivateData';
import { setTotalBasketPrice } from '../../store/reducers/data/basketDataSlice';
import Public from '../../API/Public';
import {
  setContacntHelperPhones,
  setContactEMail,
  setContactPhones,
} from '../../store/reducers/data/contactDataSlice';
import {
  closeSearchModal,
  setTypeOpenSearchModal,
} from '../../store/reducers/modal/modalSearchSlice';
import Payment from '../../pages/Payment';

function AppRouter() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const isLogin = useSelector((state: RootState) => state.autorizationStatus.isLogin);
  const favorites = useSelector((state: RootState) => state.favoritesData.favorites);
  const windowWidth = useSelector((state: RootState) => state.window.windowWidth);
  const typeOpenSearchModal = useSelector(
    (state: RootState) => state.modalSearch.typeOpenSearchModal,
  );

  useEffect(() => {
    if (location.pathname === '/') {
      if (windowWidth <= 959) {
        dispatch(closeSearchModal());
      } else if (typeOpenSearchModal !== CALENDAR) {
        dispatch(setTypeOpenSearchModal(CALENDAR));
      }
    } else if (typeOpenSearchModal === CALENDAR) {
      dispatch(closeSearchModal());
    }
  }, [windowWidth, location.pathname]);

  useEffect(() => {
    if (isLogin !== null && isLogin) {
      if (
        favorites &&
        favorites !== undefined &&
        axiosInterseptor.defaults.baseURL === BASE_API_URL_SECRET
      ) {
        PrivateData.getPrivateFavoriteAddedArrayId(favorites);
      }
    }
  }, [isLogin, favorites]);

  useEffect(() => {
    if (isLogin !== null && isLogin) {
      PrivateData.getPrivateFavoriteArrayId()
        .then((item) => {
          localStorage.removeItem('favourites');
          localStorage.setItem('favourites', JSON.stringify(item));
          dispatch(setFavorites(item));
          return item;
        })
        .catch(() => {
          return undefined;
        });
      PrivateData.getPrivateUser().then((item) => {
        if (item.passport.approved === true) {
          dispatch(setVerified());
        }
        if (item && item.pay) {
          dispatch(setTotalBasketPrice(item.pay));
        }
      });
    }
  }, [isLogin]);

  useEffect(() => {
    dispatch(closeMenuModal());
  }, [location.pathname]);

  useEffect(() => {
    Public.getContact()
      .then((items) => {
        if (items) {
          if (items.phones) {
            dispatch(setContactPhones(items.phones));
          }
          if (items.email) {
            dispatch(setContactEMail(items.email));
          }
          if (items.phones_help) {
            dispatch(setContacntHelperPhones(items.phones_help));
          }
        }
      })
      .catch((error: any) => console.error(error));

    if (location.pathname === '/catalog' || location.pathname === '/catalog/') {
      navigate('/catalog/Оренбург');
    }

    const storedToken = localStorage.getItem('access_token');
    if (storedToken) {
      dispatch(setAccessToken(storedToken));
      const expirationTime = localStorage.getItem('tokenExpirationTime');
      if (expirationTime) {
        dispatch(setExpires(Number(expirationTime)));
      }
      dispatch(setLogin());
    } else {
      dispatch(logout());
    }

    if (localStorage.favourites && localStorage.favourites.length !== 0) {
      dispatch(setFavorites(JSON.parse(localStorage.favourites)));
    } else if (localStorage.favourites === undefined) {
      localStorage.setItem('favourites', JSON.stringify([]));
    }

    function handleWindowResize() {
      dispatch(setWindowWidth(window.innerWidth));
    }

    dispatch(setWindowWidth(window.innerWidth));
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header />
      <Main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/catalog/:cityName/" element={<Catalog />} />
          <Route path="/catalog/:cityName/:id/" element={<CatalogDetail />} />
          <Route path="/account/" element={<Account />} />
          <Route path="/reviews/" element={<Reviews />} />
          <Route path="/services/" element={<Services />} />
          <Route path="/weather/" element={<Weather />} />
          <Route path="/payment/" element={<Payment />} />
          {/* <Route path="/catalog/city/:k/" element={<Catalog />} />
          <Route path="/catalog/city/:k/:id/" element={<CatalogDetail />} />
          <Route path="/account/page/" element={<Account />} /> */}
        </Routes>
      </Main>
      <Footer />
      <ModalMenu />
    </>
  );
}

export default AppRouter;
