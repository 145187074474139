import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import Icon from '../Icon';
import { IPagination } from './interface';
import usePagination from '../../hooks/usePagination';
import bemClassName from '../../utils/bem';

import './index.scss';

const pagination = bemClassName('pagination');

const Pagination: React.FC<IPagination> = ({
  totalPageCount,
  currentPage,
  externalClassName = '',
  handleExternal,
}) => {
  const [page, setPage] = useState(currentPage || 1);
  const windowWidth = useSelector((state: RootState) => state.window.windowWidth);

  const paginationRange = usePagination({
    totalPageCount,
    siblingCount: windowWidth >= 600 ? 1 : 0,
    currentPage: page,
  });

  const handleClickNext = () => {
    if (totalPageCount !== page) {
      setPage(page + 1);
    }
  };

  const handleClickPrevious = () => {
    if (page !== 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    if (currentPage !== undefined && currentPage !== page && currentPage) {
      setPage(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    if (handleExternal) {
      handleExternal(page);
    }
  }, [page]);

  return (
    <ul className={`${pagination()} ${externalClassName}`}>
      <li
        className={pagination('btn', {
          prev: true,
          disable: page === 1,
        })}
        onClick={handleClickPrevious}>
        <Icon iconName="arrow" externalClassName={pagination('arrow', { prev: true })} />
      </li>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === 'DOTS') {
          return (
            <li className={pagination('btn', { disable: true })} key={index}>
              ...
            </li>
          );
        }
        return (
          <li
            className={pagination('btn', {
              active: page === pageNumber,
            })}
            key={index}
            onClick={() => setPage(pageNumber)}>
            {pageNumber}
          </li>
        );
      })}
      <li
        className={pagination('btn', {
          next: true,
          disable: page === totalPageCount,
        })}
        onClick={handleClickNext}>
        <Icon iconName="arrow" externalClassName={pagination('arrow', { next: true })} />
      </li>
    </ul>
  );
};

export default Pagination;
