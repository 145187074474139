import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import bemClassName from '../../utils/bem';
import './index.scss';
import Icon from '../../UI/Icon';
import MessageData from '../../API/MessageData';
import Loading from '../../UI/Loading';
import formatedDateOfStay from '../../utils/historyBookingsUtils';

const chat = bemClassName('chat');

const Chat = ({ chat_id }: { chat_id: string }) => {
  const [chatData, setChatData] = useState<any>();
  const [messages, setMessages] = useState<{ text: string; mine?: boolean; file?: File }[]>([]);
  const [lastMessageId, setLastMessageId] = useState<number>();
  const [inputValue, setInputValue] = useState('');

  const [file, setFile] = useState<File | undefined>(undefined);
  const [responseFile, setResponseFile] = useState<any>();

  const [firstRequest, setFirstRequest] = useState<boolean>(false);
  const [isLoadingChat, setIsLoadingChat] = useState<boolean>(false);
  const [reachedTop, setReachedTop] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const [containerHeight, setContainerHeight] = useState<number>(0);

  let parsedHTML: any;

  const parsingFile = (text: any) => {
    if (text !== '') {
      parsedHTML = parse(text);
    }
    return parsedHTML;
  };

  const handleMessageSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (inputValue.trim() !== '') {
      setMessages([...messages, { text: inputValue, mine: true }]);
      setInputValue('');
      setFile(undefined);
      if (chat_id) {
        MessageData.postAddedMessage(chat_id, inputValue, responseFile).then((item) => {
          setLastMessageId(item.id);
        });
      }
    }
  };

  const handleFileSubmit = () => {
    if (file) {
      setMessages([...messages, { text: '', mine: true, file }]);
      MessageData.postAddedMedia(file).then((item) => {
        setResponseFile(item);
      });
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handlerScrollTo = () => {
    // if (messagesEndRef && messagesEndRef.current) {
    //   messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    // }
    if (messagesEndRef && messagesEndRef.current) {
      const newScrollTop = messagesEndRef.current.scrollHeight - containerHeight;
      messagesEndRef.current.scrollTop = newScrollTop > 0 ? newScrollTop : 0;
    }
  };

  const handleScrollMaxTop = () => {
    if (messagesEndRef.current) {
      const { scrollTop } = messagesEndRef.current;
      if (scrollTop === 0 && !reachedTop) {
        setReachedTop(true);
      }
    }
  };

  const newMessage = () => {
    if (chat_id) {
      if (lastMessageId) {
        MessageData.getNewMessages(chat_id, lastMessageId).then((item) => {
          if (item) {
            const result: any = Object.values(item);
            result.map((items: any) => {
              const lastItem: any = result[result.length - 1].id;
              if (lastItem) {
                setLastMessageId(lastItem);
              }
              if (items.incoming === true) {
                setMessages((prevMessages) => [
                  ...prevMessages,
                  { text: items.message, mine: true },
                ]);
              } else {
                setMessages((prevMessages) => [
                  ...prevMessages,
                  { text: items.message, mine: false },
                ]);
              }
              return items;
            });
          }
        });
      }
    }
  };

  useEffect(() => {
    handleFileSubmit();
  }, [file]);

  useEffect(() => {
    handlerScrollTo();
  }, [messages, containerHeight]);

  useEffect(() => {
    if (chat_id && reachedTop) {
      const abortControllerOldMessages = new AbortController();
      setIsLoadingChat(true);
      const container = messagesEndRef.current;
      MessageData.getMessageDataСhat({
        id: chat_id,
        offset: messages.length,
        signal: abortControllerOldMessages.signal,
      }).then((items) => {
        if (container) {
          setContainerHeight(container.scrollHeight);
        }
        if (items) {
          const result = items.messages;

          result.map((item: any) => {
            if (item.incoming === true) {
              if (items.message !== '') {
                setMessages((prevMessages) => {
                  prevMessages.unshift({ text: item.message, mine: true });
                  return [...prevMessages];
                });
              }
            } else if (items.message !== '') {
              setMessages((prevMessages) => {
                prevMessages.unshift({ text: item.message, mine: false });
                return [...prevMessages];
              });
            }
            return item;
          });
          setIsLoadingChat(false);
        }
      });
      setReachedTop(false);
      // return () => {
      //   abortControllerOldMessages.abort();
      // };
    }
    return undefined;
  }, [reachedTop, chat_id, messages]);

  useEffect(() => {
    newMessage();
    const interval = setInterval(newMessage, 5000);

    return () => clearInterval(interval);
  }, [lastMessageId, chat_id]);

  useEffect(() => {
    setFirstRequest(true);
    setIsLoading(true);
    if (chat_id && firstRequest) {
      MessageData.getMessageDataСhat({ id: chat_id }).then((item) => {
        setIsLoading(false);
        setChatData(item);
        if (item && item.messages && item.messages.length > 0) {
          setLastMessageId(item.messages[item.messages.length - 1].id);
        }
        if (item) {
          const result = item.messages;

          if (result) {
            result.map((items: any) => {
              if (items.incoming === true) {
                if (items.message !== '') {
                  setMessages((prevMessages) => [
                    ...prevMessages,
                    { text: items.message, mine: true },
                  ]);
                }
              } else if (items.message !== '') {
                setMessages((prevMessages) => [
                  ...prevMessages,
                  { text: items.message, mine: false },
                ]);
              }
              return items;
            });
          }
        }
      });
    }
  }, [chat_id, firstRequest]);

  return !isLoading ? (
    <div className={chat()}>
      {chatData && (
        <Link to="/account#messages" className={chat('item')}>
          <Icon externalClassName={chat('back')} iconName="back_chat" />
          {chatData.photos && (
            <picture className={chat('picture')}>
              <img
                className={chat('image')}
                src={`${chatData.photos_url}/${chatData.photos[0]}-298x298.jpg`}
                srcSet={`${chatData.photos_url}/${chatData.photos[0]}-596x596.jpg 2x, ${chatData.photos_url}/${chatData.photos[0]}-894x894.jpg 3x`}
                alt=""
              />
            </picture>
          )}
          <div className={chat('preview')}>
            <h4 className={chat('preview-title')}>
              <span className={chat('preview-title-text')}>{chatData.title}</span>
              <span className={chat('preview-title-info')}>
                <span className={chat('separator')} />
                <span className={chat('description')}>{chatData.city}</span>
                <span className={chat('separator')} />
                {chatData.dates === null ? undefined : (
                  <span className={chat('description')}>
                    {formatedDateOfStay(chatData.created_at, chatData.updated_at)}
                  </span>
                )}
              </span>
            </h4>
          </div>
        </Link>
      )}
      <div onScroll={handleScrollMaxTop} ref={messagesEndRef} className={chat('messages')}>
        {isLoadingChat && (
          <Loading externalClassName={chat('loading-message', { firstrender: !chatData })} />
        )}
        {messages.map((message, index) => (
          <div key={index} className={chat('message', { mine: message.mine })}>
            {!message.file && parsingFile(message.text)}
            {message.file && (
              <div className={chat('media-file')}>
                {message.file.type.startsWith('image/') ? (
                  <img
                    className={chat('media-img')}
                    src={URL.createObjectURL(message.file)}
                    alt={message.file.name}
                  />
                ) : (
                  <a href={URL.createObjectURL(message.file)} download={message.file.name}>
                    {message.file.name}
                  </a>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      <form onSubmit={handleMessageSubmit} className={chat('send-message')}>
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className={chat('write')}
          placeholder="Напишите сообщение..."
        />
        <div className={chat('file')}>
          <label htmlFor="input__file">
            <Icon externalClassName={chat('attach-icon')} iconName="attach" />
            <input
              id="input__file"
              className={chat('file-input')}
              type="file"
              onChange={handleFileChange}
            />
          </label>
        </div>
        <button type="submit">
          <Icon externalClassName={chat('send')} iconName="send" />
        </button>
      </form>
    </div>
  ) : (
    <Loading externalClassName={chat('loading')} />
  );
};

export default Chat;
