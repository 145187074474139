import bemClassName from '../../utils/bem';

import './index.scss';

const main = bemClassName('main');

const Main = ({ children }: React.PropsWithChildren) => {
  return <main className={main()}>{children}</main>;
};

export default Main;
