import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import Validate from '../../../API/Validate';

export const fetchCheckEmailValidate = createAsyncThunk(
  'data/fetchCheckEmailValidate',
  Validate.checkEmail,
);

export const fetchHelperEmailValidate = createAsyncThunk(
  'data/fetchHelperEmailValidate',
  Validate.helperEmail,
);

export interface DataState {
  emailIsValid: boolean | null;
  emailErrorText: string | null;
  emailDomainArray: Array<any> | null;
}

const initialState: DataState = {
  emailIsValid: null,
  emailErrorText: null,
  emailDomainArray: null,
};

export const emailValidateSlice = createSlice({
  name: 'emailValidate',
  initialState,
  reducers: {
    setDefaultValueEmailValidate: (state) => {
      state.emailIsValid = null;
      state.emailErrorText = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchCheckEmailValidate.fulfilled,
      (state, action: PayloadAction<boolean | string>) => {
        if (typeof action.payload === 'boolean') {
          state.emailIsValid = action.payload;
          state.emailErrorText = '';
        } else if (typeof action.payload === 'string') {
          state.emailIsValid = false;
          state.emailErrorText = action.payload;
        }
      },
    );
    builder.addCase(
      fetchHelperEmailValidate.fulfilled,
      (state, action: PayloadAction<Array<any>>) => {
        state.emailDomainArray = Object.values(action.payload);
      },
    );
  },
});

export const { setDefaultValueEmailValidate } = emailValidateSlice.actions;

export default emailValidateSlice.reducer;
