import { Swiper, SwiperSlide } from 'swiper/react';
import { useDispatch } from 'react-redux';
import { Grid } from 'swiper';
import { Link } from 'react-router-dom';

import bemClassName from '../../utils/bem';
// import orenburg from '../../assets/images/orenburg.jpg';
// import samara from '../../assets/images/samara.jpg';
// import ufa from '../../assets/images/ufa.jpg';
// import kazan from '../../assets/images/kazan.jpg';
// import toliaty from '../../assets/images/toliaty.jpg';
// import saratov from '../../assets/images/saratov.jpg';

import 'swiper/scss';
import 'swiper/scss/grid';
import './index.scss';
import { setCitySelected } from '../../store/reducers/search/searchDataSlice';

const city = bemClassName('city');

interface ICity {
  data: any;
}

const City: React.FC<ICity> = ({ data }) => {
  const dispatch = useDispatch();

  return (
    <div className={city()}>
      <Swiper
        className={city('carousel')}
        spaceBetween={10}
        slidesPerView="auto"
        modules={[Grid]}
        loop
        breakpoints={{
          768: {
            spaceBetween: 20,
          },
          1025: {
            spaceBetween: 30,
            slidesPerView: 3,
            loop: false,
            grid: {
              fill: 'row',
              rows: 2,
            },
            enabled: false,
          },
        }}>
        {data &&
          data.map((items: any) => {
            return (
              <SwiperSlide key={items.id} style={{ backgroundImage: `url(${items.photo})` }}>
                <Link
                  onClick={() =>
                    dispatch(
                      setCitySelected({
                        id: items.id,
                        name: items.name,
                        population: items.population,
                      }),
                    )
                  }
                  className={city('link')}
                  to={`catalog/${items.name}`}>
                  <h2 className={city('item-title')}>{items.name}</h2>
                  <hr className={city('item-line')} />
                  <p className={city('info')}>
                    <span className={city('info-count')}>{items.products_count}</span>квартир
                  </p>
                  <p className={city('info')}>
                    <span className={city('info-count')}>{items.free_count}</span>готовы к заселению
                  </p>
                </Link>
              </SwiperSlide>
            );
          })}

        {/* <SwiperSlide style={{ backgroundImage: `url(${samara})` }}>
          <Link className={city('link')} to="#t">
            <h2 className={city('item-title')}>Самара</h2>
            <hr className={city('item-line')} />
            <p className={city('info')}>
              <span className={city('info-count')}>800</span>квартир
            </p>
            <p className={city('info')}>
              <span className={city('info-count')}>128</span>готовы к заселению
            </p>
          </Link>
        </SwiperSlide>
        <SwiperSlide style={{ backgroundImage: `url(${ufa})` }}>
          <Link className={city('link')} to="#t">
            <h2 className={city('item-title')}>Уфа</h2>
            <hr className={city('item-line')} />
            <p className={city('info')}>
              <span className={city('info-count')}>800</span>квартир
            </p>
            <p className={city('info')}>
              <span className={city('info-count')}>128</span>готовы к заселению
            </p>
          </Link>
        </SwiperSlide>
        <SwiperSlide style={{ backgroundImage: `url(${kazan})` }}>
          <Link className={city('link')} to="#t">
            <h2 className={city('item-title')}>Казань</h2>
            <hr className={city('item-line')} />
            <p className={city('info')}>
              <span className={city('info-count')}>800</span>квартир
            </p>
            <p className={city('info')}>
              <span className={city('info-count')}>128</span>готовы к заселению
            </p>
          </Link>
        </SwiperSlide>
        <SwiperSlide style={{ backgroundImage: `url(${toliaty})` }}>
          <Link className={city('link')} to="#t">
            <h2 className={city('item-title')}>Тольятти</h2>
            <hr className={city('item-line')} />
            <p className={city('info')}>
              <span className={city('info-count')}>800</span>квартир
            </p>
            <p className={city('info')}>
              <span className={city('info-count')}>128</span>готовы к заселению
            </p>
          </Link>
        </SwiperSlide>
        <SwiperSlide style={{ backgroundImage: `url(${saratov})` }}>
          <Link className={city('link')} to="#t">
            <h2 className={city('item-title')}>Саратов</h2>
            <hr className={city('item-line')} />
            <p className={city('info')}>
              <span className={city('info-count')}>800</span>квартир
            </p>
            <p className={city('info')}>
              <span className={city('info-count')}>128</span>готовы к заселению
            </p>
          </Link>
        </SwiperSlide> */}
      </Swiper>
    </div>
  );
};

export default City;
