import Container from '../../UI/Container';
import Logo from '../Logo';
import HeaderLinks from '../HeaderLinks';

import bemClassName from '../../utils/bem';

import './index.scss';

const header = bemClassName('header');

const Header = () => {
  return (
    <header className={header()}>
      <Container>
        <div className={header('inner')}>
          <Logo />
          <HeaderLinks />
        </div>
      </Container>
    </header>
  );
};

export default Header;
