import { createSlice } from '@reduxjs/toolkit';

export interface DataState {
  isOpen: boolean;
}

const initialState: DataState = {
  isOpen: false,
};

export const modalMenuSlice = createSlice({
  name: 'modalMenu',
  initialState,
  reducers: {
    toggleMenuModal: (state) => {
      state.isOpen = !state.isOpen;
      if (window.innerWidth < 1025 && state.isOpen === true) {
        document.body.style.overflow = 'hidden';
      } else if (document.body.style.overflow === 'hidden' && state.isOpen === false) {
        document.body.removeAttribute('style');
      }
    },
    closeMenuModal: (state) => {
      state.isOpen = false;
      document.body.removeAttribute('style');
    },
  },
});

export const { toggleMenuModal, closeMenuModal } = modalMenuSlice.actions;

export default modalMenuSlice.reducer;
