import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface FloorSliderValue {
  minValue: number;
  maxValue: number;
}

interface AreaSliderValue {
  minValue: number;
  maxValue: number;
}

interface Features {
  id: number;
  apiKey: string;
}

export interface DataState {
  sliderStartFloorValue: FloorSliderValue | null;
  sliderStartAreaValue: AreaSliderValue | null;
  sliderFloorValue: Array<number> | null;
  sliderAreaValue: Array<number> | null;
  bedsValue: number | null;
  doubleBedsValue: number | null;
  features: Array<Features> | null;
  metaCount: number | null;
  selectedAreas: Array<number> | null;
}

const initialState: DataState = {
  sliderStartFloorValue: null,
  sliderStartAreaValue: null,
  sliderFloorValue: null,
  sliderAreaValue: null,
  bedsValue: null,
  doubleBedsValue: null,
  features: null,
  metaCount: null,
  selectedAreas: null,
};

export const otherFiltersDataSlice = createSlice({
  name: 'otherFiltersDataSlice',
  initialState,
  reducers: {
    setClearAllAdditionalFilters: (state) => {
      state.sliderAreaValue = null;
      state.sliderFloorValue = null;
      state.features = null;
      state.bedsValue = null;
      state.doubleBedsValue = null;
      state.selectedAreas = null;
    },
    setStartSliderFloorValue: (state, action: PayloadAction<Array<number> | null>) => {
      if (action.payload) {
        state.sliderStartFloorValue = {
          minValue: action.payload[0],
          maxValue: action.payload[1],
        };
      }
    },
    setStartSliderAreaValue: (state, action: PayloadAction<Array<number> | null>) => {
      if (action.payload) {
        state.sliderStartAreaValue = {
          minValue: action.payload[0],
          maxValue: action.payload[1],
        };
      }
    },
    setSliderFloorValue: (state, action: PayloadAction<Array<number> | null>) => {
      if (state.sliderStartFloorValue) {
        if (
          action.payload &&
          state.sliderStartFloorValue.maxValue === action.payload[1] &&
          state.sliderStartFloorValue.minValue === action.payload[0]
        ) {
          state.sliderFloorValue = null;
        } else {
          state.sliderFloorValue = action.payload;
        }
      }
    },
    setSliderAreaValue: (state, action: PayloadAction<Array<number> | null>) => {
      if (state.sliderStartAreaValue) {
        if (
          action.payload &&
          state.sliderStartAreaValue.maxValue === action.payload[1] &&
          state.sliderStartAreaValue.minValue === action.payload[0]
        ) {
          state.sliderAreaValue = null;
        } else {
          state.sliderAreaValue = action.payload;
        }
      }
    },
    setBudsValue: (state, action: PayloadAction<number>) => {
      state.bedsValue = action.payload;
    },
    setDoubleBudsValue: (state, action: PayloadAction<number | null>) => {
      state.doubleBedsValue = action.payload;
    },
    setFeatures: (state, action: PayloadAction<Features>) => {
      if (action.payload !== null) {
        if (state.features === null) {
          state.features = [];
          state.features.push(action.payload);
        } else if (state.features.find((item) => item.id === action.payload.id)) {
          state.features = [...state.features.filter((item) => item.id !== action.payload.id)];
          if (state.features.length === 0) {
            state.features = null;
          }
        } else {
          state.features.push(action.payload);
          state.features = [...state.features.sort()];
        }
      } else {
        state.features = action.payload;
      }

      // state.features = state.features ? [...state.features, ...action.payload] : action.payload;
    },
    setMetaCount: (state, action: PayloadAction<number>) => {
      state.metaCount = action.payload;
    },
    setSelectedAreas: (state, action: PayloadAction<number | null>) => {
      if (action.payload !== null) {
        if (state.selectedAreas === null) {
          state.selectedAreas = [];
          state.selectedAreas.push(action.payload);
        } else if (state.selectedAreas.includes(action.payload)) {
          state.selectedAreas = [...state.selectedAreas.filter((item) => item !== action.payload)];
          if (state.selectedAreas.length === 0) {
            state.selectedAreas = null;
          }
        } else {
          state.selectedAreas.push(action.payload);
          state.selectedAreas = [...state.selectedAreas.sort()];
        }
      } else {
        state.selectedAreas = action.payload;
      }
    },
  },
});

export const {
  setStartSliderFloorValue,
  setStartSliderAreaValue,
  setSliderFloorValue,
  setSliderAreaValue,
  setClearAllAdditionalFilters,
  setBudsValue,
  setDoubleBudsValue,
  setFeatures,
  setMetaCount,
  setSelectedAreas,
} = otherFiltersDataSlice.actions;

export default otherFiltersDataSlice.reducer;
