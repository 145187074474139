export function toStringRooms(rooms: Array<number>) {
  return rooms.length >= 3
    ? `${rooms[0]} - ${rooms[rooms.length - 1]}`
    : rooms.map((item) => item.toString()).join(', ');
  // if (rooms.length >= 3) {
  //   return `${rooms[0]} - ${rooms[rooms.length - 1]}`;
  // } else {
  //   return rooms.map((item) => item.toString()).join(', ');
  // }
}

export function toStringGuests(guests: number, adults: boolean) {
  return adults
    ? `${guests} ${
        typeof guests === 'number' && (guests + 20) % 10 === 1 && guests !== 11
          ? ' Взрослый'
          : ' Взрослых'
      }`
    : `${guests} ${
        typeof guests === 'number' && (guests + 20) % 10 === 1 && guests !== 11
          ? 'Ребенок'
          : 'Детей'
      }`;
  // if (adults) {
  //   return `${guests} ${
  //     typeof guests === 'number' && (guests + 20) % 10 === 1 && guests !== 11
  //       ? ' Взрослый'
  //       : ' Взрослых'
  //   }`;
  // } else {
  //   return `${guests} ${
  //     typeof guests === 'number' && (guests + 20) % 10 === 1 && guests !== 11 ? 'Ребенок' : 'Детей'
  //   }`;
  // }
}

export function toStringCalendar(calendar: string) {
  return calendar
    ? new Date(calendar).toLocaleDateString('ru-RU', {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
      })
    : '';
  // if (calendar) {
  //   return new Date(calendar).toLocaleDateString('ru-RU', {
  //     weekday: 'short',
  //     day: 'numeric',
  //     month: 'short',
  //   });
  // }
}
