import { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import Icon from '../Icon';

import { ICheckbox } from './interface';
import bemClassName from '../../utils/bem';

import './index.scss';

const checkbox = bemClassName('checkbox');

const Checkbox: React.FC<ICheckbox> = ({
  text,
  linkText,
  checked,
  externalClassName = '',
  handleExternal,
  iconName,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = () => {
    setIsChecked(!isChecked);
    if (handleExternal) {
      handleExternal(!isChecked);
    }
  };

  useEffect(() => {
    if (checked !== undefined) {
      setIsChecked(checked);
    }
  }, [checked]);

  return (
    <label htmlFor={text} className={`${checkbox()} ${externalClassName}`}>
      <input id={text} className={checkbox('hiden')} type="checkbox" onChange={handleChange} />
      <span className={checkbox('custom', { checked: isChecked })} aria-hidden="true">
        {isChecked && <Icon iconName="check" externalClassName={checkbox('icon')} />}
      </span>
      {iconName && <Icon iconName={iconName} externalClassName={checkbox('text-icon')} />}
      <div className={checkbox('text-content')}>
        {text} {linkText && <Link to="/">{linkText}</Link>}
      </div>
    </label>
  );
};

export default Checkbox;
