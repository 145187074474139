import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CityDataItem } from '../../../app_interfaces';

interface ParamsPayload {
  calendarSelected: Calendar | null;
  guests: Array<number> | null;
  rooms: Array<number> | null;
  sliderPriceValue: Array<number> | null;
  isContactless: boolean;
  isBonus: boolean;
}

interface RangeSliderValue {
  minValue: number;
  maxValue: number;
}

export interface Calendar {
  checkIn: string | null;
  departure: string | null;
}
export interface ICityCoords {
  lat: number;
  lon: number;
}

export interface DataState {
  citySelected: CityDataItem | null;
  guests: Array<number> | null;
  rooms: Array<number> | null;
  sortSelected: string;
  currentPage: number | null;
  calendarSelected: Calendar | null;
  sliderStartPriceValue: RangeSliderValue | null;
  sliderPriceValue: Array<number> | null;
  isContactless: boolean;
  isBonus: boolean;
  cityCoords: ICityCoords | null;
}

const initialState: DataState = {
  citySelected: null,
  guests: null,
  rooms: null,
  sortSelected: 'Популярные',
  currentPage: null,
  calendarSelected: null,
  sliderStartPriceValue: null,
  sliderPriceValue: null,
  isContactless: false,
  isBonus: false,
  cityCoords: null,
};

export const searchDataSlice = createSlice({
  name: 'searchData',
  initialState,
  reducers: {
    setCitySelected: (state, action: PayloadAction<CityDataItem>) => {
      state.citySelected = action.payload;
    },
    setGuests: (state, action: PayloadAction<Array<number> | null>) => {
      state.guests = action.payload;
    },
    setAdults: (state, action: PayloadAction<number | null>) => {
      if (state.guests === null && action.payload !== null && action.payload !== 0) {
        state.guests = [0, 0];
        state.guests[0] = action.payload;
      } else if (state.guests && action.payload !== null) {
        if (action.payload === 0 && state.guests[1] === 0) {
          state.guests = null;
        } else {
          state.guests[0] = action.payload;
        }
      }
    },
    setKids: (state, action: PayloadAction<number | null>) => {
      if (state.guests === null && action.payload !== null && action.payload !== 0) {
        state.guests = [0, 0];
        state.guests[1] = action.payload;
      } else if (state.guests && action.payload !== null) {
        if (action.payload === 0 && state.guests[0] === 0) {
          state.guests = null;
        } else {
          state.guests[1] = action.payload;
        }
      }
    },
    setRooms: (state, action: PayloadAction<number | null>) => {
      if (action.payload !== null) {
        if (state.rooms === null) {
          state.rooms = [];
          state.rooms.push(action.payload);
        } else if (state.rooms.includes(action.payload)) {
          state.rooms = [...state.rooms.filter((item) => item !== action.payload)];
          if (state.rooms.length === 0) {
            state.rooms = null;
          }
        } else {
          state.rooms.push(action.payload);
          state.rooms = [...state.rooms.sort()];
        }
      } else {
        state.rooms = action.payload;
      }
    },
    setSortSelected: (state, action: PayloadAction<string>) => {
      state.sortSelected = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setParams: (state, action: PayloadAction<ParamsPayload>) => {
      state.rooms = action.payload.rooms;
      state.guests = action.payload.guests;
      state.calendarSelected = action.payload.calendarSelected;
      state.sliderPriceValue = action.payload.sliderPriceValue;
      state.isContactless = action.payload.isContactless;
      state.isBonus = action.payload.isBonus;
    },
    setCheckIn: (state, action: PayloadAction<string | null>) => {
      if (action.payload === null) {
        if (state.calendarSelected && state.calendarSelected.departure === null) {
          state.calendarSelected = null;
        } else if (state.calendarSelected) {
          state.calendarSelected.checkIn = action.payload;
        }
      } else if (!state.calendarSelected) {
        state.calendarSelected = {
          checkIn: action.payload,
          departure: null,
        };
      } else {
        state.calendarSelected.checkIn = action.payload;
      }
    },
    setDeparture: (state, action: PayloadAction<string | null>) => {
      if (action.payload === null) {
        if (state.calendarSelected && state.calendarSelected.checkIn === null) {
          state.calendarSelected = null;
        } else if (state.calendarSelected) {
          state.calendarSelected.departure = action.payload;
        }
      } else if (!state.calendarSelected) {
        state.calendarSelected = {
          checkIn: null,
          departure: action.payload,
        };
      } else {
        state.calendarSelected.departure = action.payload;
      }
    },
    setSliderPriceStartValue: (state, action: PayloadAction<Array<number> | null>) => {
      if (action.payload) {
        state.sliderStartPriceValue = {
          minValue: action.payload[0],
          maxValue: action.payload[1],
        };
      }
    },
    setSliderPriceValue: (state, action: PayloadAction<Array<number> | null>) => {
      if (state.sliderStartPriceValue) {
        if (
          action.payload &&
          state.sliderStartPriceValue.maxValue === action.payload[1] &&
          state.sliderStartPriceValue.minValue === action.payload[0]
        ) {
          state.sliderPriceValue = null;
        } else {
          state.sliderPriceValue = action.payload;
        }
      }
    },
    setClearAllFilter: (state) => {
      state.rooms = null;
      state.guests = null;
      state.calendarSelected = null;
      state.sliderPriceValue = null;
      state.isBonus = false;
      state.isContactless = false;
    },
    setClearCrumbsFilter: (state) => {
      state.guests = null;
      state.calendarSelected = null;
      state.sliderPriceValue = null;
      state.isBonus = false;
      state.isContactless = false;
    },
    toggleIsMark: (state) => {
      state.isContactless = !state.isContactless;
    },
    toggleisBonus: (state) => {
      state.isBonus = !state.isBonus;
    },
    setCityCoords: (state, action: PayloadAction<ICityCoords>) => {
      state.cityCoords = { lat: action.payload.lat, lon: action.payload.lon };
    },
  },
});

export const {
  setCitySelected,
  setGuests,
  setRooms,
  setAdults,
  setKids,
  setSortSelected,
  setCurrentPage,
  setCheckIn,
  setDeparture,
  setParams,
  setSliderPriceStartValue,
  setSliderPriceValue,
  setClearAllFilter,
  setClearCrumbsFilter,
  toggleIsMark,
  toggleisBonus,
  setCityCoords,
} = searchDataSlice.actions;

export default searchDataSlice.reducer;
